import { createStore } from 'effector';

export const $tourOpen = createStore(false);
$tourOpen.watch((state) => console.log('$tourOpen', state));

export const $tourSteps = createStore([]);
$tourSteps.watch((state) => console.log('$tourSteps', state));

// FIXME Refs of objects to show
export const $isPresetRef = createStore(null);
export const $publicBusinessTypeRef = createStore(null);
export const $publicDetailsRef = createStore(null);
export const $publicCreateRef = createStore(null);
export const $liteCategoryRef = createStore(null);
export const $liteChartRef = createStore(null);
export const $profileRef = createStore(null);
export const $categoriesRef = createStore(null);
export const $gradientRef = createStore(null);
export const $mapRef = createStore(null);
export const $profileModalRef = createStore(null);

export const $currentStep = createStore(0);
$currentStep.watch((state) => console.log('$currentStep', state));
