import notification from 'antd/es/notification';
import publicData from '../../data/publicData.json';
import rbp_free_dict from '../../dictionaries/rbp_free_dict.json';

export const calculateIndexCards = (hexagons, business_type) => {
  const temp = {
    population_total: 0,
    households: 0,
    income_avg_range: 0,

    competitors_cnt_B: 0,
    rent_price_range: 0,

    tj_lvl_avg: 0,
    pt_stops_cnt: 0,
    parking_place_cnt: 0,
  };
  hexagons.forEach((hex, index) => {
    temp.population_total += hex.properties.population_total;
    temp.households += hex.properties.households;
    temp.income_avg_range += hex.properties.income_avg_range;

    temp.competitors_cnt_B += hex.properties[business_type];
    temp.rent_price_range += hex.properties.rent_price_range;

    temp.tj_lvl_avg += hex.properties.tj_lvl_avg;
    temp.pt_stops_cnt += hex.properties.pt_stops_cnt;
    temp.parking_place_cnt += hex.properties.parking_place_cnt;
  });
  Object.keys(temp).forEach((key) => {
    if (
      key === 'income_avg_range' ||
      key === 'rent_price_range' ||
      key === 'tj_lvl_avg'
    ) {
      temp[key] = Math.round(temp[key] / hexagons.length);
    }
  });

  if (hexagons.length === publicData.features.length) {
    temp.population_total = rbp_free_dict.population_total.default_value;
    temp.households = rbp_free_dict.households.default_value;
  }
  return temp;
};

export const filterPublicHex = (
  hexagons,
  filters,
  business_type,
  chosen_metrics
) => {
  const prop_list = [
    'population_total',
    'households',
    business_type,
    'price_per_sq_m_Com',
    'pedestrian_connect',
    'tj_lvl_avg',
    'pt_route_cnt',
    'parking_place_cnt',
    'income_median',
  ];

  const filteredHexagons = hexagons
    .filter((item) => {
      if (
        prop_list.every((filter) => {
          const temp = filter.includes('competitors')
            ? 'business_type'
            : filter;
          return (
            item.properties[filter] >= filters[temp][0] &&
            item.properties[filter] <= filters[temp][1]
          );
        })
      ) {
        return true;
      }
      return false;
    })
    .map((item) => {
      let index_main =
        item.properties[
          chosen_metrics === 'competitors_cnt_B'
            ? business_type
            : chosen_metrics
        ];
      if (filters.population_category.length > 0) {
        index_main =
          filters.population_category.reduce(
            (a, b) => a + item.properties[b],
            0
          ) || null;
      }

      return {
        ...item,
        properties: {
          ...item.properties,
          index_main,
        },
      };
    });

  if (filteredHexagons.length === 0) {
    notification.info({
      message: 'Info',
      description:
        'Отсутствуют локации с такими параметрами. Попробуйте установить другие фильтры',
      placement: 'topRight',
      className: 'notification',
    });
  }

  return filteredHexagons;
};
