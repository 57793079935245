import React from 'react';
import { useUnit } from 'effector-react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import ReactPlayer from 'react-player';
import style from '../RbpLanding.module.css';
import report_placeholder_big from '../../../assets/RbpLandingImages/Payments/report_placeholder_big.png';
import report_placeholder_small from '../../../assets/RbpLandingImages/Payments/report_placeholder_small.png';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';
import payment_report_img_1 from '../../../assets/RbpLandingImages/Payments/payment_report_img_1.png';
import payment_report_img_2 from '../../../assets/RbpLandingImages/Payments/payment_report_img_2.png';
import payment_report_img_3 from '../../../assets/RbpLandingImages/Payments/payment_report_img_3.png';
import payment_report_img_4 from '../../../assets/RbpLandingImages/Payments/payment_report_img_4.png';
import useWindowSize from '../../../hooks/useWindowSize.js';
import RBP_Lite_Report_example from '../../../data/RBP_Lite_Report_example.xlsx';
import payment_page_video from '../../../assets/payment_page_video.mp4';
import rbp_report_example from '../../../data/rbp_report_example.pdf';

function PaymentReports() {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const params = [
    rbp_landing_translation[language].payments_reports_params_1,
    rbp_landing_translation[language].payments_reports_params_2,
    rbp_landing_translation[language].payments_reports_params_3,
    rbp_landing_translation[language].payments_reports_params_4,
    rbp_landing_translation[language].payments_reports_params_5,
    rbp_landing_translation[language].payments_reports_params_6,
    rbp_landing_translation[language].payments_reports_params_7,
    // rbp_landing_translation[language].payments_reports_params_8,
  ];

  const downloadExcel = () => {
    const link = document.createElement('a');
    // link.href = RBP_Lite_Report_example;
    link.href = rbp_report_example;
    link.download = 'RBP Lite data reports.pdf';
    link.click();
    link.remove();
  };

  return (
    <div className={style.payments_reports}>
      {/* <div */}
      {/*  className={[style.section_title, style.section_title_small].join(' ')} */}
      {/* > */}
      {/*  {rbp_landing_translation[language].payments_reports_right} */}
      {/* </div> */}
      <div>
        {/* <div */}
        {/*  className={[style.section_title, style.section_title_small].join(' ')} */}
        {/* > */}
        {/*  {rbp_landing_translation[language].payments_reports_video_title} */}
        {/* </div> */}
        <ReactPlayer
          width={width < 1200 ? 'calc(100% - 10px)' : 605}
          height={width < 1200 ? '100%' : 340}
          style={{
            // marginTop: 15,
            borderRadius: 10,
            overflow: 'hidden',
          }}
          url={payment_page_video}
          controls={false}
          loop
          playing
        />
      </div>
      <div>
        {/* <div */}
        {/*  className={[style.section_title, style.section_title_small].join(' ')} */}
        {/* > */}
        {/*  {rbp_landing_translation[language].payments_reports_title} */}
        {/* </div> */}
        <div className={style.payments_reports_desc}>
          {rbp_landing_translation[language].payments_reports_desc_1}
        </div>
        {/* <div */}
        {/*  className={style.payments_reports_desc} */}
        {/*  style={{ marginTop: 15 }} */}
        {/* > */}
        {/*  {rbp_landing_translation[language].payments_reports_desc_2} */}
        {/* </div> */}
        <div className={style.payments_reports_params}>
          {params.map((item, index) => {
            return (
              <div
                className={style.payments_reports_param}
                key={`param_${index}`}
              >
                <CheckOutlined className={style.payments_reports_check} />
                {item}
              </div>
            );
          })}
        </div>
        {/* <Button */}
        {/*  className={style.payments_reports_download} */}
        {/*  onClick={downloadExcel} */}
        {/* > */}
        {/*  {rbp_landing_translation[language].payments_reports_download} */}
        {/* </Button> */}
      </div>
    </div>
  );
}

export default PaymentReports;
