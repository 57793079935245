import { sample } from 'effector';
import notification from 'antd/es/notification';
import mapboxgl from 'mapbox-gl';
import { condition } from 'patronum';
import {
  $chosenPoint,
  $isError,
  $isSuccess,
  $language,
  $menuModal,
  $paymentBusiness,
  $redirectLink,
  $secondGradient,
  $userExistsModal,
} from './stores.js';
import {
  changeLanguageEv,
  changeMenuModalEv,
  changePaymentBusinessEv,
  changeSecondGradientEv,
  newUserEv,
  openPaymentUrlEv,
  resetErrorEv,
  resetPaymentBusinessEv,
  resetRedirectLinkEv,
  resetSuccessEv,
  resetUserExistsEv,
  sendPaymentEv,
  sendRegRequestEv,
  setChosenPointEv,
  userExistsEv,
} from './events.js';
import { sendRegRequestFx } from './effects.js';
import { wsSendPayment } from '../../utils/webSocketConfig.js';

$language.on(changeLanguageEv, (_, payload) => payload);

$isSuccess.reset(resetSuccessEv);
$isError.reset(resetErrorEv);

$menuModal.on(changeMenuModalEv, (state, payload) => {
  if (typeof payload === 'boolean') {
    return payload;
  }
  return !state;
});

$secondGradient.on(changeSecondGradientEv, (state, payload) => {
  if (typeof payload === 'boolean') {
    return payload;
  }
  return !state;
});

$redirectLink.reset(resetRedirectLinkEv);

$paymentBusiness
  .on(changePaymentBusinessEv, (state, payload) => payload)
  .reset(resetPaymentBusinessEv);

$userExistsModal
  .on(userExistsEv, (state, payload) => true)
  .reset(resetUserExistsEv);

sample({
  clock: sendRegRequestEv,
  target: sendRegRequestFx,
});

// sample({
//   clock: sendRegRequestFx.doneData,
//   fn: () => true,
//   target: $isSuccess,
// });
//
// sample({
//   clock: sendRegRequestFx.failData,
//   fn: () => true,
//   target: $isError,
// });

sendRegRequestFx.doneData.watch((data) => {
  notification.success({
    message: 'Успех!',
    description: 'Ваша заявка отправлена на рассмотрение',
    placement: 'topRight',
  });
});

sendRegRequestFx.failData.watch((data) => {
  notification.error({
    message: 'Ошибка!',
    description: 'Что-то пошло не так...',
    placement: 'topRight',
  });
});

sample({
  source: $chosenPoint,
  clock: setChosenPointEv,
  fn: (source, clock) => {
    if (source) {
      source.remove();
    }
    return new mapboxgl.Marker({ scale: 0.5 })
      .setLngLat([clock.lng || clock.lon, clock.lat])
      .addTo(window.map);
  },
  target: $chosenPoint,
});

sample({
  source: $chosenPoint,
  clock: sendPaymentEv,
  fn: (source, clock) => {
    // const business = Number(
    //   clock.business_type.split('pt')[0].replace('t', '')
    // );
    // const sub_business = Number(clock.business_type.split('pt')[1]);
    wsSendPayment({
      email: clock.email,
      // lat: source._lngLat.lat,
      // lon: source._lngLat.lng,
      // business,
      // sub_business,
      // product: clock.package.name,
      product: 'location_test',
    });
  },
});

sample({
  clock: newUserEv,
  fn: (clock) => {
    window.open(clock.forward_url, 'newwindow', 'width=600, height=770');
    return clock.forward_url;
  },
  target: $redirectLink,
});

condition({
  source: openPaymentUrlEv,
  if: (source) => source.user_exists,
  then: userExistsEv,
  else: newUserEv,
});
