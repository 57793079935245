import { sample } from 'effector';
import { once, spread } from 'patronum';
import {
  $activeFilters,
  $chartFilters,
  $publicFilters,
  $publicRangeMax,
  $publicRangeMin,
} from './stores.js';
import {
  changeActiveFilterEv,
  changeChartFiltersEv,
  changePublicFiltersEv,
  clearActiveGradientEv,
  clearChartFiltersEv,
  clearFiltersEv,
  clearGradientEv,
  dbChangeActiveFilterEv,
  debounced,
  debouncePublicFiltersEv,
  getPeopleFilteredEv,
  initPublicFiltersEv,
  resetActiveFiltersEv,
  selectAllEv,
  submitFiltersBackEv,
  submitFiltersEv,
  updateFiltersByPresetEv,
} from './events.js';
import { all_metrics } from '../../dictionaries/metrics.js';
import {
  wsGetFilteredPolygons,
  wsGetPeopleFiltered,
  wsGetRBPData,
  wsGetRBPFilteredData,
} from '../../utils/webSocketConfig.js';
import {
  $dataPresets,
  $presetAreaToEdit,
  $userAppointedPreset,
  applyPresetFiltersEv,
} from '../dataPresetsModel/index.js';
import { $zoom9Hexagons, $zoom9StartHexagons } from '../zoom9Model/index.js';
import { showLoaderEv } from '../webSocketModel/index.js';
import { $rbpLayer, changeRbpLayerEv } from '../mapModel/index.js';
import { formatSocdemPeopleGroups } from '../../utils/active-filters-utils.js';
import {
  $publicBusinessType,
  $zoom7StartHexagons,
} from '../zoom7Model/index.js';

$activeFilters.reset(resetActiveFiltersEv);

sample({
  source: $activeFilters,
  clock: [debounced, changeActiveFilterEv],
  fn: (source, clock) => {
    if (/zoom\d+?_hex/g.test(clock.field)) {
      if (source[clock.field].id === clock.value.id) {
        return { ...source, [clock.field]: { id: '', centerCoords: [] } };
      }
      return { ...source, [clock.field]: clock.value };
    }
    if (clock.field === 'excludedIndexes') {
      if (!clock.value) {
        return {
          ...source,
          excludedIndexes: [],
        };
      }
      if (typeof clock.value === 'string') {
        if (source[clock.field].includes(clock.value)) {
          return {
            ...source,
            excludedIndexes: source.excludedIndexes.filter(
              (item) => item !== clock.value
            ),
          };
        }
      } else if (
        clock.value.every((item) => source[clock.field].includes(item))
      ) {
        return {
          ...source,
          excludedIndexes: source.excludedIndexes.filter(
            (item) => !clock.value.includes(item)
          ),
        };
      }
      let addValue = clock.value;
      if (typeof clock.value === 'string') {
        addValue = [clock.value];
      }
      return {
        ...source,
        excludedIndexes: [...source.excludedIndexes, ...addValue],
      };
    }

    if (clock.field === 'gradient') {
      if (clock.value === null)
        return {
          ...source,
          gradient: [],
        };
      if (
        source.gradient.every(
          (item) => item.min !== clock.value.min && item.max !== clock.value.max
        )
      ) {
        return {
          ...source,
          gradient: [...source.gradient, clock.value],
        };
      }
      return {
        ...source,
        gradient: source.gradient.filter(
          (item) => item.min !== clock.value.min && item.max !== clock.value.max
        ),
      };
    }

    if (clock.field === 'chosen_metrics') {
      if (source.chosen_metrics.includes(clock.value)) {
        return {
          ...source,
          chosen_metrics: source.chosen_metrics.filter(
            (item) => item !== clock.value
          ),
        };
      }
      return {
        ...source,
        // FIXME For multiple metrics. Gotta rewrite calculateHexagonsByMetrics in hex utils
        // chosen_metrics: [...source.chosen_metrics, clock.value],
        chosen_metrics: [clock.value],
      };
    }

    if (source[clock.field] === clock.value) {
      return { ...source, [clock.field]: '' };
    }
    return { ...source, [clock.field]: clock.value };
  },
  target: $activeFilters,
});

sample({
  source: $activeFilters,
  clock: clearFiltersEv,
  fn: (source, clock) => {
    return {
      ...source,
      [clock.field]: clock.field === 'excludedIndexes' ? all_metrics : [],
    };
  },
  target: $activeFilters,
});

sample({
  source: $activeFilters,
  clock: selectAllEv,
  fn: (source, clock) => {
    return {
      ...source,
      excludedIndexes: [],
    };
  },
  target: [$activeFilters, submitFiltersEv],
});

sample({
  source: $activeFilters,
  clock: submitFiltersEv,
  filter: (source) => source.chosen_metrics.length !== 0,
  fn: (source, clock) => {
    return {
      field: 'chosen_metrics',
      value: source.chosen_metrics[0],
    };
  },
  target: changeActiveFilterEv,
});

sample({
  source: $activeFilters,
  clock: submitFiltersBackEv,
  fn: (source) => {
    const payload = {
      region_ids: source.district,
      index_ids: all_metrics
        .filter((item) => !source.excludedIndexes.includes(item))
        .map((item) => Number(item.split('index_')[1])),
    };

    wsGetFilteredPolygons(payload);
  },
});

sample({
  source: $activeFilters,
  clock: clearActiveGradientEv,
  fn: (source) => {
    return {
      ...source,
      gradient: [],
    };
  },
  target: $activeFilters,
});

sample({
  source: [$activeFilters, $dataPresets, $presetAreaToEdit],
  clock: updateFiltersByPresetEv,
  filter: ([activeFilters, dataPresets, presetAreaToEdit], clock) =>
    presetAreaToEdit.preset ||
    (presetAreaToEdit.preset && presetAreaToEdit.area),
  fn: ([activeFilters, dataPresets, presetAreaToEdit], clock) => {
    const chosenPreset = dataPresets.find(
      (preset) => preset.name === presetAreaToEdit.preset
    );
    const { isochrone, ...presetActiveFilters } = chosenPreset.activeFilters;
    delete presetActiveFilters.isochrone;
    if (!presetAreaToEdit.area) {
      window.draw.set({
        type: 'FeatureCollection',
        features: [
          ...presetActiveFilters.draw_polygon,
          ...(chosenPreset.activeFilters.isochrone
            ? chosenPreset.activeFilters.isochrone.map((item) => item.point)
            : []),
        ],
      });
      return {
        ...activeFilters,
        ...presetActiveFilters,
      };
    }
    const area_polygon = presetActiveFilters.draw_polygon.find(
      (item) =>
        item.id === presetAreaToEdit.area ||
        item.properties.name === presetAreaToEdit.area
    );
    const chosenIsochrone = isochrone.find(
      (item) => item.point.properties.name === presetAreaToEdit.area
    );
    if (chosenIsochrone) {
      window.draw.set({
        type: 'FeatureCollection',
        features: [chosenIsochrone.point],
      });
    } else {
      window.draw.set({
        type: 'FeatureCollection',
        features: area_polygon ? [area_polygon] : [],
      });
    }

    let district_value = activeFilters.district;
    if (
      !area_polygon &&
      !isochrone
        .map((item) => item.area.properties.name)
        .includes(presetAreaToEdit.area)
    ) {
      district_value = [
        chosenPreset.hexagons.find(
          (item) => item.properties.area === presetAreaToEdit.area
        ).properties.city_region_id,
      ];
    } else {
      district_value = [];
    }
    return {
      ...activeFilters,
      ...presetActiveFilters,
      draw_polygon: area_polygon ? [area_polygon] : [],
      district: district_value,
    };
  },
  target: $activeFilters,
});

sample({
  clock: $presetAreaToEdit.updates,
  filter: (clock) => clock.preset === '' && clock.area === '',
  fn: () => {
    window.draw.set({
      type: 'FeatureCollection',
      features: [],
    });
  },
  target: resetActiveFiltersEv,
});

sample({
  source: [
    $zoom9Hexagons,
    $activeFilters,
    $zoom9StartHexagons,
    $userAppointedPreset,
  ],
  clock: changeActiveFilterEv,
  filter: (source, clock) => clock.field === 'business_type',
  fn: ([hexagons, activeFilters, startHex, preset], clock) => {
    wsGetRBPData({
      business: clock.value,
      index_ids:
        activeFilters.excludedIndexes.length > 0
          ? all_metrics
              .filter((item) => !activeFilters.excludedIndexes.includes(item))
              .map((item) => +item.split('index_')[1])
          : [],
      zoom_ids:
        hexagons.length === startHex.length && !preset
          ? []
          : hexagons.map((item) => item.properties.hex_id),
    });
  },
  target: showLoaderEv,
});

sample({
  source: [
    $zoom9Hexagons,
    $activeFilters,
    $zoom9StartHexagons,
    $userAppointedPreset,
  ],
  clock: submitFiltersEv,
  fn: ([hexagons, activeFilters, startHex, preset], clock) => {
    const payload = {
      business: activeFilters.business_type,
      index_ids:
        activeFilters.excludedIndexes.length > 0
          ? all_metrics
              .filter((item) => !activeFilters.excludedIndexes.includes(item))
              .map((item) => +item.split('index_')[1])
          : [],
      zoom_ids:
        hexagons.length === startHex.length && !preset
          ? []
          : hexagons.map((item) => item.properties.hex_id),
    };
    if (activeFilters.groups.length > 0) {
      payload.groups = activeFilters.groups;
      if (activeFilters.gradient.length > 0) {
        payload.zoom_ids = [];
      }
      wsGetRBPFilteredData(payload);
    } else {
      wsGetRBPData(payload);
    }
  },
  target: [showLoaderEv],
});

sample({
  source: $chartFilters,
  clock: changeChartFiltersEv,
  fn: (source, clock) => {
    if (source.length > 0) {
      if (source[0].chart !== clock.chart) {
        return [clock];
      }
      if (
        source.some((item) => JSON.stringify(item) === JSON.stringify(clock))
      ) {
        return [
          ...source.filter(
            (item) => JSON.stringify(item) !== JSON.stringify(clock)
          ),
        ];
      }
      return [...source, clock];
    }
    return [clock];
  },
  target: $chartFilters,
});

sample({
  clock: changeChartFiltersEv,
  fn: () => 'socdem',
  target: $rbpLayer,
});

sample({
  source: [$zoom9Hexagons, $zoom9StartHexagons, $chartFilters],
  clock: [$chartFilters.updates, getPeopleFilteredEv],
  filter: ([hexagons, startHex, chartFilters], clock) =>
    chartFilters.length > 0,
  fn: ([hexagons, startHex, chartFilters], clock) => {
    if (chartFilters.length > 0) {
      const payload = {
        working: chartFilters[0].working,
        zoom_ids:
          hexagons.length === startHex.length
            ? []
            : hexagons.map((item) => item.properties.hex_id),
        groups: formatSocdemPeopleGroups(chartFilters),
      };
      wsGetPeopleFiltered(payload);
    }
  },
});

sample({
  clock: $chartFilters.updates,
  target: clearGradientEv,
});

sample({
  clock: $chartFilters.updates,
  filter: (clock) => clock.length === 0,
  fn: () => 'index',
  target: changeRbpLayerEv,
});

sample({
  source: $activeFilters,
  clock: [$rbpLayer.updates, clearGradientEv],
  filter: (source, clock) => source.gradient.length > 0,
  fn: () => {
    return {
      field: 'gradient',
      value: null,
    };
  },
  target: changeActiveFilterEv,
});

sample({
  source: [$userAppointedPreset, $activeFilters],
  clock: once(applyPresetFiltersEv),
  filter: ([preset]) => preset,
  fn: ([preset, activeFilters]) => {
    const result = {
      ...activeFilters,
      ...preset.preset.activeFilters,
      groups: preset.preset.activeFilters.socdem_groups,
      district: [],
    };
    delete result.socdem_groups;
    return result;
  },
  target: $activeFilters,
});

sample({
  source: $userAppointedPreset,
  clock: once(applyPresetFiltersEv),
  filter: (source) =>
    source && source.preset.activeFilters.chart_filters.length > 0,
  fn: (source) => source.activeFilters.chart_filters,
  target: $chartFilters,
});

sample({
  source: $userAppointedPreset,
  clock: clearChartFiltersEv,
  filter: (source) =>
    !(source && source.preset.activeFilters.chart_filters.length > 0),
  fn: () => [],
  target: $chartFilters,
});

sample({
  clock: initPublicFiltersEv,
  fn: (hexagons) => {
    let min_pt = hexagons[0].properties.population_total;
    let max_pt = hexagons[0].properties.population_total;
    let min_hh = hexagons[0].properties.households;
    let max_hh = hexagons[0].properties.households;
    let min_bt = hexagons[0].properties.competitors_cnt_B1;
    let max_bt = hexagons[0].properties.competitors_cnt_B1;
    let min_p = hexagons[0].properties.price_per_sq_m_Com;
    let max_p = hexagons[0].properties.price_per_sq_m_Com;
    let min_pc = hexagons[0].properties.pedestrian_connect;
    let max_pc = hexagons[0].properties.pedestrian_connect;
    let min_tj = hexagons[0].properties.tj_lvl_avg;
    let max_tj = hexagons[0].properties.tj_lvl_avg;
    let min_ptr = hexagons[0].properties.pt_route_cnt;
    let max_ptr = hexagons[0].properties.pt_route_cnt;
    let min_pp = hexagons[0].properties.parking_place_cnt;
    let max_pp = hexagons[0].properties.parking_place_cnt;
    let min_im = hexagons[0].properties.income_median;
    let max_im = hexagons[0].properties.income_median;
    hexagons.forEach((hex, index) => {
      if (index > 0) {
        if (
          hex.properties.population_total < min_pt &&
          typeof hex.properties.population_total === 'number'
        )
          min_pt = hex.properties.population_total;
        if (hex.properties.population_total > max_pt)
          max_pt = hex.properties.population_total;
        if (
          hex.properties.households < min_hh &&
          typeof hex.properties.households === 'number'
        )
          min_hh = hex.properties.households;
        if (hex.properties.households > max_hh)
          max_hh = hex.properties.households;
        if (
          hex.properties.competitors_cnt_B1 < min_bt &&
          typeof hex.properties.competitors_cnt_B1 === 'number'
        )
          min_bt = hex.properties.competitors_cnt_B1;
        if (hex.properties.competitors_cnt_B1 > max_bt)
          max_bt = hex.properties.competitors_cnt_B1;
        if (
          hex.properties.price_per_sq_m_Com < min_p &&
          typeof hex.properties.price_per_sq_m_Com === 'number'
        )
          min_p = hex.properties.price_per_sq_m_Com;
        if (hex.properties.price_per_sq_m_Com > max_p)
          max_p = hex.properties.price_per_sq_m_Com;
        if (
          hex.properties.pedestrian_connect < min_pc &&
          typeof hex.properties.pedestrian_connect === 'number'
        )
          min_pc = hex.properties.pedestrian_connect;
        if (hex.properties.pedestrian_connect > max_pc)
          max_pc = hex.properties.pedestrian_connect;
        if (
          hex.properties.tj_lvl_avg < min_tj &&
          typeof hex.properties.tj_lvl_avg === 'number'
        )
          min_tj = hex.properties.tj_lvl_avg;
        if (hex.properties.tj_lvl_avg > max_tj)
          max_tj = hex.properties.tj_lvl_avg;
        if (
          hex.properties.pt_route_cnt < min_ptr &&
          typeof hex.properties.pt_route_cnt === 'number'
        )
          min_ptr = hex.properties.pt_route_cnt;
        if (hex.properties.pt_route_cnt > max_ptr)
          max_ptr = hex.properties.pt_route_cnt;
        if (
          hex.properties.parking_place_cnt < min_pp &&
          typeof hex.properties.parking_place_cnt === 'number'
        )
          min_pp = hex.properties.parking_place_cnt;
        if (hex.properties.parking_place_cnt > max_pp)
          max_pp = hex.properties.parking_place_cnt;
        if (
          hex.properties.income_median < min_im &&
          typeof hex.properties.income_median === 'number'
        )
          min_im = hex.properties.income_median;
        if (hex.properties.income_median > max_im)
          max_im = hex.properties.income_median;
      }
    });

    return {
      publicFilters: {
        population_total: [min_pt || 0, max_pt],
        households: [min_hh || 0, max_hh],
        business_type: [min_bt || 0, max_bt],
        price_per_sq_m_Com: [min_p || 0, max_p],
        pedestrian_connect: [min_pc || 0, max_pc],
        tj_lvl_avg: [min_tj || 0, max_tj],
        pt_route_cnt: [min_ptr || 0, max_ptr],
        parking_place_cnt: [min_pp || 0, max_pp],
        income_median: [min_im || 0, max_im],
        population_category: [],
      },
      publicRangeMax: {
        population_total: max_pt,
        households: max_hh,
        business_type: max_bt,
        price_per_sq_m_Com: max_p,
        pedestrian_connect: max_pc,
        tj_lvl_avg: max_tj,
        pt_route_cnt: max_ptr,
        parking_place_cnt: max_pp,
        income_median: max_im,
      },
      publicRangeMin: {
        population_total: min_pt || 0,
        households: min_hh || 0,
        business_type: min_bt || 0,
        price_per_sq_m_Com: min_p || 0,
        pedestrian_connect: min_pc || 0,
        tj_lvl_avg: min_tj || 0,
        pt_route_cnt: min_ptr || 0,
        parking_place_cnt: min_pp || 0,
        income_median: min_im || 0,
      },
    };
  },
  target: spread({
    targets: {
      publicFilters: $publicFilters,
      publicRangeMax: $publicRangeMax,
      publicRangeMin: $publicRangeMin,
    },
  }),
});

sample({
  source: $publicFilters,
  clock: changePublicFiltersEv,
  fn: (source, clock) => {
    if (clock.field === 'population_category') {
      if (source[clock.field].length > 0) {
        const category = clock.value.match(/population_.{3}/g)[0];
        if (source[clock.field].every((item) => item.includes(category))) {
          if (source[clock.field].includes(clock.value)) {
            return {
              ...source,
              [clock.field]: source[clock.field].filter(
                (item) => item !== clock.value
              ),
            };
          }
          return {
            ...source,
            [clock.field]: [...source[clock.field], clock.value],
          };
        }
        return {
          ...source,
          [clock.field]: [clock.value],
        };
      }
      return {
        ...source,
        [clock.field]: [clock.value],
      };
    }
    return {
      ...source,
      [clock.field]: clock.value,
    };
  },
  target: $publicFilters,
});

sample({
  source: [
    $publicFilters,
    $zoom7StartHexagons,
    $publicRangeMax,
    $publicRangeMin,
  ],
  clock: $publicBusinessType.updates,
  fn: ([filters, startHex, rangeMax, rangeMin], clock) => {
    let min_bt = startHex[0].properties.competitors_cnt_B1;
    let max_bt = startHex[0].properties.competitors_cnt_B1;

    startHex.forEach((hex) => {
      if (hex.properties[clock] < min_bt) min_bt = hex.properties[clock];
      if (hex.properties[clock] > max_bt) max_bt = hex.properties[clock];
    });
    return {
      publicFilters: {
        ...filters,
        business_type: [min_bt || 0, max_bt],
      },
      publicRangeMax: {
        ...rangeMax,
        business_type: max_bt,
      },
      publicRangeMin: {
        ...rangeMin,
        business_type: min_bt || 0,
      },
    };
  },
  target: spread({
    targets: {
      publicFilters: $publicFilters,
      publicRangeMax: $publicRangeMax,
      publicRangeMin: $publicRangeMin,
    },
  }),
});
