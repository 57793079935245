import { createEvent } from 'effector';

export const toggleIsPresetEv = createEvent();

export const toggleProfileModalEv = createEvent();

export const changeCurrentFavsEv = createEvent();

export const changeChartFiltersEv = createEvent();

export const putLiteHexagonsEv = createEvent();

export const putIsochroneTotalEv = createEvent();

export const changeChosenLiteHexEv = createEvent();

export const resetChosenLiteHexEv = createEvent();

export const putLiteAggregatesEv = createEvent();

export const putD9TableEv = createEvent();

export const putKrishaObjectsEv = createEvent();

export const flyToKrishaEv = createEvent();

export const getNewLocationEv = createEvent();

export const changeLiteBusinessEv = createEvent();

export const activatePresetEv = createEvent();

export const setActivePresetEv = createEvent();

export const countDotsByGradientEv = createEvent();

export const putRoadsEv = createEvent();

export const toggleShowRoadsEv = createEvent();

export const restoreHexagonsEv = createEvent();

export const restoreRoadsEv = createEvent();

export const downloadReportEv = createEvent();

export const renamePresetEv = createEvent();

export const deleteLitePresetEv = createEvent();

export const toggleLiteErrorModalEv = createEvent();

export const sendPaymentUserEv = createEvent();

export const openKrishaLinkEv = createEvent();

export const toggleCategoryExpandedEv = createEvent();

export const resetChartFiltersEv = createEvent();

export const changeHexLabelEv = createEvent()
