import { createStore } from 'effector';

export const $messages = createStore([
  {
    text: 'Налоговый режим — это установленная законодательством система налогообложения, определяющая порядок расчета и уплаты налогов для бизнеса. Выберите способ консультации: посмотреть все доступные режимы или подобрать оптимальный вариант на основе ваших параметров.',
    fromUser: false,
  },
]);

$messages.watch((state) => console.log('$messages', state));

export const $chosenOptions = createStore([]);

export const $currentOptions = createStore([
  'Подбери мне режим налогооблажения',
  'Покажи все режимы',
]);

export const $resultData = createStore(null);
