import { sample } from 'effector';
import { spread } from 'patronum';
import {
  goBackByResultEv,
  resetAllEv,
  resetMessagesEv,
  resetOptionsEv,
  sendMessageEv,
} from './events.js';
import { $currentOptions, $messages } from './stores.js';
import {
  messages_sequence,
  options_sequence,
} from '../../data/cert_consultation_dict.jsx';

$messages.reset(resetMessagesEv);

$currentOptions.reset(resetOptionsEv);

sample({
  source: $messages,
  clock: sendMessageEv,
  fn: (source, clock) => {
    return [
      ...source,
      {
        text: clock,
        fromUser: true,
      },
      {
        text: messages_sequence[clock],
        fromUser: false,
      },
    ];
  },
  target: $messages,
});

sample({
  clock: sendMessageEv,
  fn: (clock) => {
    return options_sequence[clock] || [];
  },
  target: $currentOptions,
});

sample({
  clock: resetAllEv,
  target: [resetMessagesEv, resetOptionsEv],
});

sample({
  source: $messages,
  clock: goBackByResultEv,
});

sample({
  source: $messages,
  clock: goBackByResultEv,
  fn: (source) => {
    let lastFromUser;
    for (let i = source.length - 1; i >= 0; i--) {
      if (source[i].fromUser) {
        lastFromUser = i;
        break;
      }
    }
    return {
      messages: source.slice(0, lastFromUser),
      currentOptions: options_sequence[source[lastFromUser - 2].text],
    };
  },
  target: spread({
    targets: {
      messages: $messages,
      currentOptions: $currentOptions,
    },
  }),
});
