import React, { useEffect, useRef, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import Icon from '@ant-design/icons';
import style from './LandingIsochroneButton.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $mapLoaded,
  changeDrawModeEv,
  changeInteractivityEv,
} from '../../models/mapModel/index.js';
import { getIsochroneForPaymentsEv } from '../../models/isochroneModel/index.js';
import { setChosenPointEv } from '../../models/rbpLandingModel/index.js';
import { $isPreset } from '../../models/rbpLiteModel/index.js';
import { $isDarkTheme } from '../../models/authModel/index.js';
import {
  $tourOpen,
  incStepEv,
  setPublicCreateRefEv,
} from '../../models/educationModel/index.js';

function MarkerSvg() {
  const { pathname } = useLocation();

  return (
    <svg
      fill="currentColor"
      width={pathname === '/lite' ? '48' : 20}
      height={pathname === '/lite' ? '48' : 20}
      viewBox="-3 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m8.075 23.52c-6.811-9.878-8.075-10.891-8.075-14.52 0-4.971 4.029-9 9-9s9 4.029 9 9c0 3.629-1.264 4.64-8.075 14.516-.206.294-.543.484-.925.484s-.719-.19-.922-.48l-.002-.004zm.925-10.77c2.07 0 3.749-1.679 3.749-3.75s-1.679-3.75-3.75-3.75-3.75 1.679-3.75 3.75c0 2.071 1.679 3.75 3.75 3.75z" />
    </svg>
  );
}

function MarkerIcon() {
  return <Icon component={MarkerSvg} />;
}

function LandingIsochroneButton({ absolute = true }) {
  const changeInteractivity = useUnit(changeInteractivityEv);
  const changeDrawModeState = useUnit(changeDrawModeEv);
  const [search] = useSearchParams();
  const getIsochroneForPayments = useUnit(getIsochroneForPaymentsEv);
  const mapLoaded = useUnit($mapLoaded);
  const setChosenPoint = useUnit(setChosenPointEv);
  const isPreset = useUnit($isPreset);
  const { pathname } = useLocation();
  const [animation, setAnimation] = useState(true);
  const setPublicCreateRef = useUnit(setPublicCreateRefEv);
  const publicCreateRef = useRef();
  const tourOpen = useUnit($tourOpen);
  const incStep = useUnit(incStepEv);

  const onClick = () => {
    changeInteractivity(false);
    changeDrawModeState('draw_point');
    window.draw.changeMode('draw_point');
    if (tourOpen) {
      incStep();
    }
  };

  useEffect(() => {
    if (search.size > 0 && mapLoaded) {
      const coords = queryString.parse(window.location.search);
      getIsochroneForPayments({
        lon: coords.lng,
        lat: coords.lat,
      });
      // window.draw.set({
      //   type: 'FeatureCollection',
      //   features: [
      //     {
      //       type: 'Feature',
      //       geometry: {
      //         type: 'Point',
      //         coordinates: [+coords.lng, +coords.lat],
      //       },
      //       properties: {},
      //     },
      //   ],
      // });
      setChosenPoint(coords);
    }
  }, [search, mapLoaded]);

  useEffect(() => {
    if (publicCreateRef.current) {
      setPublicCreateRef({
        element: 'publicCreate',
        ref: publicCreateRef.current,
      });
    }
  }, [publicCreateRef.current]);

  useEffect(() => {
    if (tourOpen) {
      setAnimation(false);
    } else {
      setAnimation(true);
    }
  }, [tourOpen]);

  return (
    <div
      ref={publicCreateRef}
      style={{
        position: absolute ? 'absolute' : '',
        top: 20,
        left: pathname === '/lite' ? 340 : 20,
      }}
    >
      {pathname === '/lite' && isPreset ? null : (
        <Tooltip
          title={
            ru_en_page_dictionary[
              pathname === '/lite'
                ? 'lite_create_button'
                : window.location.search.length > 0
                  ? 'payment_point_button_with_loc'
                  : 'payment_point_button_no_loc'
            ].ru
          }
          placement="right"
          {...(pathname === '/payment' ? { open: true } : {})}
        >
          <Button
            onClick={onClick}
            icon={<MarkerIcon />}
            className={style.button}
            style={{
              width: pathname === '/lite' ? 96 : 40,
              height: pathname === '/lite' ? 96 : 40,
              animation:
                pathname === '/lite'
                  ? animation
                    ? `${style.pulse} 2s infinite`
                    : 'unset'
                  : 'unset',
            }}
            onMouseEnter={() => setAnimation(false)}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default LandingIsochroneButton;
