import { createEvent } from 'effector';
import { debounce } from 'patronum';
import {$publicFilters} from "./stores.js";

export const changeActiveFilterEv = createEvent();
export const dbChangeActiveFilterEv = createEvent();

export const clearFiltersEv = createEvent();

export const submitFiltersEv = createEvent();

export const selectAllEv = createEvent();

export const calculateByMetricEv = createEvent();

export const debounced = debounce({
  source: dbChangeActiveFilterEv,
  timeout: 1000,
});

export const submitFiltersBackEv = createEvent();

export const clearActiveGradientEv = createEvent();

export const resetActiveFiltersEv = createEvent();

export const updateFiltersByPresetEv = createEvent();

export const changeChartFiltersEv = createEvent();

export const getPeopleFilteredEv = createEvent();

export const clearGradientEv = createEvent();

export const clearChartFiltersEv = createEvent();

export const initPublicFiltersEv = createEvent();

export const changePublicFiltersEv = createEvent();
export const debouncePublicFiltersEv = debounce({
  source: $publicFilters.updates,
  timeout: 500,
});
