import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useLocation } from 'react-router-dom';
import {
  $chosenZoom7Hexagon,
  $zoom7Hexagons,
  setChosenZoom7HexagonEv,
  setClickCoordsEv,
} from '../../models/zoom7Model/index.js';
import {
  $hexagonsHeight,
  $interactivity,
  $invertHeight,
  $layersOpacity,
  $mapLoaded,
} from '../../models/mapModel/index.js';
import { $gradientBuckets } from '../../models/gradientModel/index.js';
import {
  formDynamicBuckets,
  generateExtrusionHeight,
} from '../../utils/hexagon-utils.js';
import {
  changeLayerVisibility,
  changePointerOnLayer,
} from '../../utils/mapbox-utils.js';
import {
  $activeExtrusionProp,
  $isExtrusion,
} from '../../models/zoom10Model/index.js';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import { $isPreset } from '../../models/rbpLiteModel/index.js';
import { incStepByHexEv } from '../../models/educationModel/index.js';

function handleClick(e) {
  const clickedFeatures = window.map.queryRenderedFeatures(e.point, {
    layers: ['public_attracts_layer', 'public_stops_layer'],
  });

  if (clickedFeatures.length === 0) {
    const feature = e.features[0];
    setChosenZoom7HexagonEv(feature);
    setClickCoordsEv({
      ...e.lngLat,
      id: e.features[0].properties.Zoom8_id,
    });
  }
  // incStepByHexEv();
}

function Zoom7Layer() {
  const zoom7Hexagons = useUnit($zoom7Hexagons);
  const mapLoaded = useUnit($mapLoaded);
  const { buckets, colors } = useUnit($gradientBuckets);
  const layersOpacity = useUnit($layersOpacity);
  const isExtrusion = useUnit($isExtrusion);
  const activeExtrusionProp = useUnit($activeExtrusionProp);
  const hexagonsHeight = useUnit($hexagonsHeight);
  const invertHeight = useUnit($invertHeight);
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const chosen_metrics = useUnit($activeFilters).chosen_metrics[0];
  const { pathname } = useLocation();
  const isPreset = useUnit($isPreset);
  const interactivity = useUnit($interactivity);

  const createLayer = (update) => {
    const zoom7HexagonData = {
      type: 'FeatureCollection',
      features: zoom7Hexagons.filter(
        (item) => item.properties.index_main !== null
      ),
    };
    const gradientProp = formDynamicBuckets(buckets, colors);

    console.log('gradientProp', gradientProp);
    if (update) {
      window.map.getSource('zoom7_hexagon_source').setData(zoom7HexagonData);
      window.map.setPaintProperty(
        'zoom7_hexagon_layer',
        'fill-extrusion-color',
        gradientProp
      );
      window.map.setPaintProperty('zoom7_hexagon_outline', 'line-color', [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        '#2D9CDB',
        gradientProp,
      ]);
    } else {
      if (!window.map.getSource('zoom7_hexagon_source')) {
        window.map.addSource('zoom7_hexagon_source', {
          type: 'geojson',
          data: zoom7HexagonData,
        });
      }
      if (!window.map.getLayer('zoom7_hexagon_layer')) {
        window.map.addLayer({
          id: 'zoom7_hexagon_layer',
          type: 'fill-extrusion',
          source: 'zoom7_hexagon_source',
          paint: {
            'fill-extrusion-color': gradientProp,
            'fill-extrusion-height': generateExtrusionHeight(
              isExtrusion,
              activeExtrusionProp,
              hexagonsHeight,
              invertHeight
            ),
            'fill-extrusion-opacity': layersOpacity,
          },
        });
      }

      if (!window.map.getLayer('zoom7_hexagon_outline')) {
        window.map.addLayer({
          id: 'zoom7_hexagon_outline',
          type: 'line',
          source: 'zoom7_hexagon_source',
          paint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'active'], false],
              '#2D9CDB',
              gradientProp,
            ],
            'line-width': [
              'case',
              ['boolean', ['feature-state', 'active'], false],
              5,
              1,
            ],
            'line-opacity': 0.8,
          },
        });
      }

      if (isExtrusion) {
        changeLayerVisibility('zoom7_hexagon_outline', 'none');
      } else {
        changeLayerVisibility('zoom7_hexagon_outline', 'visible');
      }

      if (pathname === '/lite' && isPreset) {
        changeLayerVisibility('zoom7_hexagon_layer', false);
        changeLayerVisibility('zoom7_hexagon_outline', false);
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && zoom7Hexagons.length && buckets.length > 0) {
      if (window.map.getLayer('zoom7_hexagon_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [zoom7Hexagons, mapLoaded, buckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom7_hexagon_layer')) {
      setTimeout(() => {
        window.map.setPaintProperty(
          'zoom7_hexagon_layer',
          'fill-extrusion-opacity',
          layersOpacity
        );
        window.map.setPaintProperty(
          'zoom7_hexagon_outline',
          'line-opacity',
          layersOpacity
        );
      }, 100);
    }
  }, [mapLoaded, layersOpacity]);

  // useEffect(() => {
  //   window.map.on('click', 'zoom7_hexagon_layer', handleClick);
  //   changePointerOnLayer('zoom7_hexagon_layer', true);
  // }, []);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom7_hexagon_layer')) {
      if (!isExtrusion) {
        window.map.setPaintProperty(
          'zoom7_hexagon_layer',
          'fill-extrusion-height',
          generateExtrusionHeight(isExtrusion, '', 0, invertHeight)
        );
      } else {
        window.map.setPaintProperty(
          'zoom7_hexagon_layer',
          'fill-extrusion-height',
          generateExtrusionHeight(
            isExtrusion,
            activeExtrusionProp,
            hexagonsHeight,
            invertHeight
          )
        );
      }
    }
  }, [isExtrusion, activeExtrusionProp, hexagonsHeight, invertHeight]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('zoom7_hexagon_source')) {
      zoom7Hexagons.map((item) => {
        window.map.setFeatureState(
          {
            source: 'zoom7_hexagon_source',
            id: item.id,
          },
          {
            active: false,
          }
        );
      });
      if (chosenZoom7Hexagon) {
        window.map.setFeatureState(
          {
            source: 'zoom7_hexagon_source',
            id: chosenZoom7Hexagon.id,
          },
          {
            active: true,
          }
        );
      }
    }
  }, [mapLoaded, chosenZoom7Hexagon]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom7_hexagon_layer')) {
      if (!isPreset) {
        changeLayerVisibility('zoom7_hexagon_layer', true);
        changeLayerVisibility('zoom7_hexagon_outline', true);
      } else {
        changeLayerVisibility('zoom7_hexagon_layer', false);
        changeLayerVisibility('zoom7_hexagon_outline', false);
      }
    }
  }, [isPreset, mapLoaded]);

  useEffect(() => {
    if (interactivity) {
      window.map.on('click', 'zoom7_hexagon_layer', handleClick);
      changePointerOnLayer('zoom7_hexagon_layer', interactivity);
    } else {
      window.map.off('click', 'zoom7_hexagon_layer', handleClick);
      changePointerOnLayer('zoom7_hexagon_layer', interactivity);
    }
  }, [interactivity]);

  return null;
}

export default Zoom7Layer;
