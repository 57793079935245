export const tax_info = {
  Общеустановленный: {
    info:
      '<h1>Общеустановленный режим налогообложения</h1>\n' +
      '    <p>Подходит и для ИП, и для юридических лиц.</p>\n' +
      '    <p>Общеустановленный режим налогообложения подразумевает исчисление и уплату всех установленных в общем порядке налогов и других обязательных платежей в бюджет.<br>\n' +
      '    Налогом облагается разница между доходами и расходами компании в ходе предпринимательской деятельности.</p>\n' +
      '    <h2>Основные параметры</h2>\n' +
      '    <ul>\n' +
      '        <li><strong>Количество сотрудников:</strong> не ограничено</li>\n' +
      '        <li><strong>Предельный доход:</strong> не ограничен</li>\n' +
      '        <li><strong>Ставка КПН для ТОО:</strong> 20% от прибыли (для сельхозпроизводителей – 10%)</li>\n' +
      '        <li><strong>Ставка ИПН для ИП:</strong> 10% от прибыли</li>\n' +
      '        <li><strong>Виды деятельности:</strong> не ограничены</li>\n' +
      '    </ul>',
    pros:
      '<h1>Кому подходит общеустановленный режим налогообложения</h1>\n' +
      '    <ul>\n' +
      '        <li>Предпринимателям с небольшой наценкой</li>\n' +
      '        <li>Тем, кто занимается импортом и дальнейшей реализацией</li>\n' +
      '        <li>Крупному бизнесу, предпочитающему работать с ТОО на общеустановленном режиме с НДС</li>\n' +
      '    </ul>',
    cons:
      '<h1>Требования и ограничения налогового режима</h1>\n' +
      '    <ul>\n' +
      '        <li>Требуется вести полный бухгалтерский учет</li>\n' +
      '        <li>На текущий момент сложно с поставщиками с официальными документами</li>\n' +
      '        <li>Нет дополнительных вычетов на экстренные расходы</li>\n' +
      '        <li>Порог по НДС ограничен 20 000 МРП</li>\n' +
      '        <li>Высокая нагрузка по фонду заработной платы – 39%</li>\n' +
      '    </ul>',
  },
  Патент: {
    info:
      '<h1>Специальный налоговый режим на основе патента</h1>\n' +
      '\n' +
      '    <p><strong>Доступен только для индивидуальных предпринимателей в форме личного предпринимательства.</strong></p>\n' +
      '\n' +
      '    <h2>Разрешенные виды деятельности</h2>\n' +
      '\n' +
      '    <p><strong>Внимание!</strong> Торговлей на данном виде налогообложения заниматься запрещено. Также данный режим не могут использовать предприниматели, имеющие структурные подразделения, филиалы и объекты обложения в разных населенных пунктах.</p>\n' +
      '\n' +
      '    <h2>Основные параметры</h2>\n' +
      '\n' +
      '    <ul>\n' +
      '\n' +
      '        <li><strong>Количество сотрудников:</strong> не предусмотрено</li>\n' +
      '\n' +
      '        <li><strong>Предельный доход:</strong> не должен превышать 13 025 376 тенге (3 528 МРП за год). При превышении автоматически осуществляется переход на другой налоговый режим</li>\n' +
      '\n' +
      '        <li><strong>Ставка ИПН:</strong> 1% к доходу, полученному за налоговый период + пенсионные, социальные отчисления и медицинское страхование за ИП</li>\n' +
      '\n' +
      '        <li><strong>Статус НДС:</strong> не являются плательщиками НДС</li>\n' +
      '\n' +
      '    </ul>\n' +
      '\n' +
      '    <h2>Дополнительные условия</h2>\n' +
      '\n' +
      '    <ul>\n' +
      '\n' +
      '        <li>На основе патента (форма 911.00), оплата налога и социальных платежей производится до получения патента – 1 раз в год</li>\n' +
      '\n' +
      '        <li>С использованием мобильного приложения можно сдавать отчетность ежемесячно (предзаполнение, чеки формируются в приложении и не требуют наличия ККМ)</li>\n' +
      '\n' +
      '    </ul>',
    pros:
      '<h1>Преимущества специального режима с фиксированным вычетом</h1>\n' +
      '    <ul>\n' +
      '        <li>Относительно низкий налог – 1%</li>\n' +
      '        <li>Можно применять при оказании услуг юридическим лицам</li>\n' +
      '        <li>По мобильному приложению имеется предварительное декларирование, чеки без комиссии ОФД</li>\n' +
      '    </ul>',
    cons:
      '<h1>Ограничения специального режима с фиксированным вычетом</h1> \n' +
      '    <ul>\n' +
      '        <li>По патенту требуется заранее оплатить налог и социальные платежи</li>\n' +
      '        <li>По итогам года требуется корректировать налог</li>\n' +
      '        <li>При минимальных оборотах высокая нагрузка из-за социальных платежей</li>\n' +
      '        <li>Минимальный платеж за пенсионные, социальные отчисления и соц. мед. страхование – 18 200 тенге</li>\n' +
      '    </ul>',
    allowed_activities:
      '<h1>Разрешенные виды деятельности</h1>\n' +
      '    \n' +
      '    <ul>\n' +
      '        <li>Штукатурные работы</li>\n' +
      '        <li>Столярные и плотницкие работы</li>\n' +
      '        <li>Работы по покрытию полов и облицовке стен</li>\n' +
      '        <li>Малярные и стекольные работы</li>\n' +
      '        <li>Деятельность такси</li>\n' +
      '        <li>Грузовые перевозки автомобильным транспортом</li>\n' +
      '        <li>Управление недвижимостью за вознаграждение или на договорной основе</li>\n' +
      '        <li>Деятельность в области фотографии</li>\n' +
      '        <li>Переводческое (устное и письменное) дело</li>\n' +
      '        <li>Сдача в имущественный наем (аренду)</li>\n' +
      '        <li>Сдача в имущественный наем (аренду) транспортных средств</li>\n' +
      '        <li>Прокат и имущественный наем (аренду) развлекательного и спортивного инвентаря</li>\n' +
      '        <li>Прокат видеозаписей и дисков</li>\n' +
      '        <li>Прокат и имущественный наем (аренду) прочих предметов личного потребления и бытовых товаров</li>\n' +
      '        <li>Сдача в имущественный наем (аренду) сельскохозяйственной техники и оборудования</li>\n' +
      '        <li>Сдача в имущественный наем (аренду) офисных машин и оборудования, включая вычислительную технику</li>\n' +
      '        <li>Услуги в области спортивного образования и образования специалистов организации досуга</li>\n' +
      '        <li>Услуги образования в сфере культуры</li>\n' +
      '        <li>Услуги в области прочего образования</li>\n' +
      '        <li>Вспомогательные образовательные услуги</li>\n' +
      '        <li>Деятельность в области искусства</li>\n' +
      '        <li>Ремонт компьютеров и периферийного оборудования</li>\n' +
      '        <li>Ремонт коммуникационного оборудования</li>\n' +
      '        <li>Ремонт предметов личного потребления и бытовых товаров</li>\n' +
      '        <li>Парикмахерские услуги</li>\n' +
      '        <li>Маникюр и педикюр</li>\n' +
      '        <li>Ветеринарные услуги</li>\n' +
      '        <li>Услуги по обработке земельных участков</li>\n' +
      '        <li>Услуги по уборке жилых помещений и ведению домашнего хозяйства</li>\n' +
      '        <li>Услуги носильщиков на рынках, вокзалах</li>\n' +
      '        <li>Изготовление и ремонт музыкальных инструментов</li>\n' +
      '        <li>Выпас домашних животных</li>\n' +
      '        <li>Курьерская доставка, за исключением услуг по доставке почтовых отправлений (только для Мобильного приложения)</li>\n' +
      '    </ul>',
  },
  Упрощенный: {
    info:
      '<h1>Упрощенный режим налогообложения</h1>\n' +
      '    <p><strong>Подходит и для ИП, и для юридических лиц.</strong></p>\n' +
      '    <p>Общеустановленный режим налогообложения подразумевает исчисление и уплату всех установленных в общем порядке налогов и других обязательных платежей в бюджет.</p>\n' +
      '    <p><strong>Налогом облагается доход компании без учета расходов.</strong></p>\n' +
      '    <h2>Основные параметры</h2>\n' +
      '    <ul>\n' +
      '        <li><strong>Количество сотрудников:</strong> до 30</li>\n' +
      '        <li><strong>Предельный доход:</strong> до 24 038 МРП за полугодие</li>\n' +
      '        <li><strong>При превышении:</strong> автоматический переход на другой налоговый режим</li>\n' +
      '        <li><strong>Ставка КПН для ТОО:</strong> 3% от дохода</li>\n' +
      '        <li><strong>Ставка ИПН для ИП:</strong> 3% от дохода (без учета расходов)</li>\n' +
      '        <li><strong>Виды деятельности:</strong> не ограничены</li>\n' +
      '    </ul>\n' +
      '    <h2>Ограничения</h2>\n' +
      '    <p>Данный режим не могут использовать предприниматели, имеющие структурные подразделения, а также филиалы и объекты обложения в разных населенных пунктах.</p>\n',
    pros:
      '<h1>Самый популярный налоговый режим</h1>  \n' +
      '    <ul>\n' +
      '        <li>Можно работать с юридическими лицами</li>\n' +
      '        <li>Не требуется ведение бухгалтерского учета (при этом сбор первичных документов обязателен!)</li>\n' +
      '        <li>Налог уменьшается на размер социальных отчислений работников</li>\n' +
      '        <li>Налог уменьшается на 1,5% за каждого работника, если средняя зарплата более:\n' +
      '            <ul>\n' +
      '                <li>23 МРП - 84 916 тенге (для ИП)</li>\n' +
      '                <li>29 МРП – 107 068 тенге (для ТОО)</li>\n' +
      '            </ul>\n' +
      '        </li>\n' +
      '    </ul>',
    cons:
      '<h1>Ограничения упрощенного налогового режима</h1>\n' +
      '    \n' +
      '    <ul>\n' +
      '        <li>Порог по НДС ниже предельного дохода по упрощенному режиму, поэтому возникают 15% штрафы за превышение порога по НДС</li>\n' +
      '        <li>При превышении порога НДС происходит жесткий переход на НДС, что усложняет работу из-за +12% НДС, практически превращая его в налог с оборота</li>\n' +
      '        <li>При минимальных оборотах высокая нагрузка из-за социальных платежей (минимальный платеж за пенсионные, социальные отчисления и соц. мед. страхование – 18 200 тенге, согласно Расчет социальных платежей)</li>\n' +
      '        <li>Существуют ограничения по применению упрощенной декларации (см. Ограничениям по видам деятельности)</li>\n' +
      '    </ul>',
    restrictions:
      '<h1>Ограничения по применению упрощенного налогового режима</h1>\n' +
      '    <h2>Не в праве применять предприниматели, осуществляющие виды деятельности:</h2>\n' +
      '    <ul>\n' +
      '        <li>Консультационные и (или) маркетинговые услуги</li>\n' +
      '        <li>Деятельность в области права, юстиции и правосудия</li>\n' +
      '        <li>Деятельность в области бухгалтерского учета или аудита</li>\n' +
      '        <li>Аренда и эксплуатация торгового рынка</li>\n' +
      '        <li>Сдача в субаренду торговых объектов, относящихся к торговым рынкам, стационарным торговым объектам категории 1, 2 и 3</li>\n' +
      '        <li>Деятельность двух и более налогоплательщиков в сфере предоставления гостиничных услуг на территории одной гостиницы или отдельно стоящего нежилого здания</li>\n' +
      '        <li>Производство подакцизных товаров</li>\n' +
      '        <li>Хранение и оптовая реализация подакцизных товаров</li>\n' +
      '        <li>Реализация отдельных видов нефтепродуктов</li>\n' +
      '        <li>Проведение лотерей</li>\n' +
      '        <li>Недропользование</li>\n' +
      '        <li>Сбор и прием стеклопосуды</li>\n' +
      '        <li>Сбор (заготовка), хранение, переработка и реализация лома и отходов цветных и черных металлов</li>\n' +
      '        <li>Финансовая, страховая деятельность и посредническая деятельность страхового брокера и страхового агента</li>\n' +
      '        <li>Деятельность в рамках финансового лизинга</li>\n' +
      '    </ul>\n' +
      '    <h2>Не в праве применять упрощенный режим:</h2>\n' +
      '    <ul>\n' +
      '        <li>Предприниматели, у которых есть структурные подразделения (филиалы/торговые точки) в разных населенных пунктах (город с разными районами считается одним населенным пунктом)</li>\n' +
      '        <li>Предприниматели, которые являются учредителями в двух и более ТОО на упрощенном режиме (но быть ИП и учредителем ТОО не на упрощенном режиме разрешается)</li>\n' +
      '        <li>ТОО, которые являются учредителями ТОО на упрощенном режиме с долей более 25%</li>\n' +
      '        <li>Предприниматели, оказывающие услуги на основании агентских договоров (соглашений)</li>\n' +
      '    </ul>\n' +
      '    <h2>Дополнительные разъяснения:</h2>\n' +
      '    <h3>Консультационные услуги</h3>\n' +
      '    <p>Под консультационными услугами понимаются услуги по предоставлению разъяснений, рекомендаций, советов и иных форм консультаций, включая определение и (или) оценку проблем и (или) возможностей лица, в целях решения управленческих, экономических, финансовых, инвестиционных вопросов, в том числе вопросов стратегического планирования, организации и осуществления предпринимательской деятельности, управления персоналом.</p>\n' +
      '    <h3>Агентские договоры</h3>\n' +
      '    <p>Под агентскими договорами (соглашениями) понимаются договоры (соглашения) гражданско-правового характера, заключенные в соответствии с законодательством Республики Казахстан, по которым одна сторона (агент) обязуется за вознаграждение совершать по поручению другой стороны определенные действия от своего имени, но за счет другой стороны либо от имени и за счет другой стороны.</p>',
    social_payments:
      '<h1>Расчет социальных платежей для ИП</h1>\n' +
      '    <h2>Минимальный размер заработной платы (МЗП) – 85 000 тенге (2024 г.)</h2>\n' +
      '    <h3>Социальные платежи для ИП на Упрощенке:</h3>\n' +
      '    <ul>\n' +
      '        <li><strong>Пенсионные отчисления:</strong> 10% от МЗП = 8 000 тенге</li>\n' +
      '        <li><strong>Социальные отчисления:</strong> 3,5% от МЗП = 2 975 тенге</li>\n' +
      '        <li><strong>Взнос за соц. мед. страхование:</strong> 5% от 1,4 МЗП = 5 950 тенге</li>\n' +
      '        <li><strong>Пенсионный взнос за счет работодателя:</strong> 1,5% от МЗП = 1 275 тенге</li>\n' +
      '        <li><strong>Итого социальные платежи за ИП на Упрощенке:</strong> 18 200 тенге в месяц</li>\n' +
      '    </ul> \n' +
      '    <h3>Дополнительно для ИП на общеустановленном режиме (ОУР):</h3>\n' +
      '    <ul>\n' +
      '        <li><strong>Социальный налог:</strong> 2 МРП – социальные отчисления = 2 * 3 450 тенге – 2 975 тенге = 4 409 тенге</li>\n' +
      '        <li><strong>Итого социальные платежи для ИП на ОУР:</strong> 22 609 тенге в месяц</li>\n' +
      '    </ul>',
    tax_reduce:
      '<h1>Уменьшение налога за счет фонда оплаты труда работников</h1>\n' +
      '    <table border="1" cellpadding="5" cellspacing="0">\n' +
      '        <thead>\n' +
      '            <tr>\n' +
      '                <th>Показатель</th>\n' +
      '                <th>Упрощенный режим</th>\n' +
      '                <th>Розничный налог</th>\n' +
      '            </tr>\n' +
      '        </thead>\n' +
      '        <tbody>\n' +
      '            <tr>\n' +
      '                <td>Оборот за год</td>\n' +
      '                <td>60 000 000</td>\n' +
      '                <td>60 000 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Ставка налога</td>\n' +
      '                <td>3%</td>\n' +
      '                <td>4%</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Количество работников</td>\n' +
      '                <td>5</td>\n' +
      '                <td>5</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Средняя зарплата</td>\n' +
      '                <td>150 000</td>\n' +
      '                <td>150 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Фонд зарплаты в месяц</td>\n' +
      '                <td>750 000</td>\n' +
      '                <td>750 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Фонд зарплаты в год</td>\n' +
      '                <td>9 000 000</td>\n' +
      '                <td>9 000 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Оборот минус Фонд оплаты труда</td>\n' +
      '                <td>51 000 000</td>\n' +
      '                <td>51 000 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Налог за год</td>\n' +
      '                <td>1 800 000</td>\n' +
      '                <td>2 040 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Социальные отчисления за год</td>\n' +
      '                <td>283 500</td>\n' +
      '                <td>-</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Уменьшение налога на 1,5% за каждого работника</td>\n' +
      '                <td>7,5%</td>\n' +
      '                <td>-</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Итого налог</td>\n' +
      '                <td>1 402 763</td>\n' +
      '                <td>2 040 000</td>\n' +
      '            </tr>\n' +
      '            <tr>\n' +
      '                <td>Итоговый % налога от Оборота</td>\n' +
      '                <td>2,3%</td>\n' +
      '                <td>3,4%</td>\n' +
      '            </tr>\n' +
      '        </tbody>\n' +
      '    </table>',
  },
  Вычет: {
    info:
      '<h1>Специальный режим налогообложения с фиксированным вычетом</h1>\n' +
      '    <p><strong>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.</strong></p>\n' +
      '    <p>Налогом облагается доход компании. Особенность режима в том, что налогооблагаемый доход определяется с учетом корректировок и вычетов, предусмотренных НК РК.</p>\n' +
      '    <p>Предусмотрен дополнительный фиксированный вычет в размере до 30% от суммы дохода, без подтверждающих документов (при соответствии определенным условиям), а также, помимо вычета по расходам на оплату труда, предусмотрено уменьшение налогооблагаемого дохода на 100% от фонда оплаты труда.</p>\n' +
      '    <p>При этом, общая сумма расходов, относимых на вычеты (подтвержденных документально плюс фиксированный вычет), не должна превышать 70% от суммы дохода.</p>\n' +
      '    <p><strong>Налогом облагается разница между доходами и расходами компании в ходе предпринимательской деятельности.</strong></p>\n' +
      '    <h2>Основные параметры</h2>\n' +
      '    <ul>\n' +
      '        <li><strong>Количество сотрудников:</strong> до 50</li>\n' +
      '        <li><strong>Предельный доход:</strong> не превышает 144 184-кратный размер МРП за год</li>\n' +
      '        <li><strong>Ставка КПН для ТОО:</strong> 20% от дохода</li>\n' +
      '        <li><strong>Ставка ИПН для ИП:</strong> 10% от дохода</li>\n' +
      '    </ul>',
    pros:
      '<h1>Преимущества специального режима с фиксированным вычетом</h1>\n' +
      '    <ul>\n' +
      '        <li>Позволяет брать на вычеты без подтверждающих документов, тем самым снижая нагрузку на бухгалтерию, включая расходы, на которые невозможно получить документы</li>\n' +
      '        <li>Количество работников и предельный доход больше, чем на упрощенной декларации</li>\n' +
      '        <li>Если средняя зарплата более 47 МРП, то уменьшение налогооблагаемого дохода на общий размер фонда оплаты труда (ФОТ)</li>\n' +
      '    </ul>',
    cons:
      '<h1>Ограничения специального режима с фиксированным вычетом</h1>\n' +
      '    <ul>\n' +
      '        <li>Порог по НДС остается 20 000 МРП</li>\n' +
      '        <li>Исключены расходы по производству (сырье, материалы, услуги)</li>\n' +
      '        <li>Ограничение по общим вычетам не более 70% расходов практически приравнивает к упрощенному режиму; при низкой марже нет выгоды</li>\n' +
      '        <li>Бухгалтерский учет сложнее, чем по упрощенному режиму</li>\n' +
      '        <li>Непопулярный налоговый режим</li>\n' +
      '    </ul>',
  },
  Розничный: {
    info:
      '<h1>Специальный налоговый режим розничного налога</h1>\n' +
      '<h2>Ставка розничного налога:</h2>\n' +
      '<ul>\n' +
      '<li>4%: по доходам, полученным (подлежащим получению) за налоговый период в РК и за ее пределами физическим лицам;</li>\n' +
      '<li>8%: по доходам, полученным от реализации товаров, выполнения работ, оказания услуг юридическим лицам.</li>\n' +
      '</ul>\n' +
      '<h2>Предприниматели, применяющие СНР розничного налога, вправе:</h2>\n' +
      '<ul>\n' +
      '<li>не вставать на учет в качестве плательщика НДС;</li>\n' +
      '<li>уплачивать единый платеж в пользу работников, который включает в себя ИПН, ОПВ, СО, ОСМС.</li>\n' +
      '</ul>\n' +
      '<p>Налоговым периодом для применения специального налогового режима розничного налога является календарный квартал.</p>\n' +
      '<p>Специальный налоговый режим розничного налога вправе применять налогоплательщики, соответствующие следующим условиям:</p>\n' +
      '<ul>\n' +
      '<li>среднесписочная численность работников за налоговый период не превышает 200 человек;</li>\n' +
      '<li>доход за календарный год не превышает 600 000 МРП;</li>\n' +
      '<li>осуществляющие исключительно один или несколько из видов деятельности, определенных Правительством Республики Казахстан для целей применения данного режима;</li>\n' +
      '<li>признаются субъектами малого и среднего предпринимательства в соответствии с Предпринимательским кодексом РК.</li>\n' +
      '</ul>',
  },
  Крестьянский: {
    info:
      '<h1>Специальный налоговый режим для крестьянских или фермерских хозяйств</h1>\n' +
      '<h2>Специальный налоговый режим вправе применять крестьянские или фермерские хозяйства, при одновременном соответствии следующим условиям: \n' +
      '</h2>\n' +
      '<ul>\n' +
      '<li>Осуществляют исключительно виды деятельности, на которые распространяется данный СНР; </li>\n' +
      '<li>Не являются плательщиками НДС; </li>\n' +
      '<li>Совокупная площадь земель сельскохозяйственного назначения на правах частной собственности  не превышает пределов, установленных п. 2 ст.  702 НК РК.</li>\n' +
      '</ul>\n' +
      '<p>Налогоплательщики, применяющие Специальный налоговый режим для крестьянских или фермерских хозяйств производят уплату единого земельного налога.  Для исчисления единого земельного налога объектом обложения является оценочная стоимость земельного участка.</p>\n' +
      '<p>Исчисление единого земельного налога производится налогоплательщиком самостоятельно путем применения к объекту налогообложения за отчетный налоговый период ставки в размере 0,5%.</p> \n' +
      '<p>Налогоплательщики освобождаются от уплаты ИПН, социального налога, платы за негативное воздействие на окружающую среду, земельного налога, налога на транспортные средства, налога на имущество (ст. 705 НК РК).</p>\n' +
      '<p>Плательщики единого земельного налога  освобождены от уплаты социального налога с 2018 года.</p>\n' +
      '<p>Налоговый период составляет календарный год.</p>',
  },
  Мобильный: {
    info:
      '<h1>Специальный налоговый режим с использованием специального мобильного приложения </h1>\n' +
      '<h2>Налоговый режим вправе применять индивидуальные предприниматели, которые: </h2>\n' +
      '<ul>\n' +
      '<li>не имеют наемных работников;\n' +
      '</li>\n' +
      '<li>осуществляют деятельность в форме личного предпринимательства;\n' +
      '</li>\n' +
      '<li>осуществляют один или несколько из разрешенных видов деятельности;\n' +
      '</li>\n' +
      '<li>не являются плательщиками налога на добавленную стоимость;\n' +
      '</li>\n' +
      '<li>доход за налоговый период не превышает 3 528 МРП.\n' +
      '</li>\n' +
      '</ul>\n' +
      '<p>Не вправе применять специальный налоговый режим на основе патента, упрощенной декларации или с использованием специального мобильного приложения и индивидуальные предприниматели и юридические лица, оказывающие услуги на основании агентских договоров (соглашений).</p>\n' +
      '<p>Основные параметры:</p>\n' +
      '<ul>\n' +
      '<li>Исчисление суммы ИПН производится путем применения ставки в размере 1% к объекту налогообложения.\n' +
      '</li>\n' +
      '<li>Исчисление социальных платежей производится в соответствии с законами Республики Казахстан "О пенсионном обеспечении в Республике Казахстан", "Об обязательном социальном страховании" и "Об обязательном социальном медицинском страховании".</li>\n' +
      '</ul>\n' +
      '<p>Налоговым периодом для применения специального налогового режима на основе патента с использованием фиксированного вычета или специального мобильного приложения является календарный год.</p>\n' +
      '<p>Периодом, за который в специальном мобильном приложении производятся исчисление и уплата индивидуального подоходного налога и социальных платежей, является календарный месяц.</p>',
    allowed_activities:
      '<h1>Разрешенные виды деятельности</h1>\n' +
      '    <ul>\n' +
      '        <li>Штукатурные работы</li>\n' +
      '        <li>Столярные и плотницкие работы</li>\n' +
      '        <li>Работы по покрытию полов и облицовке стен</li>\n' +
      '        <li>Малярные и стекольные работы</li>\n' +
      '        <li>Деятельность такси</li>\n' +
      '        <li>Грузовые перевозки автомобильным транспортом</li>\n' +
      '        <li>Управление недвижимостью за вознаграждение или на договорной основе</li>\n' +
      '        <li>Деятельность в области фотографии</li>\n' +
      '        <li>Переводческое (устное и письменное) дело</li>\n' +
      '        <li>Сдача в имущественный наем (аренду)</li>\n' +
      '        <li>Сдача в имущественный наем (аренду) транспортных средств</li>\n' +
      '        <li>Прокат и имущественный наем (аренду) развлекательного и спортивного инвентаря</li>\n' +
      '        <li>Прокат видеозаписей и дисков</li>\n' +
      '        <li>Прокат и имущественный наем (аренду) прочих предметов личного потребления и бытовых товаров</li>\n' +
      '        <li>Сдача в имущественный наем (аренду) сельскохозяйственной техники и оборудования</li>\n' +
      '        <li>Сдача в имущественный наем (аренду) офисных машин и оборудования, включая вычислительную технику</li>\n' +
      '        <li>Услуги в области спортивного образования и образования специалистов организации досуга</li>\n' +
      '        <li>Услуги образования в сфере культуры</li>\n' +
      '        <li>Услуги в области прочего образования</li>\n' +
      '        <li>Вспомогательные образовательные услуги</li>\n' +
      '        <li>Деятельность в области искусства</li>\n' +
      '        <li>Ремонт компьютеров и периферийного оборудования</li>\n' +
      '        <li>Ремонт коммуникационного оборудования</li>\n' +
      '        <li>Ремонт предметов личного потребления и бытовых товаров</li>\n' +
      '        <li>Парикмахерские услуги</li>\n' +
      '        <li>Маникюр и педикюр</li>\n' +
      '        <li>Ветеринарные услуги</li>\n' +
      '        <li>Услуги по обработке земельных участков</li>\n' +
      '        <li>Услуги по уборке жилых помещений и ведению домашнего хозяйства</li>\n' +
      '        <li>Услуги носильщиков на рынках, вокзалах</li>\n' +
      '        <li>Изготовление и ремонт музыкальных инструментов</li>\n' +
      '        <li>Выпас домашних животных</li>\n' +
      '        <li>Курьерская доставка, за исключением услуг по доставке почтовых отправлений (только для Мобильного приложения)</li>\n' +
      '    </ul>',
  },
  Селхоз: {
    info:
      '<h1>Специальный налоговый режим для производителей сельскохозяйственной продукции,  продукции аквакультуры (рыбоводства) и сельскохозяйственных кооперативов</h1>\n' +
      '<p>Не вправе применять специальные налоговые режимы для производителей сельскохозяйственной продукции иностранные юридические лица, иностранцы и лица без гражданства.</p>\n' +
      '<p>Специальный налоговый режим не распространяется на деятельность по производству или переработке подакцизных товаров. Исчисленные суммы КПН или ИПН, социального налога, налога на имущество и налога на транспортные средства подлежат уменьшению на 70%.</p>\n' +
      '<p>Исчисление налогов, указанных в пункте 1 статьи 700 НК РК, производители сельскохозяйственной продукции, применяющие данный специальный налоговый режим, производят в общеустановленном порядке.</p>\n' +
      '<p>Исчисление, уплата сумм индивидуального подоходного налога, удерживаемого у источника выплаты, и перечисление социальных платежей, за исключением сумм, включенных в единый платеж, производятся налогоплательщиком, применяющим данный специальный налоговый режим, в общеустановленном порядке.</p>\n' +
      '<p>Исчисление, уплата суммы единого платежа производятся в порядке, предусмотренном главой 89-1 настоящего Кодекса.</p>\n' +
      '<p>При условии ведения раздельного учета, налогоплательщики вправе относить в зачет дополнительную сумму НДС.</p>\n' +
      '<p>Уплата налогов в бюджет и представление отчетности производится в общеустановленном порядке.</p>',
  },
};

export const messages_sequence = {
  'Подбери мне режим налогооблажения':
    '<h1>Планируете ли Вы заниматься предпринимательской деятельностью по какому-либо из следующих направлений?</h1>\n' +
    '    <ul>\n' +
    '        <li>Производство, хранение и оптовая реализация подакцизных товаров</li>\n' +
    '        <li>Реализация отдельных видов нефтепродуктов - бензина, дизельного топлива и мазута</li>\n' +
    '        <li>Проведение лотерей</li>\n' +
    '        <li>Недропользование</li>\n' +
    '        <li>Сбор и прием стеклопосуды</li>\n' +
    '        <li>Сбор (заготовка), хранение, переработка и реализация лома и отходов цветных и черных металлов</li>\n' +
    '        <li>Консультационные услуги</li>\n' +
    '        <li>Деятельность в области бухгалтерского учета или аудита</li>\n' +
    '        <li>Финансовая, страховая деятельность и посредническая деятельность страхового брокера и страхового агента</li>\n' +
    '        <li>Деятельность в области права, юстиции и правосудия</li>\n' +
    '        <li>Деятельность в рамках финансового лизинга</li>\n' +
    '        <li>Оказание услуг по договору ГПХ</li>\n' +
    '    </ul>',
  'Покажи все режимы': 'Доступные режимы налогообложения',
  // Нет: 'В какой форме Вы планируете вести свой бизнес?',
  Нет: 'Относитесь ли Вы к производителям сельскохозяйственной продукции? ',
  ИП: 'Сколько работников Вы планируете оформить по трудовому договору?',
  ТОО: 'Сколько работников Вы планируете оформить по трудовому договору?',
  'ИП.Не планирую': 'Какой примерный размер дохода Вы планируете получать?',
  'ИП.до 30': 'Какой примерный размер дохода Вы планируете получать?',
  'ТОО.до 30': 'Какой примерный размер дохода Вы планируете получать?',
  'ТОО.от 30 до 50': 'Какой примерный размер дохода Вы планируете получать?',
  'ТОО.от 50 и больше': 'Какой примерный размер дохода Вы планируете получать?',
  'ИП.До 144184 МРП в год':
    'Вам может подойти один из следующих видод налогообложения. Дальнейший выбор зависит от деталей Вашей бизнес модели.',
  Да: tax_info.Общеустановленный.info,
  'ИП.До 3528 МРП в год':
    'Планируете ли Вы пользоваться Специальным мобильным приложением?',
  'ИП.До 24038 МРП за полугодие': tax_info.Упрощенный.info,
  'ИП.От 144184 МРП в год': tax_info.Общеустановленный.info,
  'Общеустановленный режим налогообложения': tax_info.Общеустановленный.info,
  'ИП.Общеустановленный режим налогообложения': tax_info.Общеустановленный.info,
  'Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.':
    tax_info.Вычет.info,
  'ИП.Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.':
    tax_info.Вычет.info,
  'ТОО.До 24038 МРП за полугодие': tax_info.Упрощенный.info,
  'ТОО.До 144184 МРП в год': tax_info.Вычет.info,
  'ТОО.От 144184 МРП в год': tax_info.Общеустановленный.info,
  'Специальный режим с использованием фиксированного вычета':
    tax_info.Вычет.info,
  'Упрощенный режим налогообложения': tax_info.Упрощенный.info,
  'Специальный налоговый режим на основе патента': tax_info.Патент.info,
  'ИП.от 30 до 50':
    'Вам может подойти один из следующих видод налогообложения. Дальнейший выбор зависит от деталей Вашей бизнес модели.',
  'ИП.от 50 и больше':
    'Вам может подойти один из следующих видод налогообложения. Дальнейший выбор зависит от деталей Вашей бизнес модели.',
  'Специальный налоговый режим розничного налога': tax_info.Розничный.info,
  'Специальный налоговый режим для крестьянских или фермерских хозяйств':
    tax_info.Крестьянский.info,
  'Специальный налоговый режим с использованием специального мобильного приложения':
    tax_info.Мобильный.info,
  'Специальный налоговый режим для производителей сельскохозяйственной продукции и сельскохозяйственных кооперативов':
    tax_info.Селхоз.info,
  'Селхоз.Нет': 'В какой форме Вы планируете вести свой бизнес?',
  'Селхоз.Да': 'Относитесь ли Вы к крестьянским или фермерским хозяйствам? ',
  'Крестьянский.Да': tax_info.Крестьянский.info,
  'Крестьянский.Нет': tax_info.Селхоз.info,
  'ИП.Да': tax_info.Мобильный.info,
  'ИП.Нет': tax_info.Патент.info,
  'ИП.До 600000 МРП в год': tax_info.Розничный.info,
  'ТОО.До 600000 МРП в год': tax_info.Розничный.info,
  'ТОО.от 50 до 200':
    'Вы планируете что Ваш доход будет превышать 600 000 МРП в год?',
  'ТОО.Да': tax_info.Общеустановленный.info,
  'ТОО.Нет': tax_info.Розничный.info,
  'ИП.Специальный налоговый режим розничного налога': tax_info.Розничный.info,
};

export const options_sequence = {
  'Подбери мне режим налогооблажения': ['Да', 'Нет'],
  'Покажи все режимы': [
    'Общеустановленный режим налогообложения',
    'Специальный режим с использованием фиксированного вычета',
    'Упрощенный режим налогообложения',
    'Специальный налоговый режим на основе патента',
    'Специальный налоговый режим розничного налога',
    'Специальный налоговый режим для крестьянских или фермерских хозяйств',
    'Специальный налоговый режим с использованием специального мобильного приложения',
    'Специальный налоговый режим для производителей сельскохозяйственной продукции и сельскохозяйственных кооперативов',
  ],
  'Селхоз.Нет': ['ИП', 'ТОО', 'Назад'],
  'Селхоз.Да': ['Да', 'Нет', 'Назад'],
  Нет: ['Да', 'Нет', 'Назад'],
  'ИП.ИП': ['Не планирую', 'до 30', 'от 30 до 50', 'от 50 и больше', 'Назад'],
  'ТОО.ТОО': [
    'до 30',
    'от 30 до 50',
    'от 50 и больше',
    'от 50 до 200',
    'Назад',
  ],
  'ИП.Не планирую': ['До 3528 МРП в год', 'До 24038 МРП за полугодие', 'Назад'],
  'ИП.до 30': [
    'До 24038 МРП за полугодие',
    'До 144184 МРП в год',
    'От 144184 МРП в год',
    'До 600000 МРП в год',
    'Назад',
  ],
  'ИП.До 144184 МРП в год': [
    'Общеустановленный режим налогообложения',
    'Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.',
    'Назад',
  ],
  'ТОО.до 30': [
    'До 24038 МРП за полугодие',
    'До 144184 МРП в год',
    'От 144184 МРП в год',
    'До 600000 МРП в год',
    'Назад',
  ],
  'ТОО.от 30 до 50': [
    'До 24038 МРП за полугодие',
    'До 144184 МРП в год',
    'От 144184 МРП в год',
    'До 600000 МРП в год',
    'Назад',
  ],
  'ТОО.от 50 и больше': [
    'До 24038 МРП за полугодие',
    'До 144184 МРП в год',
    'От 144184 МРП в год',
    'Назад',
  ],
  'ИП.До 3528 МРП в год': ['Да', 'Нет', 'Назад'],
  'ТОО.от 50 до 200': ['Да', 'Нет', 'Назад'],
  'ИП.от 30 до 50': [
    `Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.`,
    'Специальный налоговый режим розничного налога',
    'Назад',
  ],
  'ИП.от 50 и больше': [
    'Общеустановленный режим налогообложения',
    'Специальный налоговый режим розничного налога',
    'Назад',
  ],
};
