import { ConfigProvider, Spin } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint.js';
import ContentContainer from './components/ContentContainter/ContentContainer.jsx';
import {
  wsGetAlmatyPolygons,
  wsGetLiteData,
  wsGetRBPData,
} from './utils/webSocketConfig.js';
import { $loader, $webSocketConnected } from './models/webSocketModel/index.js';
import {
  $authStatus,
  $isDarkTheme,
  $userInfo,
  $userList,
  getUserListFx,
  setAuthStatusEv,
} from './models/authModel/index.js';
import { cookies } from './api/axiosinstance.js';
import {
  addResponsiveRules,
  darkTheme,
  lightTheme,
} from './themeConfigs/themeConfigs.js';
import { $zoom10Hexagons } from './models/zoom10Model/index.js';
import { $zoom9Hexagons } from './models/zoom9Model/index.js';
import { getUserAppointedPresetFx } from './models/dataPresetsModel/index.js';
import { getZeroDataFx } from './models/zoom7Model/index.js';
import useWindowSize from './hooks/useWindowSize.js';
import {
  getBuildingInfoFx,
  setActivePresetEv,
  toggleIsPresetEv,
} from './models/rbpLiteModel/index.js';
import { getIsochroneForPaymentsEv } from './models/isochroneModel/index.js';
import { setChosenPointEv } from './models/rbpLandingModel/index.js';

const checkRouteAccess = (location) => {
  const nonAuthRoutes = [
    '/public',
    '/chat',
    '/chat-fundraising',
    '/banking',
    '/crowdfunding',
    '/realestate',
    '/elnet',
    '/heatsys',
    '/gassup',
    '/watersup',
    '/landrules',
    '/business-protection',
    '/business-plan',
    '/trademark',
    '/chat-v2',
    '/licensing',
    '/ip-rights',
    '/cert-consultation',
    '/tax-consultation',
    '/master',
    '/egov-chat',
    '/egov-chat-fundraising',
    '/egov-banking',
    '/egov-crowdfunding',
    '/egov-realestate',
    '/egov-elnet',
    '/egov-heatsys',
    '/egov-gassup',
    '/egov-watersup',
    '/egov-landrules',
    '/egov-business-protection',
    '/egov-business-plan',
    '/egov-trademark',
    '/egov-chat-v2',
    '/egov-licensing',
    '/egov-ip-rights',
    '/egov-certificates',
    /egov-certificates\/\d+/g,
    '/egov-tax-modes',
    /egov-tax-modes\/\d+/g,
    '/egov-tax-modes-test',
    '/main',
    '/',
    '/payment',
    '/redirect',
    '/thank-you',
  ];
  return nonAuthRoutes.some((item) => {
    if (typeof item === 'string') {
      return item === location.pathname;
    }
    return item.test(location.pathname);
  });
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const webSocketConnected = useUnit($webSocketConnected);
  const showLoader = useUnit($loader);
  const authStatus = useUnit($authStatus);
  const setAuth = useUnit(setAuthStatusEv);
  const isDarkTheme = useUnit($isDarkTheme);
  const zoom9hexagons = useUnit($zoom9Hexagons);
  const getUserList = useUnit(getUserListFx);
  const userList = useUnit($userList);
  const userInfo = useUnit($userInfo);
  const setActivePreset = useUnit(setActivePresetEv);

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (userInfo.email === 'tezber@gmail.com') {
      navigate('/tezber');
    } else if (location.pathname === '/') {
      if (location.search.includes('order')) {
        navigate('/payment');
      } else {
        navigate('/main');
      }
    }
  }, [location, userInfo]);

  useEffect(() => {
    if (location.pathname === '/public') {
      getZeroDataFx();
    } else if (webSocketConnected && Object.keys(userInfo).length > 0) {
      if (zoom9hexagons.length === 0 && location.pathname === '/rbp') {
        if (userInfo.use_rbp_preset) {
          getUserAppointedPresetFx(userInfo.presets.rbp[0].id); // FIXME id of appointed preset form user
        } else {
          wsGetRBPData();
          window.download_start = performance.now();
        }
      }
      if (location.pathname === '/user-list' && userList.length === 0) {
        getUserList();
      }
      if (location.pathname === '/lite') {
        getZeroDataFx();
        if (userInfo?.presets?.rbp_lite?.length > 0) {
          const lastPreset =
            userInfo.presets.rbp_lite[userInfo.presets.rbp_lite.length - 1];
          wsGetLiteData(lastPreset.isochrones);
          // getIsochroneForPaymentsEv({
          //   lat: userInfo.presets.rbp_lite[userInfo.presets.rbp_lite.length - 1]
          //     .isochrones.lat,
          //   lon: userInfo.presets.rbp_lite[userInfo.presets.rbp_lite.length - 1]
          //     .isochrones.lon,
          // });
          setChosenPointEv({
            lat: lastPreset.isochrones.lat,
            lon: lastPreset.isochrones.lon,
          });
          setActivePreset({
            ...lastPreset.isochrones,
            id: lastPreset.id,
          });
        } else {
          setTimeout(() => {
            setActivePreset({ business: 1, sub_business: 1 });
            toggleIsPresetEv(false);
          }, 1000);
        }
      }
      // console.time('TIME:: zoom 8 polygons_v2');
      // console.time('TIME:: zoom 9 polygons_v2');
      // console.time('TIME:: download zoom 10 polygons');
      // wsGetAlmatyPolygonsV2();
    }
  }, [webSocketConnected, zoom9hexagons, location, userInfo]);

  useEffect(() => {
    if (!authStatus && !checkRouteAccess(location)) {
      const token = cookies.get('access_token');
      if (!token) {
        navigate('/login');
      } else {
        setAuth(true);
      }
    }
  }, [authStatus, location]);

  let theme = isDarkTheme ? darkTheme : lightTheme;

  if (location.pathname === '/tezber') {
    theme = addResponsiveRules(theme, width);
  }

  return (
    <ConfigProvider theme={theme}>
      <Spin spinning={showLoader} tip="Загружаем данные..." size="large">
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Spin>
    </ConfigProvider>
  );
}

export default App;
