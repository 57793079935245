import { createEvent } from 'effector';

export const changeLanguageEv = createEvent();

export const sendRegRequestEv = createEvent();

export const resetErrorEv = createEvent();

export const resetSuccessEv = createEvent();

export const changeMenuModalEv = createEvent();

export const changeShowRequestModalEv = createEvent();

export const setChosenPointEv = createEvent();

export const changeSecondGradientEv = createEvent();

export const sendPaymentEv = createEvent();

export const openPaymentUrlEv = createEvent();

export const resetRedirectLinkEv = createEvent();

export const changePaymentBusinessEv = createEvent()

export const resetPaymentBusinessEv = createEvent()

export const userExistsEv = createEvent()

export const newUserEv = createEvent()

export const resetUserExistsEv = createEvent()
