import { createStore } from 'effector';

const initialStore = {
  draw_polygon: [],
  quarter: 11,
  district: [],
  zoom8_hex: {
    id: '',
    centerCoords: [],
  },
  zoom9_hex: {
    id: '',
    centerCoords: [],
  },
  zoom10_hex: {
    id: '',
    centerCoords: [],
  },
  excludedIndexes: [],
  gradient: [],
  chosen_metrics: ['d1'],
  business_type: 401,
  groups: [],
};

const initPubStore = {
  population_total: [0, 0],
  households: [0, 0],
  business_type: [0, 0],
  price_per_sq_m_Com: [0, 0],
  pedestrian_connect: [0, 0],
  tj_lvl_max: [0, 0],
  pt_route_cnt: [0, 0],
  parking_place_cnt: [0, 0],
  income_median: [0, 0],
  population_category: [],
};

const initRangeStore = {
  population_total: 0,
  households: 0,
  business_type: 0,
  price_per_sq_m_Com: 0,
  pedestrian_connect: 0,
  tj_lvl_max: 0,
  pt_route_cnt: 0,
  parking_place_cnt: 0,
  income_median: 0,
};

if (window.location.pathname === '/public') {
  initialStore.chosen_metrics = ['competitors_cnt_B'];
}

if (window.location.pathname === '/rbp') {
  initialStore.chosen_metrics = [];
}

export const $activeFilters = createStore(initialStore);
$activeFilters.watch((state) => console.log('$activeFilters', state));

export const $chartFilters = createStore([]);
$chartFilters.watch((state) => console.log('$chartFilters', state));

export const $publicFilters = createStore(initPubStore);
$publicFilters.watch((state) => console.log('$publicFilters', state));

export const $publicRangeMax = createStore(initRangeStore);
export const $publicRangeMin = createStore(initRangeStore);
$publicRangeMin.watch((state) => console.log('$publicRangeMin', state));
