export const education_steps_desc = {
  profile: {
    title: 'Личный кабинет',
    desc: 'В личном кабинете Вы можете получить информацию количестве доступных локаций и при необходимости приобрести дополнительные. Здесь же будут доступны все Ваши отчеты, сформированные ранее. Вы сможете загрузить их на интерактивную карту и экспортировать в формат Excel.',
  },
  profileModal: {
    title: 'Личный кабинет',
    desc: 'В личном кабинете Вы можете получить информацию количестве доступных локаций и при необходимости приобрести дополнительные. Здесь же будут доступны все Ваши отчеты, сформированные ранее. Вы сможете загрузить их на интерактивную карту и экспортировать в формат Excel.',
  },
  isPreset: {
    title: 'Выбор новой локации для оценки.',
    desc: 'При выборе новой локации Вам будет доступна базовая аналитика на весь город. С ее помощью удобно провести предварительный анализ территорий',
  },
  publicBusiness: {
    title: 'Выбор типа бизнеса для анализа конкурентного поля.',
    desc: 'Вам необходимо выбрать тип бизнеса для оценки плотности конкурентов и последующего расчета охватов',
  },
  publicDetails: {
    title: 'Просмотр базовых характеристик территорий.',
    desc: 'В панели справа представлены базовые характеристики города и отдельных участков. Для просмотра информации на отдельный участок, нажмите на ячейку на карте.',
  },
  publicCreate: {
    title: 'Создание отчета для локации.',
    desc: 'После определения локации на основе предварительного анализы, укажите точку на карте, для которой хотите сформировать подробный отчет. Отчет сформируется для зоны 15-минутной пешеходной доступности и откроется в основном интерфейсе платформы.',
  },
  liteCategory: {
    title: 'Просмотр характеристик локации и отдельных территорий.',
    desc: 'В панели справа представлены характеристики выбранной локации (15-минутной зоны доступности) и средние значения по городу. Для просмотра информации по отдельному участку, нужно выбрать его на карте. Для просмотра детальной информации, нажмите на интересующий показатель.',
  },
  liteChart: {
    title: 'Просмотр детальных характеристик локации и отдельных территорий.',
    desc: 'Ряд инфографики имеют интерактивных интерфейс. Например, если у Вас есть понимание своей целевой аудитории, ее можно указать с помощью графика, выбрав соответствующие столбцы. При этом примененные фильтры будут применены к карте.',
  },
  gradient: {
    title: 'Фильтрация участков по значению на карте',
    desc: 'Для фильтрации характеристик по значениям удобно использовать интерактивную легенду. Для этого выберите один, или несколько диапазонов.',
  },
  mapDetails: {
    // title: 'mapDetails',
    title: (
      <>
        <br />
        Выберите область на карте для просмотра ее характеристик в панели
        справа.
      </>
    ),
  },
  mapCreate: {
    title: (
      <>
        <br />
        Укажите точку на карте
      </>
    ),
    // desc: 'Укажите точку на карте',
  },
  map: {
    title: 'Map',
    desc: 'Map desc',
  },
  categories: {
    title: 'Categories',
    desc: 'Categories desc',
  },
};
