import { createEvent } from 'effector';

export const sendMessageEv = createEvent();

export const resetAllEv = createEvent();

export const resetMessagesEv = createEvent();

export const resetOptionsEv = createEvent();

export const resetResultDataEv = createEvent()

export const addInfoEv = createEvent()

export const goBackByResultEv = createEvent()
