import React from 'react';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import LiteCategory from './LiteCategory/LiteCategory.jsx';
import PublicCategory from '../PublicCategory/PublicCategory.jsx';
import PublicBusinessType from '../PublicBusinessType/PublicBusinessType.jsx';
import { $isPreset } from '../../models/rbpLiteModel/index.js';
import RentSuggestions from './RentSuggestions/RentSuggestions.jsx';
import MapGeoCoder from '../MapGeoCoder/MapGeoCoder.jsx';
import BuildingInfo from './BuildingInfo/BuildingInfo.jsx';

function LiteMapWidgetsManager() {
  const isPreset = useUnit($isPreset);

  if (isPreset) {
    return (
      <>
        <RentSuggestions />
        <LiteCategory />
        <BuildingInfo />
      </>
    );
  }
  return (
    <>
      <MapGeoCoder />
      <PublicBusinessType />
      <PublicCategory />
    </>
  );
}

export default LiteMapWidgetsManager;
