import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import mapboxgl from 'mapbox-gl';
import { $mapLoaded } from '../../models/mapModel/index.js';
import attracts from '../../data/public_attracts.json';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import { $attractLayer } from '../../models/zoom7Model/index.js';

const icons_dict = {
  БЦ: 'bc',
  ТЦ: 'tc',
  ТРЦ: 'trc',
};

const days_translation = {
  Fri: 'Пятница',
  Mon: 'Понедельник',
  Thu: 'Четверг',
  Tue: 'Вторник',
  Wed: 'Среда',
  Sat: 'Суббота',
  Sun: 'Воскресенье',
};

const onClick = (e) => {
  const feature = e.features[0];
  const coordinates = feature.geometry.coordinates.slice();

  // const schedule_source = JSON.parse(feature.properties.schedule);
  // if (schedule_source.is_24x7) delete schedule_source.is_24x7;
  // if (schedule_source.comment) delete schedule_source.comment;
  // const schedule = Object.fromEntries(
  //   Object.keys(schedule_source).map((item) => {
  //     return [
  //       item,
  //       Object.values(schedule_source[item].working_hours[0]).join(' - '),
  //     ];
  //   })
  // );

  const html = `
            <div class="popup_content_wrapper">
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Название:</div> 
                <div class="popup_item_value">${
                  feature.properties.name || '-'
                }</div>
              </div>
             
            </div>`;

  // <div className="popup_item_wrapper popup_item_odd">
  //   <div className="popup_item_field">Расписание:</div>
  //   <div className="popup_item_value">
  //     <div>${days_translation.Mon} : ${schedule.Mon || '-'}</div>
  //     <div>${days_translation.Tue} : ${schedule.Tue || '-'}</div>
  //     <div>${days_translation.Wed} : ${schedule.Wed || '-'}</div>
  //     <div>${days_translation.Thu} : ${schedule.Thu || '-'}</div>
  //     <div>${days_translation.Fri} : ${schedule.Fri || '-'}</div>
  //     <div>${days_translation.Sun} : ${schedule.Sun || '-'}</div>
  //     <div>${days_translation.Sat} : ${schedule.Sat || '-'}</div>
  //   </div>

  new mapboxgl.Popup().setLngLat(coordinates).setHTML(html).addTo(window.map);
};

function PublicAttracts() {
  const mapLoaded = useUnit($mapLoaded);
  const attractLayer = useUnit($attractLayer);

  const createLayer = (update) => {
    const attractsData = {
      type: 'FeatureCollection',
      features: attractLayer
        ? attracts.features.filter(
            (item) => item.properties.type === attractLayer
          )
        : attracts.features,
    };

    if (update) {
      window.map.getSource('public_attracts_source').setData(attractsData);
      window.map.setLayoutProperty(
        'public_attracts_layer',
        'icon-image',
        icons_dict[attractLayer]
      );
    } else {
      if (!window.map.getSource('public_attracts_source')) {
        window.map.addSource('public_attracts_source', {
          type: 'geojson',
          data: attractsData,
        });
      }

      if (!window.map.getLayer('public_attracts_layer')) {
        window.map.addLayer({
          id: 'public_attracts_layer',
          type: 'symbol',
          source: 'public_attracts_source',
          layout: {
            'image-size': 1,
          },
        });
      }

      window.map.on('click', 'public_attracts_layer', onClick);

      changeLayerVisibility('public_attracts_layer', false);
    }
  };

  useEffect(() => {
    if (mapLoaded && attracts) {
      if (window.map.getLayer('public_attracts_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [mapLoaded, attracts, attractLayer]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('public_attracts_layer')) {
      if (attractLayer) {
        changeLayerVisibility('public_attracts_layer', true);
      } else {
        changeLayerVisibility('public_attracts_layer', false);
      }
    }
  }, [mapLoaded, attractLayer]);
  return null;
}

export default PublicAttracts;
