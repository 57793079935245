import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import style from './RestorePassword.module.css';
import {
  $authStatus,
  $restoreSuccess,
  loginEv,
  loginFx,
  restorePasswordFx,
  sendForgotPasswordEv,
  sendRestorePasswordEv,
} from '../../models/authModel/index.js';
import useWindowSize from '../../hooks/useWindowSize.js';
import { cookies } from '../../api/axiosinstance.js';
import logo from '../../assets/logo.svg';
import login_image from '../../assets/login_image.png';
import thank_you from '../../assets/RbpLandingImages/thank_you.svg';

function RestorePassword(props) {
  const authStatus = useUnit($authStatus);
  const navigate = useNavigate();
  const isLoading = useUnit(restorePasswordFx.pending);
  const sendForgotPassword = useUnit(sendForgotPasswordEv);
  const sendRestorePassword = useUnit(sendRestorePasswordEv);
  const [width, height] = useWindowSize();
  const { id } = useParams();
  const restoreSuccess = useUnit($restoreSuccess);

  useEffect(() => {
    if (authStatus) {
      const token = cookies.get('access_token');
      if (token) {
        navigate('/');
      }
    }
  }, [authStatus]);

  useEffect(() => {
    if (!id || !/.{8}-.{4}-.{4}-.{4}-.{12}/g.test(id)) {
      navigate('/login');
    }
  }, [id]);

  const onFinish = (values) => {
    sendRestorePassword({
      ...values,
      unique_token: id,
    });
  };

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={style.login_wrapper}>
      <div className={style.login_form_wrapper}>
        <div className={style.login_form} id="login-form">
          <img src={logo} className={style.login_logo} />
          {restoreSuccess ? (
            <div className={style.forgot_success}>
              <img src={thank_you} className={style.forgot_img} />
              <div className={style.forgot_subtitle}>
                Пароль успешно изменен
              </div>
              <Button
                type="primary"
                className={style.login_button}
                style={{ marginTop: 20 }}
                onClick={goToLogin}
              >
                Войти
              </Button>
            </div>
          ) : (
            <Form
              name="loginForm"
              id="loginForm"
              onFinish={onFinish}
              layout="vertical"
              style={{ marginTop: 30 }}
            >
              <Form.Item name="password" label="Введите новый пароль">
                <Input.Password
                  allowClear
                  placeholder="********"
                  className={style.login_input}
                />
              </Form.Item>
              {/* <div className="login-register"> */}
              <Button
                loading={isLoading}
                htmlType="submit"
                form="loginForm"
                type="primary"
                className={style.login_button}
              >
                Изменить
              </Button>
              {/* <Link to="/register">Зарегистрироваться</Link> */}
              {/* </div> */}
            </Form>
          )}

          {/* <Button onClick={sendForgotPassword}>Forgot</Button> */}
          {/* <Button onClick={sendRestorePassword}>Restore</Button> */}
        </div>
      </div>
      {width > 1200 ? (
        <img src={login_image} className={style.login_image} />
      ) : (
        ''
      )}
    </div>
  );
}

export default RestorePassword;
