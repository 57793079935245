import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { $isochroneStore } from '../../models/isochroneModel/index.js';
import { $mapLoaded } from '../../models/mapModel/index.js';
import { $isPreset } from '../../models/rbpLiteModel/index.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';

export default function IsochroneLayer() {
  const isochroneFeatures = useUnit($isochroneStore);
  const mapLoaded = useUnit($mapLoaded);
  const { pathname } = useLocation();
  const isPreset = useUnit($isPreset);

  const createLayer = (update) => {
    const isochroneData = {
      type: 'FeatureCollection',
      features: isochroneFeatures,
    };
    if (update) {
      window.map.getSource('isochrone_source').setData(isochroneData);
    } else {
      if (!window.map.getSource('isochrone_source')) {
        window.map.addSource('isochrone_source', {
          type: 'geojson',
          data: isochroneData,
        });
      }

      if (!window.map.getLayer('isochrone_layer')) {
        window.map.addLayer({
          id: 'isochrone_layer',
          type: 'line',
          source: 'isochrone_source',
          paint: {
            'line-color': 'black',
            'line-width': pathname === '/lite' ? 5 : 1,
            // 'fill-color': '#bf4040',
            // 'fill-opacity': 0.3,
          },
        });
      }

      // window.map.moveLayer('isochrone_layer');
    }
  };

  useEffect(() => {
    if (mapLoaded) {
      if (window.map.getLayer('isochrone_layer')) {
        createLayer(true);
      } else if (isochroneFeatures.length) {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [isochroneFeatures, mapLoaded]);

  useEffect(() => {
    if (
      mapLoaded &&
      pathname === '/lite' &&
      window.map.getLayer('isochrone_layer')
    ) {
      if (isPreset) {
        changeLayerVisibility('isochrone_layer', true);
      } else {
        changeLayerVisibility('isochrone_layer', false);
      }
    }
  }, [mapLoaded, isPreset]);

  return null;
}
