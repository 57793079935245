import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import style from '../RbpLanding.module.css';
import {
  $language,
  $redirectLink,
  resetRedirectLinkEv,
} from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { resetUserInfoEv } from '../../../models/authModel/index.js';
import {
  clearIsochroneEv,
  clearIsochroneFeaturesEv,
} from '../../../models/isochroneModel/index.js';

function RedirectPage() {
  const language = useUnit($language);
  const redirectLink = useUnit($redirectLink);
  const navigate = useNavigate();
  const resetRedirectLink = useUnit(resetRedirectLinkEv);
  const resetUserInfo = useUnit(resetUserInfoEv);
  const clearIsochroneFeatures = useUnit(clearIsochroneFeaturesEv);

  const onClick = () => {
    window.open(redirectLink, 'newwindow', 'width=600, height=770');
  };

  const goToLogin = () => {
    clearIsochroneFeatures();
    resetUserInfo();
    navigate('/login');
  };

  const goBack = () => {
    resetRedirectLink();
    navigate(-1);
  };

  useEffect(() => {
    if (!redirectLink) {
      navigate('/payment');
    }
  }, [redirectLink]);

  return (
    <div className={style.redirect_wrapper}>
      <div className={style.container} style={{ position: 'relative' }}>
        <Button
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
          className={style.back_button}
        >
          {rbp_landing_translation[language].redirect_back_button}
        </Button>
        <div className={style.redirect_title}>
          {rbp_landing_translation[language].redirect_title}
        </div>
        <div className={style.redirect_subtitle}>
          {rbp_landing_translation[language].redirect_subtitle}
        </div>
        <Button className={style.redirect_button} onClick={onClick}>
          {rbp_landing_translation[language].redirect_button}
        </Button>
        {/* <div className={style.redirect_title} style={{ marginTop: 20 }}> */}
        {/*  {rbp_landing_translation[language].redirect_login_title} */}
        {/* </div> */}
        <div className={style.redirect_subtitle}>
          {rbp_landing_translation[language].redirect_login_title}
          <br />
          {rbp_landing_translation[language].redirect_login_subtitle}
        </div>
        <Button className={style.redirect_button} onClick={goToLogin}>
          {rbp_landing_translation[language].redirect_login_button}
        </Button>
      </div>
    </div>
  );
}

export default RedirectPage;
