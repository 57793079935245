import React from 'react';
import { Button, Modal } from 'antd';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import {
  $language,
  $userExistsModal,
  resetUserExistsEv,
} from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import style from '../RbpLanding.module.css';

function UserExistsModal() {
  const open = useUnit($userExistsModal);
  const reset = useUnit(resetUserExistsEv);
  const language = useUnit($language);

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <Modal open={open} footer={false} onCancel={reset} centered>
      <div className={style.user_exists_wrapper}>
        <div
          className={style.user_exists_message}
          dangerouslySetInnerHTML={{
            __html: rbp_landing_translation[language].user_exists_message,
          }}
        />
        <Button
          className={style.user_exists_button}
          onClick={goToLogin}
          type="primary"
        >
          {rbp_landing_translation[language].user_exists_button}
        </Button>
      </div>
    </Modal>
  );
}

export default UserExistsModal;
