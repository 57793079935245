import { useUnit } from 'effector-react';
import { Tabs, Tooltip } from 'antd';
import Icon, { StarFilled } from '@ant-design/icons';
import { $socdemStore } from '../../models/socdemModel/index.js';
import { $economicsStore } from '../../models/economicsModel/index.js';
import ColumnChart from './Charts/ColumnChart.jsx';
import style from './InfographicsTabs.module.css';
import BarChart from './Charts/BarChart.jsx';
import PieChart from './Charts/PieChart.jsx';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $isTablet,
  $language,
  $userInfo,
} from '../../models/authModel/index.js';
import { rbp_small_icons_dict } from '../../dictionaries/icons_dict.jsx';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import InfoCards from './Charts/InfoCards.jsx';

const columnIndexes = ['101', '102', '103', '104', '106', '107'];
const barIndexes = ['105', '201', '202', '203'];
const pieIndexes = ['205'];
const cardIndexes = ['204'];

const chartTypeDistributor = (index, props) => {
  if (columnIndexes.includes(index)) {
    return <ColumnChart {...props} />;
  }
  if (barIndexes.includes(index)) {
    return <BarChart {...props} />;
  }
  if (pieIndexes.includes(index)) {
    return <PieChart {...props} />;
  }
  if (cardIndexes.includes(index)) {
    return <InfoCards {...props} />;
  }
};

export default function InfographicsTabs(props) {
  const { expanded, infActiveTab, onTabClick } = props;

  const socdemStore = useUnit($socdemStore);
  const economicsStore = useUnit($economicsStore);
  const language = useUnit($language);
  const isTablet = useUnit($isTablet);
  const userFavs = useUnit($userInfo).rbp_chart_favorites;
  const userInfo = useUnit($userInfo);

  const tabsItems = [
    {
      key: '0',
      label: (
        <Tooltip
          trigger={isTablet ? null : 'hover'}
          title={
            userFavs.length > 0
              ? ru_en_page_dictionary.fav_metrics[language]
              : ru_en_page_dictionary.no_fav_metrics[language]
          }
          placement="right"
        >
          <StarFilled className={style.fav_button} />
        </Tooltip>
      ),
      children: (
        <div
          className={style.tab_container}
          style={{
            height: isTablet ? '84vh' : expanded ? '518px' : '254px',
          }}
        >
          <div className={style.tab_wrapper}>
            {userFavs.map((item, index) => {
              if (index % 2 === 0 && index !== userFavs.length - 1) {
                const firstChart = item;
                const secondChart = userFavs[index + 1];

                const firstChartSource = Object.keys(socdemStore).includes(
                  firstChart.toString()
                )
                  ? socdemStore
                  : economicsStore;
                const secondChartSource = Object.keys(socdemStore).includes(
                  secondChart.toString()
                )
                  ? socdemStore
                  : economicsStore;

                const firstChartProps = {
                  key: `favorites-${firstChart}-${index}`,
                  data: firstChartSource[firstChart],
                  title: firstChart,
                };
                const secondChartProps = {
                  key: `favorites-${secondChart}-${index + 1}`,
                  data: secondChartSource[secondChart],
                  title: secondChart,
                  expanded,
                };
                return (
                  <div
                    className={style.double_chart_wrapper}
                    style={{
                      width: expanded ? '530px' : '1070px',
                      columnGap: expanded ? 'unset' : '10px',
                    }}
                    key={`favorites-double-chart-${index}`}
                  >
                    {chartTypeDistributor(
                      firstChart.toString(),
                      firstChartProps
                    )}
                    {chartTypeDistributor(
                      secondChart.toString(),
                      secondChartProps
                    )}
                  </div>
                );
              }
              if (index === userFavs.length - 1 && userFavs.length % 2 !== 0) {
                const source = Object.keys(socdemStore).includes(
                  item.toString()
                )
                  ? socdemStore
                  : economicsStore;
                const chartProps = {
                  key: `favorites-${item}-${index}`,
                  data: source[item],
                  title: item.toString(),
                };
                return chartTypeDistributor(item.toString(), chartProps);
              }
              return null;
            })}
          </div>
        </div>
      ),
    },
    {
      key: '1',
      label: (
        <Tooltip
          trigger={isTablet ? null : 'hover'}
          title={index_names_dynamics.d1[`name_${language}`]}
          placement="right"
        >
          <Icon
            component={rbp_small_icons_dict[1]}
            style={{
              marginRight: 'unset',
            }}
          />
        </Tooltip>
      ),
      children: (
        <div
          className={style.tab_container}
          style={{
            height: isTablet ? '84vh' : expanded ? '518px' : '254px',
          }}
        >
          <div className={style.tab_wrapper}>
            {Object.keys(socdemStore).map((item, index) => {
              if (
                index % 2 === 0 &&
                index !== Object.keys(socdemStore).length - 1
              ) {
                const firstChart = item;
                const secondChart = Object.keys(socdemStore)[index + 1];

                const firstChartProps = {
                  key: `socdem-${firstChart}-${index}`,
                  data: socdemStore[firstChart],
                  title: firstChart,
                };
                const secondChartProps = {
                  key: `socdem-${secondChart}-${index + 1}`,
                  data: socdemStore[secondChart],
                  title: secondChart,
                  expanded,
                };
                return (
                  <div
                    className={style.double_chart_wrapper}
                    style={{
                      width: expanded ? '530px' : '1070px',
                      columnGap: expanded ? 'unset' : '10px',
                    }}
                    key={`socdem-double-chart-${index}`}
                  >
                    {chartTypeDistributor(firstChart, firstChartProps)}
                    {chartTypeDistributor(secondChart, secondChartProps)}
                  </div>
                );
              }
              if (
                index === Object.keys(socdemStore).length - 1 &&
                Object.keys(socdemStore).length % 2 !== 0
              ) {
                const chartProps = {
                  key: `socdem-${item}-${index}`,
                  data: socdemStore[item],
                  title: item,
                };
                return chartTypeDistributor(item, chartProps);
              }
              return null;
            })}
          </div>
        </div>
      ),
    },
  ];

  if (userInfo.email !== 'airba@qala-ai.com') {
    tabsItems.push({
      key: '2',
      label: (
        <Tooltip
          trigger={isTablet ? null : 'hover'}
          title={index_names_dynamics.d2[`name_${language}`]}
          placement="right"
        >
          <Icon
            component={rbp_small_icons_dict[4]}
            style={{
              marginRight: 'unset',
            }}
          />
        </Tooltip>
      ),
      children: (
        <div
          className={style.tab_container}
          style={{
            height: isTablet ? '84vh' : expanded ? '518px' : '254px',
          }}
        >
          <div className={style.tab_wrapper}>
            {Object.keys(economicsStore).map((item, index) => {
              if (
                index % 2 === 0 &&
                index !== Object.keys(economicsStore).length - 1
              ) {
                const firstChart = item;
                const secondChart = Object.keys(economicsStore)[index + 1];

                const firstChartProps = {
                  key: `economics-${firstChart}-${index}`,
                  data: economicsStore[firstChart],
                  title: firstChart,
                };
                const secondChartProps = {
                  key: `economics-${secondChart}-${index + 1}`,
                  data: economicsStore[secondChart],
                  title: secondChart,
                  expanded,
                };

                return (
                  <div
                    className={style.double_chart_wrapper}
                    style={{
                      width: expanded ? '530px' : '1070px',
                      columnGap: expanded ? 'unset' : '10px',
                    }}
                    key={`economic-double-chart-${index}`}
                  >
                    {chartTypeDistributor(firstChart, firstChartProps)}
                    {chartTypeDistributor(secondChart, secondChartProps)}
                  </div>
                );
              }
              if (
                index === Object.keys(economicsStore).length - 1 &&
                Object.keys(economicsStore).length % 2 !== 0
              ) {
                const chartProps = {
                  key: `econimic-${item}-${index}`,
                  data: economicsStore[item],
                  title: item,
                };
                return chartTypeDistributor(item, chartProps);
              }
              return null;
            })}
          </div>
        </div>
      ),
    });
  }

  return (
    <Tabs
      items={tabsItems}
      tabPosition="left"
      activeKey={infActiveTab}
      onChange={onTabClick}
      id="rbp_top_bar_tabs"
      tabBarStyle={{
        minWidth: '25px',
        alignItems: 'center',
      }}
    />
  );
}
