import { sample } from 'effector';
import { spread } from 'patronum';
import {
  addInfoEv,
  goBackByResultEv,
  resetResultDataEv,
  sendMessageEv,
} from './events.js';
import { $currentOptions, $messages, $resultData } from './stores.js';
import {
  messages_sequence,
  options_sequence,
  tax_info,
} from '../../data/tax_consultation_dict.jsx';
import { resetAllEv, resetMessagesEv, resetOptionsEv } from './index.js';

const result_options = [
  'Да',
  'ИП.До 3528 МРП в год',
  'ИП.До 24038 МРП за полугодие',
  'ИП.От 144184 МРП в год',
  'Общеустановленный режим налогообложения',
  'ИП.Общеустановленный режим налогообложения',
  'ТОО.До 24038 МРП за полугодие',
  'ТОО.До 144184 МРП в год',
  'ТОО.От 144184 МРП в год',
  'Упрощенный режим налогообложения',
  'Специальный налоговый режим на основе патента',
  'Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.',
  'ИП.Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.',
  'Специальный режим с использованием фиксированного вычета',
  'ИП.от 30 до 50',
  'ИП.от 50 и больше',
  'Специальный налоговый режим розничного налога',
  'Специальный налоговый режим для крестьянских или фермерских хозяйств',
  'Специальный налоговый режим с использованием специального мобильного приложения',
  'Специальный налоговый режим для производителей сельскохозяйственной продукции и сельскохозяйственных кооперативов',
  'Крестьянский.Да',
  'Крестьянский.Нет',
  'ИП.Да',
  'ИП.Нет',
  'ИП.До 600000 МРП в год',
  'ТОО.До 600000 МРП в год',
  'ТОО.Да',
  'ТОО.Нет',
  'ИП.Специальный налоговый режим розничного налога',
];
const results = {
  Да: tax_info.Общеустановленный,
  'ИП.До 3528 МРП в год': tax_info.Патент,
  'ИП.До 24038 МРП за полугодие': tax_info.Упрощенный,
  'ИП.От 144184 МРП в год': tax_info.Общеустановленный,
  'Общеустановленный режим налогообложения': tax_info.Общеустановленный,
  'Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.':
    tax_info.Вычет,
  'ИП.Общеустановленный режим налогообложения': tax_info.Общеустановленный,
  'ИП.Специальный режим с использованием фиксированного вычета</br>Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.':
    tax_info.Вычет,
  'ТОО.До 24038 МРП за полугодие': tax_info.Упрощенный,
  'ТОО.До 144184 МРП в год': tax_info.Вычет,
  'ТОО.От 144184 МРП в год': tax_info.Общеустановленный,
  'Специальный режим с использованием фиксированного вычета': tax_info.Вычет,
  'Упрощенный режим налогообложения': tax_info.Упрощенный,
  'Специальный налоговый режим на основе патента': tax_info.Патент,
  'ИП.от 30 до 50': tax_info.Вычет,
  'ИП.от 50 и больше': tax_info.Общеустановленный,
  'Специальный налоговый режим розничного налога': tax_info.Розничный,
  'Специальный налоговый режим для крестьянских или фермерских хозяйств':
    tax_info.Крестьянский,
  'Специальный налоговый режим с использованием специального мобильного приложения':
    tax_info.Мобильный,
  'Специальный налоговый режим для производителей сельскохозяйственной продукции и сельскохозяйственных кооперативов':
    tax_info.Селхоз,
  'Крестьянский.Да': tax_info.Крестьянский,
  'Крестьянский.Нет': tax_info.Селхоз,
  'ИП.Да': tax_info.Мобильный,
  'ИП.Нет': tax_info.Патент,
  'ИП.До 600000 МРП в год': tax_info.Розничный,
  'ТОО.До 600000 МРП в год': tax_info.Розничный,
  'ТОО.Да': tax_info.Общеустановленный,
  'ТОО.Нет': tax_info.Розничный,
  'ИП.Специальный налоговый режим розничного налога': tax_info.Розничный,
};

$messages.reset(resetMessagesEv);

$currentOptions.reset(resetOptionsEv);

$resultData.reset(resetResultDataEv);

sample({
  source: $messages,
  clock: sendMessageEv,
  fn: (source, clock) => {
    if (clock === 'Назад') {
      return source.slice(0, source.length - 2);
    }
    let company_format = '';
    let prefix = '';
    if (source.find((item) => item.text === 'ИП')) {
      company_format = 'ИП.';
    }
    if (source.find((item) => item.text === 'ТОО')) {
      company_format = 'ТОО.';
    }
    if (
      source[source.length - 1].text ===
      'Относитесь ли Вы к производителям сельскохозяйственной продукции? '
    ) {
      prefix = 'Селхоз.';
    }
    if (
      source[source.length - 1].text ===
      'Относитесь ли Вы к крестьянским или фермерским хозяйствам? '
    ) {
      prefix = 'Крестьянский.';
    }
    return [
      ...source,
      {
        text: clock,
        fromUser: true,
      },
      {
        text: messages_sequence[`${prefix}${company_format}${clock}`],
        fromUser: false,
      },
    ];
  },
  target: $messages,
});

sample({
  source: $messages,
  clock: sendMessageEv,
  fn: (source, clock) => {
    let company_format = '';
    let prefix = '';
    if (source.find((item) => item.text === 'ИП')) {
      company_format = 'ИП.';
    }
    if (source.find((item) => item.text === 'ТОО')) {
      company_format = 'ТОО.';
    }
    if (
      source[source.length - 3].text ===
      'Относитесь ли Вы к производителям сельскохозяйственной продукции? '
    ) {
      prefix = 'Селхоз.';
    }
    if (
      source[source.length - 3].text ===
      'Относитесь ли Вы к крестьянским или фермерским хозяйствам? '
    ) {
      prefix = 'Крестьянский.';
    }
    if (clock === 'Назад') {
      return options_sequence[
        `${prefix}${company_format}${source[source.length - 2].text}`
      ];
    }
    return options_sequence[`${prefix}${company_format}${clock}`] || [];
  },
  target: $currentOptions,
});

sample({
  clock: resetAllEv,
  target: [resetMessagesEv, resetOptionsEv, resetResultDataEv],
});

sample({
  source: $messages,
  clock: sendMessageEv,
  fn: (source, clock) => {
    let company_format = '';
    let prefix = '';
    if (source.find((item) => item.text === 'ИП')) {
      company_format = 'ИП.';
    }
    if (source.find((item) => item.text === 'ТОО')) {
      company_format = 'ТОО.';
    }
    if (
      source[source.length - 3].text ===
      'Относитесь ли Вы к крестьянским или фермерским хозяйствам? '
    ) {
      prefix = 'Крестьянский.';
    }
    if (result_options.includes(`${prefix}${company_format}${clock}`)) {
      return results[`${prefix}${company_format}${clock}`];
    }
    return null;
  },
  target: $resultData,
});

sample({
  source: $messages,
  clock: addInfoEv,
  fn: (source, clock) => {
    return [
      ...source,
      {
        text: clock,
        fromUser: false,
      },
    ];
  },
  target: $messages,
});

sample({
  source: $messages,
  clock: goBackByResultEv,
  fn: (source) => {
    let lastFromUser;
    for (let i = source.length - 1; i >= 0; i--) {
      if (source[i].fromUser) {
        lastFromUser = i;
        break;
      }
    }
    let company_format = '';
    let prefix = '';
    if (source.find((item) => item.text === 'ИП')) {
      company_format = 'ИП.';
    }
    if (source.find((item) => item.text === 'ТОО')) {
      company_format = 'ТОО.';
    }
    if (
      source[source.length - 3].text ===
      'Относитесь ли Вы к производителям сельскохозяйственной продукции? '
    ) {
      prefix = 'Селхоз.';
    }
    if (
      source[source.length - 3].text ===
      'Относитесь ли Вы к крестьянским или фермерским хозяйствам? '
    ) {
      prefix = 'Селхоз.';
    }
    return {
      messages: source.slice(0, lastFromUser),
      currentOptions:
        options_sequence[
          `${prefix}${company_format}${source[lastFromUser - 2].text}`
        ],
    };
  },
  target: spread({
    targets: {
      messages: $messages,
      currentOptions: $currentOptions,
    },
  }),
});
