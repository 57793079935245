import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import {
  $chosenLiteHexagon,
  $hexLabel,
  $isPreset,
  $liteHexagons,
  $showRoads,
  changeChosenLiteHexEv,
} from '../../models/rbpLiteModel/index.js';
import { $mapLoaded } from '../../models/mapModel/index.js';
import { $gradientBuckets } from '../../models/gradientModel/index.js';
import { formDynamicBuckets } from '../../utils/hexagon-utils.js';
import {
  changeLayerVisibility,
  changePointerOnLayer,
} from '../../utils/mapbox-utils.js';

function handleClick(e) {
  const feature = e.features[0];
  changeChosenLiteHexEv(feature);
}

function LiteHexagons() {
  const liteHexagons = useUnit($liteHexagons);
  const mapLoaded = useUnit($mapLoaded);
  const { buckets, colors } = useUnit($gradientBuckets);
  const chosenLiteHexagon = useUnit($chosenLiteHexagon);
  const isPreset = useUnit($isPreset);
  const showRoads = useUnit($showRoads);
  const hexLabel = useUnit($hexLabel);

  const createLayer = (update) => {
    const liteHexagonData = {
      type: 'FeatureCollection',
      features: liteHexagons,
    };
    const gradientProp = formDynamicBuckets(buckets, colors);

    if (update) {
      window.map.getSource('lite_hexagons_source').setData(liteHexagonData);
      window.map.setPaintProperty(
        'lite_hexagons_layer',
        'fill-extrusion-color',
        gradientProp
      );
      window.map.setPaintProperty('lite_hexagons_outline', 'line-color', [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        '#2D9CDB',
        gradientProp,
      ]);
    } else {
      if (!window.map.getSource('lite_hexagons_source')) {
        window.map.addSource('lite_hexagons_source', {
          type: 'geojson',
          data: liteHexagonData,
        });
      }

      if (!window.map.getLayer('lite_hexagons_layer')) {
        window.map.addLayer({
          id: 'lite_hexagons_layer',
          type: 'fill-extrusion',
          source: 'lite_hexagons_source',
          paint: {
            'fill-extrusion-color': gradientProp,
            'fill-extrusion-opacity': 0.5,
            'fill-extrusion-height': 0,
          },
        });

        window.map.addLayer({
          id: 'lite_hexagons_label_layer',
          type: 'symbol',
          source: 'lite_hexagons_source',
          layout: {
            'text-field': ['get', 'label'],
          },
          paint: {
            'text-color': '#fff',
          },
        });
      }

      if (!window.map.getLayer('lite_hexagons_outline')) {
        window.map.addLayer({
          id: 'lite_hexagons_outline',
          type: 'line',
          source: 'lite_hexagons_source',
          paint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'active'], false],
              '#2D9CDB',
              gradientProp,
            ],
            'line-width': [
              'case',
              ['boolean', ['feature-state', 'active'], false],
              5,
              1,
            ],
            'line-opacity': 0.8,
          },
        });
      }

      window.map.on('click', 'lite_hexagons_layer', handleClick);
      changePointerOnLayer('lite_hexagons_layer', true);
    }
  };

  useEffect(() => {
    if (mapLoaded && liteHexagons.length > 0 && buckets.length > 0) {
      if (window.map.getLayer('lite_hexagons_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [liteHexagons, mapLoaded, buckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('lite_hexagons_source')) {
      liteHexagons.map((item) => {
        window.map.setFeatureState(
          {
            source: 'lite_hexagons_source',
            id: item.id,
          },
          {
            active: false,
          }
        );
      });
      if (chosenLiteHexagon) {
        window.map.setFeatureState(
          {
            source: 'lite_hexagons_source',
            id: chosenLiteHexagon.id,
          },
          {
            active: true,
          }
        );
      }
    }
  }, [mapLoaded, chosenLiteHexagon]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('lite_hexagons_layer')) {
      if (isPreset) {
        changeLayerVisibility('lite_hexagons_layer', true);
        changeLayerVisibility('lite_hexagons_label_layer', true);
        changeLayerVisibility('lite_hexagons_outline', true);
      } else {
        changeLayerVisibility('lite_hexagons_layer', false);
        changeLayerVisibility('lite_hexagons_label_layer', false);
        changeLayerVisibility('lite_hexagons_outline', false);
      }
    }
  }, [isPreset, mapLoaded]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('lite_hexagons_layer')) {
      if (showRoads) {
        changeLayerVisibility('lite_hexagons_layer', false);
        changeLayerVisibility('lite_hexagons_label_layer', false);
        changeLayerVisibility('lite_hexagons_outline', false);
      } else {
        changeLayerVisibility('lite_hexagons_layer', true);
        changeLayerVisibility('lite_hexagons_label_layer', true);
        changeLayerVisibility('lite_hexagons_outline', true);
      }
    }
  }, [mapLoaded, showRoads]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('lite_hexagons_label_layer')) {
      if (hexLabel.length > 0) {
        changeLayerVisibility('lite_hexagons_label_layer', true);
      } else {
        changeLayerVisibility('lite_hexagons_label_layer', false);
      }
    }
  }, [hexLabel, mapLoaded]);

  return null;
}

export default LiteHexagons;
