import React, { useCallback, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { Button, Input, Radio, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  $requestsList,
  getRequestsListFx,
} from '../../models/requestsModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $language, $userInfo } from '../../models/authModel/index.js';
import style from './RequestsList.module.css';

function RequestsList() {
  const data = useUnit($requestsList);
  const language = useUnit($language);
  const userInfo = useUnit($userInfo);
  const navigate = useNavigate();

  const addSortAndFilter = useCallback(
    (field) => {
      return {
        filterSearch: true,
        filters: Array.from(new Set(data.map((item) => item[field]))).map(
          (item) => {
            return {
              text: item,
              value: item,
            };
          }
        ),
        onFilter: (value, record) => {
          if (typeof value === 'object') {
            switch (value.type) {
              case '>':
                return (
                  record[field] >
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
              case '<':
                return (
                  record[field] <
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
              case '=':
                return (
                  record[field] ===
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
            }
          } else {
            return record[field] === value;
          }
        },
        sorter: (a, b) => {
          if (typeof a[field] === 'string') {
            return a[field].localeCompare(b[field]);
          }
          return a[field] - b[field];
        },
        filterDropdown:
          field === 'pop_18_60' ||
          field === 'pop_total' ||
          field === 'pvz_area' ||
          field === 'GRAV_pop_18_60' ||
          field === 'GRAV_pop_total'
            ? ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                close,
              }) => (
                <div
                  style={{
                    padding: 5,
                  }}
                >
                  <Input
                    value={[selectedKeys[0]?.value]}
                    onChange={(e) =>
                      setSelectedKeys(
                        e.target.value
                          ? [
                              {
                                value: e.target.value,
                                type: selectedKeys[0]?.type,
                              },
                            ]
                          : []
                      )
                    }
                    style={{
                      marginBottom: 5,
                    }}
                  />
                  <Radio.Group
                    value={selectedKeys[0]?.type || null}
                    onChange={(e) => {
                      setSelectedKeys(
                        e.target.value
                          ? [
                              {
                                value: selectedKeys[0]?.value,
                                type: e.target.value,
                              },
                            ]
                          : []
                      );
                    }}
                  >
                    <Radio value=">">{'>'}</Radio>
                    <Radio value="<">{'<'}</Radio>
                    <Radio value="=">=</Radio>
                  </Radio.Group>
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (selectedKeys[0].value && selectedKeys[0].type) {
                          confirm();
                        }
                        console.log('selectedKeys', selectedKeys);
                      }}
                      type="primary"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm();
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              )
            : null,
      };
    },
    [data]
  );

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'request_id',
      ...addSortAndFilter('id'),
    },
    {
      title: ru_en_page_dictionary.request_name[language],
      dataIndex: 'name',
      key: 'request_name',
      ...addSortAndFilter('name'),
    },
    {
      title: ru_en_page_dictionary.request_company[language],
      dataIndex: 'company',
      key: 'request_company',
      ...addSortAndFilter('company'),
    },
    {
      title: ru_en_page_dictionary.request_phone[language],
      dataIndex: 'phone',
      key: 'request_phone',
      ...addSortAndFilter('phone'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'request_email',
      ...addSortAndFilter('email'),
    },
    {
      title: ru_en_page_dictionary.request_message[language],
      dataIndex: 'message',
      key: 'request_message',
      ...addSortAndFilter('message'),
    },
    {
      title: ru_en_page_dictionary.request_source_url[language],
      dataIndex: 'source_url',
      key: 'request_source_url',
      ...addSortAndFilter('source_url'),
    },
    {
      title: ru_en_page_dictionary.request_request_type[language],
      dataIndex: 'request_type',
      key: 'request_request_type',
      ...addSortAndFilter('request_type'),
    },
    {
      title: ru_en_page_dictionary.request_created_at[language],
      dataIndex: 'created_at',
      key: 'request_created_at',
      ...addSortAndFilter('created_at'),
    },
  ];

  useEffect(() => {
    getRequestsListFx();
  }, []);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      if (!userInfo.perms.includes('admin')) {
        navigate('/lite');
      }
    }
  }, [userInfo]);

  return (
    <div>
      <div className={style.requests_list_title}>
        {ru_en_page_dictionary.profile_request_list[language]}
      </div>
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
}

export default RequestsList;
