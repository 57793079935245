import { createStore } from 'effector';

export const $messages = createStore([
  {
    text: 'Сертификация — это процедура, в ходе которой уполномоченная организация подтверждает происхождение товара, продукции, работ, услуг и процессов, а также их соответствие установленным нормам и требованиям. Выберите цель прохождения сертификации:',
    fromUser: false,
  },
]);

export const $chosenOptions = createStore([]);

export const $currentOptions = createStore([
  'Для экспорта в третьи страны',
  'Для внутреннего обращения',
  'Покажи все программы',
]);
