import { sample } from 'effector';
import booleanIntersects from '@turf/boolean-intersects';
import {
  clearIsochroneEv,
  putIsochroneDataEv,
  getIsochroneByPointEv,
  changeIsochroneTimeEv,
  getIsochroneByTimeEv,
  changeTrafficEv,
  changeIsochroneTypeEv,
  changeIsochroneCustomTimeEv,
  changeShowModalEv,
  getIsochroneForPaymentsEv,
  clearIsochroneFeaturesEv,
} from './events.js';
import {
  $isochroneCustomTime,
  $isochroneStore,
  $isochroneTime,
  $isochroneType,
  $isTraffic,
  $showIsochroneModal,
} from './stores.js';
import { getIsochroneFx, getPublicIsochroneFx } from './effects.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import { timeValues } from '../../dictionaries/isochrone_dict.js';
import { changeDrawModeEv } from '../mapModel/index.js';
import { showLoaderEv } from '../webSocketModel/index.js';
import { updateFiltersByPresetEv } from '../activeFiltersModel/index.js';
import { $dataPresets, $presetAreaToEdit } from '../dataPresetsModel/index.js';
import { $zoom9Hexagons } from '../zoom9Model/index.js';

$isochroneStore
  .on(clearIsochroneEv, (state, payload) => {
    window.draw.changeMode('simple_select');
    window.draw.set({
      type: 'FeatureCollection',
      features: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type !== 'Point'),
    });

    // changeLayerVisibility('zoom8_hexagon_layer', 'visible');
    // changeLayerVisibility('zoom9_hexagon_layer', 'visible');
    // changeLayerVisibility('zoom10_hexagon_layer', 'visible');

    return [];
  })
  .reset(clearIsochroneFeaturesEv);

$isochroneTime.reset(clearIsochroneEv);
$isochroneCustomTime.reset(clearIsochroneEv);

sample({
  source: $isochroneStore,
  clock: putIsochroneDataEv,
  fn: (source, clock) => {
    // changeLayerVisibility('zoom8_hexagon_layer', 'none');
    // changeLayerVisibility('zoom9_hexagon_layer', 'none');
    // changeLayerVisibility('zoom10_hexagon_layer', 'none');

    return [
      ...source.filter((item) => item.properties.name !== clock.id),
      ...clock.response.feature.map((item) => {
        return {
          ...item,
          properties: {
            name: clock.id,
          },
          geometry: {
            ...item.geometry,
            type: 'Polygon',
            coordinates: [item.geometry.coordinates],
          },
        };
      }),
    ];
  },
  target: $isochroneStore,
});

sample({
  clock: changeIsochroneTimeEv,
  fn: (clock) => [clock],
  target: $isochroneTime,
});

sample({
  clock: changeIsochroneCustomTimeEv,
  fn: (clock) => clock,
  target: $isochroneCustomTime,
});

sample({
  source: [$isochroneTime, $isTraffic, $isochroneType, $zoom9Hexagons],
  clock: getIsochroneByPointEv,
  filter: ([time, isTraffic, type, hexagons], clock) => {
    return hexagons.some((hexagon) => booleanIntersects(hexagon, clock));
  },
  fn: ([time, isTraffic, type], clock) => {
    return {
      coordinates: [clock],
      time: timeValues[time[0]],
      traffic: isTraffic,
      type,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  source: [$isTraffic, $isochroneType],
  clock: getIsochroneByTimeEv,
  filter: () => {
    const point = window.draw
      .getAll()
      .features.filter((item) => item.geometry.type === 'Point');

    return point.length > 0 && point[0].geometry.coordinates.length > 0;
  },
  fn: ([isTraffic, type], clock) => {
    return {
      coordinates: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type === 'Point'),
      time: timeValues[clock],
      traffic: isTraffic,
      type,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  clock: clearIsochroneEv,
  fn: () => 'simple_select',
  target: changeDrawModeEv,
});

sample({
  clock: changeTrafficEv,
  fn: (clock) => clock,
  target: $isTraffic,
});

sample({
  source: [$isochroneTime, $isochroneType],
  clock: $isTraffic.updates,
  filter: () => {
    const point = window.draw
      .getAll()
      .features.filter((item) => item.geometry.type === 'Point');

    return point.length > 0 && point[0].geometry.coordinates.length > 0;
  },
  fn: ([time, type], clock) => {
    return {
      coordinates: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type === 'Point'),
      time: timeValues[time[0]],
      traffic: clock,
      type,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  clock: changeIsochroneTypeEv,
  fn: (clock) => clock,
  target: $isochroneType,
});

sample({
  source: [$isochroneTime, $isTraffic],
  clock: $isochroneType.updates,
  filter: () => {
    const point = window.draw
      .getAll()
      .features.filter((item) => item.geometry.type === 'Point');

    return point.length > 0 && point[0].geometry.coordinates.length > 0;
  },
  fn: ([time, isTraffic], clock) => {
    return {
      coordinates: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type === 'Point'),
      time: timeValues[time[0]],
      traffic: isTraffic,
      type: clock,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  source: [$dataPresets, $presetAreaToEdit, $isochroneStore],
  clock: updateFiltersByPresetEv,
  filter: ([dataPresets, presetAreaToEdit], clock) =>
    presetAreaToEdit.preset ||
    (presetAreaToEdit.preset && presetAreaToEdit.area),
  fn: ([dataPresets, presetAreaToEdit, isochroneStore], clock) => {
    const chosenPreset = dataPresets.find(
      (preset) => preset.name === presetAreaToEdit.preset
    );
    if (!presetAreaToEdit.area) {
      if (chosenPreset.activeFilters.isochrone) {
        return chosenPreset.activeFilters.isochrone.map((item) => item.area);
      }
      return [];
    }
    if (presetAreaToEdit.area) {
      const chosenIsochrone = chosenPreset.activeFilters.isochrone.find(
        (item) => item.area.properties.name === presetAreaToEdit.area
      );
      return chosenIsochrone ? [chosenIsochrone.area] : [];
    }
    return [];
  },
  target: $isochroneStore,
});

sample({
  clock: $presetAreaToEdit.updates,
  filter: (clock) => clock.preset === '' && clock.area === '',
  target: clearIsochroneEv,
});

sample({
  source: $showIsochroneModal,
  clock: changeShowModalEv,
  fn: (source, clock) => {
    if (typeof clock === 'boolean') return clock;
    return !source;
  },
  target: $showIsochroneModal,
});

sample({
  clock: getIsochroneForPaymentsEv,
  fn: (clock) => {
    return {
      ...clock,
      time: 15,
    };
  },
  target: getPublicIsochroneFx,
});

sample({
  clock: getPublicIsochroneFx.doneData,
  fn: (clock) => {
    return {
      response: clock,
    };
  },
  target: putIsochroneDataEv,
});
