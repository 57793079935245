import React from 'react';
import style from '../RbpLanding.module.css';
import thank_you from '../../../assets/RbpLandingImages/thank_you.svg';
import footer_logo from '../../../assets/RbpLandingImages/Footer/footer_logo.svg';

function ThankYouPage() {
  return (
    <div className={style.thank_you_wrapper}>
      <img src={footer_logo} className={style.thank_you_logo} />
      <img src={thank_you} className={style.thank_you_icon} />
      <div className={style.thank_you_title}>Благодарим за покупку!</div>
      <div className={style.thank_you_subtitle}>
        Скоро вам на указанную почту придет письмо с доступом к платформе.
      </div>
    </div>
  );
}

export default ThankYouPage;
