export const almaty_business_chats_info = [
  {
    route: 'chat',
    apiUrl: 'https://atamekenvc.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я здесь, чтобы помочь вам с информацией по созданию и развитию стартапа, а также по вопросам привлечения инвестиций. 
Задавайте любые вопросы — от первых шагов в запуске до рекомендаций по привлечению инвестиций и существующих программ поддержки.`,
    preparedMessages: [
      'Какие существуют ключевые бизнес модели?',
      'Какие критерии успешного продукта?',
      'Какие существуют каналы сбыта и какова их специфика?',
      'Какие существуют системы продаж?',
      'Как выглядит структура издержек?',
      'Как выстраивать взаимоотношения с клиентами?',
      'Из чего состоит дорожная карта по запуску своего стартапа?',
      'Что такое метод Lean Customer Development?',
    ],
  },
  {
    route: 'chat-fundraising',
    apiUrl: 'https://atamekenvc.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я здесь, чтобы помочь вам с информацией по созданию и развитию стартапа, а также по вопросам привлечения инвестиций. 
Задавайте любые вопросы — от первых шагов в запуске до рекомендаций по привлечению инвестиций и существующих программ поддержки.`,
    preparedMessages: [
      'Какие существуют источники венчурного финансирования в Казахстане?',
      'Какие существуют инновационные лаборатории?',
      'Какие ключевые акселераторы/ инкубаторы/ технопарки?',
      'С какими корпорациями могут запустить пилотный проект стартапы?',
      'Какие есть успешные примеры сотрудничества корпораций и стартапов?',
      'Что такое Astana Hub и как он работает?',
      'Виды и концепции инвестиционных договоров?',
      'Что такое питч-дек и как его правильносоставить?',
      'Как привлечь финансирование на фондовом рынке?',
      'Какой налоговый режим в специальных экономических зонах?',
      'Как работает МФЦА как площадка для совместного инвестирования?',
      'Дай контакты VS в США на ранней стадии развития стартапа?',
    ],
  },
  {
    route: 'chat-v2',
    apiUrl: 'https://atameken-2.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я - цифровой помощник для предпринимателей, упрощающий доступ к информации о государственных мерах поддержки и финансовым инструментам. Помогаю быстрее ориентироваться в доступных возможностях для роста бизнеса.`,
    preparedMessages: [
      'Какие есть меры государственной поддержки?',
      'Какие есть финансовые инструменты поддержки?',
      'Какие есть государственные программы поддержки?',
      'Какие есть меры поддержки для молодых предпринимателей?',
      'Какие есть бизнес-инкубаторы?',
      'Как получить государственную поддержку?',
      'Какие организации участвуют в программах государственной поддержки?',
    ],
  },
  {
    route: 'banking',
    apiUrl: 'https://banking.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник по финансовым продуктам банков второго уровня. Помогу выбрать кредит, разобраться с факторингом, лизингом или другими инструментами для вашего бизнеса.`,
    preparedMessages: [
      'Какие существуют финансовые государственные меры поддержки?',
      'Какие банки участвуют в программах субсидирования бизнеса?',
      'Какие требования к ИП для получения кредита?',
      'Какие требования к малому и среднему бизнесу для получения кредита?',
      'Какие дополнительные возможности у женщин-предпринимателей?',
      'Кто относится к категории молодых предпринимателей?',
      'Где самые выгодные условия кредитования крупного бизнеса?',
      'Какие типы аккредитивов существуют?',
      'Какие существуют банки по исламским финансам?',
      'Какие банки работают с корпоративными клиентами?',
      'Какие условия для получения тендерных гарантий?',
    ],
  },
  {
    route: 'crowdfunding',
    apiUrl: 'https://crowdfunding.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник, который поможет вам разобраться в мире краудфандинга. Подскажу, как выбрать платформу, создать эффективную кампанию и привлечь финансирование для вашего проекта. Вместе мы сделаем ваш проект успешным.`,
    preparedMessages: [
      'Как работает краудфандинг?',
      'Какие краудфандинговых платформы лицензированы в Казахстане?',
      'Какой процесс подачи заявки?',
      'Какие документы необходимы для подачи заявки?',
      'Какие условия предлагают краудфандинговые платформы?',
      'Как компания управляет рисками?',
      'Как происходит уплата налогов?',
    ],
  },
  {
    route: 'realestate',
    apiUrl: 'https://realestate.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах регистрации прав на недвижимость. Помогу разобраться в процедурах, документах и этапах регистрации, чтобы процесс стал для вас понятным и удобным.`,
    preparedMessages: [
      'Что такое право собственности?',
      'На основании каких документов может возникнуть право собственности на недвижимое имущество?',
      'Какой размер государственной пошлины за регистрацию?',
      'Как получить услугу онлайн?',
      'Какие документы предоставляют юридические лица?',
      'Что такое юридические притязания?',
      'Какие услуги доступны в сфере регистрации недвижимого имущества?',
    ],
  },
  {
    route: 'elnet',
    apiUrl: 'https://elnet.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник, который поможет вам получить технические условия для подключения к электроснабжению. Подскажу, как собрать документы, подать заявку и пройти все этапы подключения.`,
    preparedMessages: [
      'Какие документы необходимы?',
      'Какие сроки подключения к сетям электроснабжения?',
      'Как регулируется процесс подключения к сетям электроснабжения?',
      'Где можно получить услугу в Алматы?',
      'Какая процедура получения услуги в Алматы?',
    ],
  },
  {
    route: 'heatsys',
    apiUrl: 'https://heatsys.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – помощник в вопросах подключения к системам теплоснабжения. Подскажу, как получить технические условия, оформить документы и пройти все этапы подключения.`,
    preparedMessages: [
      'Какие документы необходимы?',
      'Какие сроки подключения к сетям теплоснабжения?',
      'Как регулируется процесс подключения к сетям теплоснабжения?',
      'Где можно получить услугу в Алматы?',
      'Какая процедура получения услуги в Алматы?',
    ],
  },
  {
    route: 'gassup',
    apiUrl: 'https://gassup.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш проводник в процессе подключения к газоснабжению. Помогу понять этапы процесса, собрать необходимые документы и подать заявку.`,
    preparedMessages: [
      'Какие документы необходимы?',
      'Как подать заявку на технические условия?',
      'Какие сроки подключения к сетям газоснабжения?',
      'Как регулируется процесс подключения к сетям газоснабжения?',
      'Где можно получить услугу?',
      'Какая процедура получения услуги?',
    ],
  },
  {
    route: 'watersup',
    apiUrl: 'https://watersup.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник для подключения к системам водоснабжения и водоотведения. Объясню, как получить технические условия и оформить необходимые документы.`,
    preparedMessages: [
      'Какие документы необходимы?',
      'Как подать заявку на технические условия?',
      'Какие сроки подключения к сетям водоснабжения и водоотведения?',
      'Как регулируется процесс подключения к сетям водоснабжения и водоотведения?',
      'Где можно получить услугу в Алматы?',
      'Какая процедура получения услуги в Алматы?',
    ],
  },
  {
    route: 'landrules',
    apiUrl: 'https://landrules.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах изменения целевого назначения земельного участка. Подскажу, как подготовить документы и пройти процедуру в соответствии с законодательством.`,
    preparedMessages: [
      'Какие есть типы целевого использования участка?',
      'Как получить услугу онлайн?',
      'Как получить услугу в НАО "Государственная корпорация "Правительство для граждан"?',
      'Какие документы необходимы?',
      'Какие есть государственные услуги в сфере земельных отношений?',
    ],
  },
  {
    route: 'business-protection',
    apiUrl: 'https://business-prot.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах защиты бизнеса. Подскажу, как защитить бизнес от неправомерных действий в соответствии с законодательством.`,
    preparedMessages: [
      'Права предпринимателя при проверке?',
      'Как защитить свои права?',
      'Какие есть онлайн сервисы по защите прав?',
      'Какие бывают проверки бизнеса?',
      'Как узнать о запланированной проверке?',
      'Как работает мобильное приложение «Qamqor»?',
      'Как подать жалобу?',
      'Как проверить законность проверки?',
      'Органы отвечающие за защиту прав предпринимателя?',
    ],
  },
  {
    route: 'business-plan',
    apiUrl: 'https://business-plan.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах создания бизнес-плана. Я здесь, чтобы помочь вам с информацией по созданию соответствующего документа на основе лучших практик, и готов предоставить шаблоны под ваш запрос — от открытия магазина до создания детского сада.`,
    preparedMessages: [
      'Какая структура бизнес-плана?',
      'Какие задачи у бизнес-плана?',
      'Из чего состоит анализ рынка?',
      'Какие сроки финансовых прогнозов?',
      'Как учитывать финансовые затраты и налоги?',
      'Как оценить нужды потребителей для моего типа бизнеса?',
      'Ключевые показатели и коэффициенты в бизнес-плане?',
      'Как выделить свой бизнес-план среди конкурентов?',
      'Требования инвесторов к бизнес-плану?',
      'Пример бизнес-плана для моего типа бизнеса?',
    ],
  },
  {
    route: 'trademark',
    apiUrl: 'https://trademark.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах регистрации товарного знака. Подскажу, как пройти все процедуры и подать заявку в соответствии с законодательством.`,
    preparedMessages: [
      'Как зарегистрировать товарный знак?',
      'Какие документы необходимо предоставить для регистрации товарного знака?',
      'Кто может подать заявку на регистрацию товарного знака?',
      'Какие сроки экспертизы товарного знака?',
      'В течение какого времени проводится ускоренная экспертиза товарного знака?',
      'Какие документы нужны для подачи заявки на ускоренную экспертизу?',
      'Какой срок действия свидетельства на товарный знак?',
      'Какие преимущества имеются в системе международной регистрации товарных знаков?',
      'Куда следует подавать международную заявку?',
    ],
  },
  {
    route: 'licensing',
    apiUrl: 'https://licensing.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
        Я – ваш помощник в вопросах лицензирования и аккредитации деятельности. Подскажу, как пройти все процедуры в соответствии с законодательством и подать заявку на получение услуги онлайн.`,
    preparedMessages: [
      'Где получить лицензию?',
      'Какие документы потребуются?',
      'Какие виды деятельности подлежат лицензированию?',
      'Какие сроки получения лицензии?',
      'Размер пошлины при лицензировании?',
      'Как получить аккредитацию?',
      'Условия для получения аккредитации?',
      'Какие виды деятельности подлежат аккредитации?',
      'Как получить услугу онлайн?',
    ],
  },
  {
    route: 'ip-rights',
    apiUrl: 'https://ip-rights.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
        Я – ваш помощник в вопросах интеллектуальной собственности. Подскажу, как собрать документы, подать заявку и пройти все этапы регистрации прав на объекты интеллектуальной собственности в соответствии с законодательством.`,
    preparedMessages: [
      'Кто может подать заявку на регистрацию товарного знака?',
      'Что относится к авторскому праву?',
      'Какой размер пошлины?',
      'Как получить услугу онлайн?',
      'Куда следует подавать международную заявку?',
      'Какие документы необходимо представить для получения патента на изобретение?',
      'В течении какого времени проводится экспертиза заявки на изобретение?',
      'Какой срок действия исключительного права на полезную модель?',
      'С какого периода патентообладатель должен оплачивать поддержание охранного документа?',
      'Как можно получить правовую охрану или регистрацию на оригинальный дизайн?',
    ],
  },
  {
    route: 'master',
    apiUrl: 'https://atameken-general.metatable.ai/create_message',
    promptMessage: `I am initial message`,
    preparedMessages: [],
  },
];
