import { Button, Card, Form, Input } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  $authStatus,
  $forgotSuccess,
  forgotPasswordFx,
  loginEv,
  loginFx,
  sendForgotPasswordEv,
  sendRestorePasswordEv,
} from '../../models/authModel/index.js';
import { cookies } from '../../api/axiosinstance.js';
import style from './ForgotPassword.module.css';
import login_image from '../../assets/login_image.png';
import logo from '../../assets/logo.svg';
import useWindowSize from '../../hooks/useWindowSize.js';
import thank_you from '../../assets/RbpLandingImages/thank_you.svg';

export default function ForgotPassword() {
  const authStatus = useUnit($authStatus);
  const navigate = useNavigate();
  const isLoading = useUnit(forgotPasswordFx.pending);
  const sendForgotPassword = useUnit(sendForgotPasswordEv);
  const sendRestorePassword = useUnit(sendRestorePasswordEv);
  const forgotSuccess = useUnit($forgotSuccess);
  const [width, height] = useWindowSize();

  useEffect(() => {
    if (authStatus) {
      const token = cookies.get('access_token');
      if (token) {
        navigate('/');
      }
    }
  }, [authStatus]);

  return (
    <div className={style.login_wrapper}>
      <div className={style.login_form_wrapper}>
        <div className={style.login_form} id="login-form">
          <img src={logo} className={style.login_logo} />
          {forgotSuccess ? (
            <div className={style.forgot_success}>
              <img src={thank_you} className={style.forgot_img} />
              <div className={style.forgot_subtitle}>
                Ссылка для сброса пароля отправлена на указанную почту
              </div>
            </div>
          ) : (
            <>
              <Form
                name="loginForm"
                id="loginForm"
                onFinish={sendForgotPassword}
                layout="vertical"
                style={{ marginTop: 30 }}
              >
                <Form.Item name="email" label="Ваша почта">
                  <Input
                    placeholder="email@email.com"
                    className={style.login_input}
                  />
                </Form.Item>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  form="loginForm"
                  type="primary"
                  className={style.login_button}
                >
                  Сбросить
                </Button>
                {/* <Link to="/register">Зарегистрироваться</Link> */}
                {/* </div> */}
              </Form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 30,
                }}
              >
                <Link
                  to="/login"
                  className={[style.login_link, style.back_button].join(' ')}
                >
                  Назад
                </Link>
              </div>
            </>
          )}
          {/* <Button onClick={sendForgotPassword}>Forgot</Button> */}
          {/* <Button onClick={sendRestorePassword}>Restore</Button> */}
        </div>
      </div>
      {width > 1200 ? (
        <img src={login_image} className={style.login_image} />
      ) : (
        ''
      )}
    </div>
  );
}
