import { createEvent } from 'effector';

export const toggleTourEv = createEvent();

// FIXME Events to set ref
export const setIspResetRefEv = createEvent();
export const setPublicBusinessTypeRefEv = createEvent();
export const setPublicDetailsRefEv = createEvent();
export const setPublicCreateRefEv = createEvent();
export const setLiteCategoryRefEv = createEvent();
export const setLiteChartRefEv = createEvent();
export const setProfileRefEv = createEvent();
export const setCategoriesRefEv = createEvent();
export const setGradientRefEv = createEvent();
export const setMapRefEv = createEvent();
export const setProfileModalRefEv = createEvent();

export const incStepEv = createEvent();
export const decStepEv = createEvent();
export const resetStepEv = createEvent();

export const incStepByHexEv = createEvent();
