import React from 'react';
import Icon from '@ant-design/icons';
import { Button, Tooltip, Tour } from 'antd';
import { useUnit } from 'effector-react';
import {
  $categoriesRef,
  $currentStep,
  $gradientRef,
  $mapRef,
  $tourOpen,
  $tourSteps,
  decStepEv,
  incStepEv,
  resetStepEv,
  toggleTourEv,
} from '../../models/educationModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $language } from '../../models/authModel/index.js';

function EducationSvg() {
  return (
    <svg
      height="20px"
      width="20px"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <style type="text/css">{' .st0{fill:currentColor;} '}</style>
        <g>
          <path
            className="st0"
            d="M473.61,63.16L276.16,2.927C269.788,0.986,263.004,0,256.001,0c-7.005,0-13.789,0.986-20.161,2.927 L38.386,63.16c-3.457,1.064-5.689,3.509-5.689,6.25c0,2.74,2.232,5.186,5.691,6.25l91.401,27.88v77.228 c0.023,39.93,13.598,78.284,38.224,107.981c11.834,14.254,25.454,25.574,40.483,33.633c15.941,8.564,32.469,12.904,49.124,12.904 c16.646,0,33.176-4.34,49.126-12.904c22.597-12.143,42.04-31.646,56.226-56.39c14.699-25.683,22.471-55.155,22.478-85.224v-78.214 l45.244-13.804v64.192c-6.2,0.784-11.007,6.095-11.007,12.5c0,5.574,3.649,10.404,8.872,12.011l-9.596,63.315 c-0.235,1.576,0.223,3.168,1.262,4.386c1.042,1.204,2.554,1.902,4.148,1.902h36.273c1.592,0,3.104-0.699,4.148-1.91 c1.036-1.203,1.496-2.803,1.262-4.386l-9.596-63.307c5.223-1.607,8.872-6.436,8.872-12.011c0-6.405-4.81-11.716-11.011-12.5V81.544 l19.292-5.885c3.457-1.064,5.691-3.517,5.691-6.25C479.303,66.677,477.069,64.223,473.61,63.16z M257.62,297.871 c-10.413,0-20.994-2.842-31.448-8.455c-16.194-8.649-30.908-23.564-41.438-42.011c-4.854-8.478-8.796-17.702-11.729-27.445 c60.877-10.776,98.51-49.379,119.739-80.97c10.242,20.776,27.661,46.754,54.227,58.648c-3.121,24.984-13.228,48.812-28.532,67.212 c-8.616,10.404-18.773,18.898-29.375,24.573C278.606,295.029,268.025,297.871,257.62,297.871z"
          />
          <path
            className="st0"
            d="M373.786,314.23l-1.004-0.629l-110.533,97.274L151.714,313.6l-1.004,0.629 c-36.853,23.036-76.02,85.652-76.02,156.326v0.955l0.846,0.45C76.291,472.365,152.428,512,262.249,512 c109.819,0,185.958-39.635,186.712-40.038l0.846-0.45v-0.955C449.808,399.881,410.639,337.265,373.786,314.23z"
          />
        </g>
      </g>
    </svg>
  );
}

function EducationIcon(props) {
  return <Icon component={EducationSvg} {...props} />;
}

// const actionSteos = [
//   'isPreset',
//   'publicBusiness',
//   'publicCreate',
//   'liteCategory',
//   'liteChart',
//   'gradient',
//   'profile',
// ];

const optionalSteps = ['publicDetails', 'mapDetails', 'liteChart', 'gradient'];

function Education() {
  const tourOpen = useUnit($tourOpen);
  const toggleTour = useUnit(toggleTourEv);
  const tourSteps = useUnit($tourSteps);
  const categoriesRef = useUnit($categoriesRef);
  const gradientRef = useUnit($gradientRef);
  const mapRef = useUnit($mapRef);
  const currentStep = useUnit($currentStep);
  const incStep = useUnit(incStepEv);
  const decStep = useUnit(decStepEv);
  const resetStep = useUnit(resetStepEv);
  const language = useUnit($language);

  const addCustomStepButtons = (item) => {
    return {
      nextButtonProps: {
        children: (
          <label style={{ cursor: 'pointer' }}>
            {ru_en_page_dictionary.education_next[language]}
          </label>
        ),
        onClick: () => {
          if (currentStep !== tourSteps.length - 1) incStep();
        },
        style: {
          display: optionalSteps.includes(item.element)
            ? 'inline-flex'
            : 'none',
        },
      },
      prevButtonProps: {
        children: (
          <label style={{ cursor: 'pointer' }}>
            {ru_en_page_dictionary.education_prev[language]}
          </label>
        ),
        onClick: () => {
          decStep();
          if (item.prevEvent) item.prevEvent();
        },
        // style: {
        //   display: 'none',
        // },
      },
    };
  };

  const finishTour = () => {
    toggleTour(false);
    resetStep();
  };

  return (
    <>
      <Tooltip title={ru_en_page_dictionary.education_button[language]}>
        <Button
          icon={<EducationIcon />}
          className="no_bg"
          style={{ border: 'unset' }}
          onClick={() => toggleTour(true)}
        />
      </Tooltip>
      <Tour
        open={tourOpen}
        onClose={finishTour}
        steps={tourSteps.map((item) => {
          return {
            ...item,
            ...addCustomStepButtons(item),
          };
        })}
        current={currentStep}
        // closeIcon={<Button style={{ padding: 4 }}>Skip</Button>}
        closeIcon={ru_en_page_dictionary.education_skip[language]}
        mask={{
          color: '#00000040',
        }}
        // gap={{
        //   offset: [200, 600],
        // }}
        // disabledInteraction
      />
    </>
  );
}

export default Education;
