import React, { useEffect, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Card, Collapse, Input, Slider, Tooltip } from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  InfoCircleFilled,
  RightOutlined,
} from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { median } from 'mathjs';
import style from './MapDemoHexModal.module.css';
import {
  $attractLayer,
  $chosenZoom7Hexagon,
  $publicBusinessType,
  $showStops,
  $zoom7Hexagons,
  changeAttractLayerEv,
  resetChosenZoom7HexagonEv,
  toggleStopsEv,
} from '../../models/zoom7Model/index.js';
import {
  modal_values_dictionary,
  range_values_dict,
} from '../../dictionaries/public_index_dictionary.js';
import {
  $activeFilters,
  $publicFilters,
  $publicRangeMax,
  $publicRangeMin,
  changeActiveFilterEv,
  changePublicFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import rbp_free_dict from '../../dictionaries/rbp_free_dict.json';
import { $isDarkTheme } from '../../models/authModel/index.js';
import { setPublicDetailsRefEv } from '../../models/educationModel/index.js';
import { addSeparators } from '../../utils/format-utils.js';

const colors = ['#9C27B0', '#2DDB73'];

const more_colors = [
  '#F85F5F',
  '#9C27B0',
  '#E4F039',
  '#F09439',
  '#39D4F0',
  '#2DDB73',
];

function RangeProp({ title, total, items, publicFilters }) {
  const isDark = useUnit($isDarkTheme);
  const changePublicFilters = useUnit(changePublicFiltersEv);
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const chosen_metric = useUnit($activeFilters).chosen_metrics[0];

  const onClick = (field) => {
    if (chosen_metric !== 'population_total') {
      changeActiveFilter({
        field: 'chosen_metrics',
        value: 'population_total',
      });
    }
    changePublicFilters({
      field: 'population_category',
      value: field,
    });
  };

  return (
    <div className={style.range_prop}>
      <div
        className={style.prop_title}
        style={{ color: isDark ? 'white' : '#717171' }}
      >
        {title}
      </div>
      <div className={style.line}>
        {items.map((item, index) => {
          const percent = (item.value * 100) / total;
          return (
            <Tooltip
              title={`${Math.round(percent)}%`}
              key={`${item.field}-line-${index}`}
            >
              <div
                className={[
                  style.line_item,
                  publicFilters.population_category.includes(item.field)
                    ? style.line_active
                    : '',
                ].join(' ')}
                onClick={() => onClick(item.field)}
                style={{
                  width: `${percent}%`,
                  background:
                    items.length === 2 ? colors[index] : more_colors[index],
                }}
              />
            </Tooltip>
          );
        })}
      </div>
      <div className={style.legend_wrapper}>
        {items.map((item, index) => {
          return (
            <div
              key={`${item.field}-legend-${index}`}
              className={[
                style.legend_item,
                publicFilters.population_category.includes(item.field)
                  ? style.legend_active
                  : '',
              ].join(' ')}
              onClick={() => onClick(item.field)}
              style={{
                color: isDark ? '#fff' : '#000',
              }}
            >
              <div
                className={style.legend_badge}
                style={{
                  background:
                    items.length === 2 ? colors[index] : more_colors[index],
                }}
              />
              <div>
                {item.label}
                {/* <div style={{ display: 'inline', fontWeight: 'bold' }}> */}
                {/*  {item.value} */}
                {/* </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function MapDemoHexModal() {
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const publicBusinessType = useUnit($publicBusinessType);
  const resetChosenZoom7Hexagon = useUnit(resetChosenZoom7HexagonEv);
  const [col1Key, setCol1Key] = useState([]);
  const [col2Key, setCol2Key] = useState([1]);
  const [col3Key, setCol3Key] = useState([]);
  const zoom7Hexagons = useUnit($zoom7Hexagons);
  const [total, setTotal] = useState({});
  const chosen_metric = useUnit($activeFilters).chosen_metrics[0];
  const isDark = useUnit($isDarkTheme);
  const setPublicDetailsRef = useUnit(setPublicDetailsRefEv);
  const publicDetailsRef = useRef();
  const publicFilters = useUnit($publicFilters);
  const toggleStops = useUnit(toggleStopsEv);
  const changeAttractLayer = useUnit(changeAttractLayerEv);
  const attractLayer = useUnit($attractLayer);
  const showStops = useUnit($showStops);

  useEffect(() => {
    if (
      chosen_metric === 'population_total' ||
      chosen_metric === 'income_median' ||
      chosen_metric === 'households'
    ) {
      setCol1Key([]);
      setCol2Key(['1']);
      setCol3Key([]);
    } else if (chosen_metric === 'competitors_cnt_B') {
      setCol1Key(['1']);
      setCol2Key([]);
      setCol3Key([]);
    } else if (
      chosen_metric === 'tj_lvl_avg' ||
      chosen_metric === 'parking_place_cnt'
    ) {
      setCol1Key([]);
      setCol2Key([]);
      setCol3Key(['1']);
    }
  }, [chosen_metric]);

  useEffect(() => {
    if (zoom7Hexagons.length > 0) {
      const temp = {};
      zoom7Hexagons.forEach((item) => {
        Object.keys(item.properties).forEach((key) => {
          if (temp[key]) {
            temp[key] += item.properties[key];
          } else {
            temp[key] = item.properties[key];
          }
        });
      });
      setTotal({
        ...temp,
        price_per_sq_m_Com:
          zoom7Hexagons.length === 528
            ? 4740
            : Math.round(temp.price_per_sq_m_Com / zoom7Hexagons.length),
        pt_route_cnt: zoom7Hexagons.length === 528 ? 150 : temp.pt_route_cnt,
        population_total:
          zoom7Hexagons.length === 528
            ? rbp_free_dict.population_total.default_value
            : temp.population_total,
        households:
          zoom7Hexagons.length === 528
            ? rbp_free_dict.households.default_value
            : temp.households,
        income_median: median(
          zoom7Hexagons.map((item) => item.properties.income_median || 0)
        ),
      });
    }
  }, [zoom7Hexagons]);

  const source = !chosenZoom7Hexagon ? total : chosenZoom7Hexagon.properties;
  // console.log('source', source);

  useEffect(() => {
    if (publicDetailsRef.current) {
      setPublicDetailsRefEv({
        element: 'publicDetails',
        ref: publicDetailsRef.current,
      });
    }
  }, [publicDetailsRef]);

  return (
    <div
      ref={publicDetailsRef}
      className={[
        style.hex_info_modal,
        // chosenZoom7Hexagon ? style.show : '',
        style.show,
      ].join(' ')}
      style={{
        background: isDark ? 'rgb(30, 35, 40)' : '#fff',
      }}
    >
      <Collapse
        ghost
        className={style.collapse}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: isDark ? 'white' : 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col2Key}
        onChange={(key) => setCol2Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label
                className={style.collapse_title}
                style={{
                  color: isDark ? '#fff' : '#000',
                }}
              >
                Аудитория
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Население
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source.population_total)}
                  </div>
                  <SliderProp
                    value={publicFilters.population_total}
                    field="population_total"
                  />
                  {/* <div className={style.prop_value}> */}
                  {/*  {source.population_total} */}
                  {/* </div> */}
                  <div className={style.divider} />
                  <RangeProp
                    title="Пол"
                    total={source.population_total}
                    publicFilters={publicFilters}
                    items={[
                      {
                        label: modal_values_dictionary.population_sex_m,
                        value: source.population_sex_m,
                        field: 'population_sex_m',
                      },
                      {
                        label: modal_values_dictionary.population_sex_w,
                        value: source.population_sex_w,
                        field: 'population_sex_w',
                      },
                    ]}
                  />
                  <div className={style.divider} />
                  <RangeProp
                    title="Возраст"
                    total={source.population_total}
                    publicFilters={publicFilters}
                    items={[
                      {
                        label: modal_values_dictionary.population_age_0_15,
                        value: source.population_age_0_15,
                        field: 'population_age_0_15',
                      },
                      {
                        label: modal_values_dictionary.population_age_16_25,
                        value: source.population_age_16_25,
                        field: 'population_age_16_25',
                      },
                      {
                        label: modal_values_dictionary.population_age_26_35,
                        value: source.population_age_26_35,
                        field: 'population_age_26_35',
                      },
                      {
                        label: modal_values_dictionary.population_age_36_45,
                        value: source.population_age_36_45,
                        field: 'population_age_36_45',
                      },
                      {
                        label: modal_values_dictionary.population_age_46_60,
                        value: source.population_age_46_60,
                        field: 'population_age_46_60',
                      },
                      {
                        label: modal_values_dictionary.population_age_61_100,
                        value: source.population_age_61_100,
                        field: 'population_age_61_100',
                      },
                    ]}
                  />
                  <div className={style.divider} />
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Доход
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source.income_median)}
                  </div>
                  <SliderProp
                    value={publicFilters.income_median}
                    field="income_median"
                  />
                  {/* {!chosenZoom7Hexagon ? ( */}
                  {/*  <div */}
                  {/*    className={style.no_value} */}
                  {/*    style={{ color: isDark ? 'white' : '#717171' }} */}
                  {/*  > */}
                  {/*    <InfoCircleFilled */}
                  {/*      style={{ color: 'red', fontSize: 20 }} */}
                  {/*    />{' '} */}
                  {/*    Выберите область на карте */}
                  {/*  </div> */}
                  {/* ) : ( */}
                  {/*  <div className={style.prop_value}> */}
                  {/*    {range_values_dict[source.income_avg_range]} */}
                  {/*  </div> */}
                  {/* )} */}
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Кол-во домохозяйств
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source.households)}
                  </div>
                  <SliderProp
                    value={publicFilters.households}
                    field="households"
                  />
                </div>
              </div>
            ),
          },
        ]}
      />
      <div className={style.collapse_divider} style={{}} />
      <Collapse
        ghost
        className={style.collapse}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: isDark ? 'white' : 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col1Key}
        onChange={(key) => setCol1Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label
                className={style.collapse_title}
                style={{
                  color: isDark ? '#fff' : '#000',
                }}
              >
                Бизнес
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Конкуренты и смежный бизнес
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source[publicBusinessType])}
                  </div>
                  <SliderProp
                    value={publicFilters.business_type}
                    field="business_type"
                  />
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Средняя стоимость аренды ком. недвижимости, кв. метр
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source.price_per_sq_m_Com)}
                  </div>
                  <SliderProp
                    value={publicFilters.price_per_sq_m_Com}
                    field="price_per_sq_m_Com"
                  />
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Количество БЦ (бизнес-центров)
                  </div>
                  <div className={style.prop_value_wrapper}>
                    <div className={style.prop_value}>
                      {addSeparators(source.attract_cnt_BC)}
                    </div>
                    <Button
                      onClick={() => changeAttractLayer('БЦ')}
                      type={attractLayer === 'БЦ' ? 'primary' : 'default'}
                    >
                      Показать на карте
                    </Button>
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Количество ТЦ (торговых центров)
                  </div>
                  <div className={style.prop_value_wrapper}>
                    <div className={style.prop_value}>
                      {source.attract_cnt_TC}
                    </div>
                    <Button
                      onClick={() => changeAttractLayer('ТЦ')}
                      type={attractLayer === 'ТЦ' ? 'primary' : 'default'}
                    >
                      Показать на карте
                    </Button>
                  </div>
                </div>
                {/* <div className={style.card}> */}
                {/*  <div */}
                {/*    className={style.prop_title} */}
                {/*    style={{ color: isDark ? 'white' : '#717171' }} */}
                {/*  > */}
                {/*    Количество ТРЦ (торгово-развлекательных центров) */}
                {/*  </div> */}
                {/*  <div className={style.prop_value_wrapper}> */}
                {/*    <div className={style.prop_value}> */}
                {/*      {addSeparators(source.attract_cnt_TRC)} */}
                {/*    </div> */}
                {/*    <Button */}
                {/*      onClick={() => changeAttractLayer('ТРЦ')} */}
                {/*      type={attractLayer === 'ТРЦ' ? 'primary' : 'default'} */}
                {/*    > */}
                {/*      Показать на карте */}
                {/*    </Button> */}
                {/*  </div> */}
                {/* </div> */}
                <div />
              </div>
            ),
          },
        ]}
      />
      <div className={style.collapse_divider} />
      <Collapse
        ghost
        className={style.collapse}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: isDark ? 'white' : 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col3Key}
        onChange={(key) => setCol3Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label
                className={style.collapse_title}
                style={{
                  color: isDark ? '#fff' : '#000',
                }}
              >
                Доступность территории
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Пешеходная связанность территорий
                  </div>
                  <div className={style.prop_value}>
                    <div style={{ color: 'red', display: 'inline' }}>
                      {!chosenZoom7Hexagon
                        ? Math.round(
                            source.pedestrian_connect / zoom7Hexagons.length
                          )
                        : source.pedestrian_connect}
                    </div>{' '}
                    / 10
                  </div>
                  <SliderProp
                    value={publicFilters.pedestrian_connect}
                    field="pedestrian_connect"
                  />
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Пробки
                  </div>
                  <div className={style.prop_value}>
                    <div style={{ color: 'red', display: 'inline' }}>
                      {!chosenZoom7Hexagon
                        ? Math.round(source.tj_lvl_avg / zoom7Hexagons.length)
                        : source.tj_lvl_avg}
                    </div>{' '}
                    / 10
                  </div>
                  <SliderProp
                    value={publicFilters.tj_lvl_avg}
                    field="tj_lvl_avg"
                  />
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Общественный транспорт, маршруты
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source.pt_route_cnt)}
                  </div>
                  <SliderProp
                    value={publicFilters.pt_route_cnt}
                    field="pt_route_cnt"
                  />
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Общественный транспорт, остановки
                  </div>
                  <div className={style.prop_value_wrapper}>
                    <div className={style.prop_value}>
                      {addSeparators(source.pt_stops_cnt)}
                    </div>
                    <Button
                      onClick={toggleStops}
                      type={showStops ? 'primary' : 'default'}
                    >
                      Показать на карте
                    </Button>
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Парковки, места
                  </div>
                  <div className={style.prop_value}>
                    {addSeparators(source.parking_place_cnt)}
                  </div>
                  <SliderProp
                    value={publicFilters.parking_place_cnt}
                    field="parking_place_cnt"
                  />
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

function SliderProp(props) {
  const { value, field } = props;
  const changePublicFilters = useUnit(changePublicFiltersEv);
  const publicRangeMax = useUnit($publicRangeMax);
  const publicRangeMin = useUnit($publicRangeMin);
  const max = publicRangeMax[field];
  const min = publicRangeMin[field];
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const onChange = (value) => {
    changePublicFilters({
      field,
      value,
    });
  };

  const onInputChange = (e, type) => {
    changePublicFilters({
      field,
      value:
        type === 'max'
          ? [value[0], Number(e.target.value)]
          : [Number(e.target.value), value[1]],
    });
  };

  return (
    <div>
      <Slider
        range
        value={value}
        max={max}
        min={min}
        onChange={onChange}
        disabled={!!chosenZoom7Hexagon}
      />
      <div className={style.slider_inputs_wrapper}>
        <Input
          className={style.slider_input}
          value={value[0]}
          type="number"
          onChange={(e) => onInputChange(e, 'min')}
        />
        <Input
          className={style.slider_input}
          value={value[1]}
          type="number"
          onChange={(e) => onInputChange(e, 'max')}
        />
      </div>
    </div>
  );
}

export default MapDemoHexModal;
