import { Button, Card, Form, Input } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  $authStatus,
  $userInfo,
  loginEv,
  loginFx,
	sendForgotPasswordEv,
	sendRestorePasswordEv,
} from '../../models/authModel/index.js';
import { cookies } from '../../api/axiosinstance.js';
import style from './Login.module.css';
import login_image from '../../assets/login_image.png';
import logo from '../../assets/logo.svg';
import useWindowSize from '../../hooks/useWindowSize.js';

export default function Login() {
  const authStatus = useUnit($authStatus);
  const onLogin = useUnit(loginEv);
  const navigate = useNavigate();
  const isLoading = useUnit(loginFx.pending);
  const userInfo = useUnit($userInfo);
	const sendForgotPassword = useUnit(sendForgotPasswordEv);
	const sendRestorePassword = useUnit(sendRestorePasswordEv);
	const [width, height] = useWindowSize();

  useEffect(() => {
    if (authStatus && Object.keys(userInfo).length > 0) {
      const token = cookies.get('access_token');
      if (token) {
        if (userInfo.email !== 'airba@qala-ai.com') {
          navigate('/lite');
        } else {
          navigate('/rbp');
        }
      }
    }
  }, [authStatus, userInfo]);

  return (
    <div className={style.login_wrapper}>
      <div className={style.login_form_wrapper}>
        <div className={style.login_form} id="login-form">
          <img src={logo} className={style.login_logo} />
          <Form
            name="loginForm"
            id="loginForm"
            onFinish={onLogin}
            layout="vertical"
            style={{ marginTop: 30 }}
          >
            <Form.Item name="email" label="Логин">
              <Input
                placeholder="email@email.com"
                className={style.login_input}
              />
            </Form.Item>
            <Form.Item name="password" label="Пароль">
              <Input.Password
                allowClear
                placeholder="********"
                className={style.login_input}
              />
            </Form.Item>
            {/* <div className="login-register"> */}
            <Button
              loading={isLoading}
              htmlType="submit"
              form="loginForm"
              type="primary"
              className={style.login_button}
            >
              Войти
            </Button>
            {/* <Link to="/register">Зарегистрироваться</Link> */}
            {/* </div> */}
          </Form>
          <div className={style.login_forgot}>
            Забыли пароль?{' '}
            <Link to="/forgot-password" className={style.login_link}>
              Сбросьте его.
            </Link>
          </div>
          <div className={style.login_rules}>
            Авторизуясь на сервисе, вы соглашаетесь с{' '}
            <div className={style.login_link}>Правилами использования.</div>
          </div>
          {/* <Button onClick={sendForgotPassword}>Forgot</Button> */}
          {/* <Button onClick={sendRestorePassword}>Restore</Button> */}
        </div>
      </div>
      {width > 1200 ? (
        <img src={login_image} className={style.login_image} />
      ) : (
        ''
      )}
    </div>
  );
}
