import React, { useEffect, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Card, Popover, Space, Table } from 'antd';
import {
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import style from './LiteCategoryDetails.module.css';
import {
  $currentFavDetails,
  $currentFavs,
  $detailsData,
  $showRoads,
  changeCurrentFavsEv,
  toggleShowRoadsEv,
} from '../../../models/rbpLiteModel/index.js';
import LiteColumnsChart from './LiteColumnsChart.jsx';
import LitePieChart from './LitePieChart.jsx';
import LiteBarChart from './LiteBarChart.jsx';
import { $isDarkTheme, $language } from '../../../models/authModel/index.js';
import CursorIcon from '../CursorIcon/CursorIcon.jsx';
import StreetsIcon from './StreetsIcon.jsx';
import { clearGradientEv } from '../../../models/activeFiltersModel/index.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { setLiteChartRefEv } from '../../../models/educationModel/index.js';

function LiteCategoryDetails({ expanded, isFav }) {
  const detailsData = useUnit($detailsData);
  const currentFavs = useUnit($currentFavs);
  const changeCurrentFavs = useUnit(changeCurrentFavsEv);
  const currentFavDetails = useUnit($currentFavDetails);
  const language = useUnit($language);
  const setLiteChartRef = useUnit(setLiteChartRefEv);
  const liteChartRef = useRef();

  const isDark = useUnit($isDarkTheme);

  const toggleFav = (metric) => {
    changeCurrentFavs(metric);
  };

  useEffect(() => {
    if (liteChartRef.current) {
      setLiteChartRef({
        element: 'liteChart',
        ref: liteChartRef.current,
      });
    }
  }, [liteChartRef]);

  return (
    <div
      ref={liteChartRef}
      className={style.details_modal}
      style={{
        background: isDark ? '#1e2328' : 'white',
        padding: !expanded ? 20 : 0,
      }}
    >
      {!expanded ? (
        <Space direction="vertical">
          {isFav && currentFavDetails.length === 0 ? (
            <div style={{ color: 'inherit', maxWidth: 412 }}>
              {ru_en_page_dictionary.lite_no_favs[language]}
            </div>
          ) : null}
          {(isFav ? currentFavDetails : detailsData).map((item, index) => {
            return (
              <DetailItem
                key={`detail-${index}-${item.metric}`}
                item={item}
                currentFavs={currentFavs}
                toggleFav={toggleFav}
                isDark={isDark}
              />
            );
          })}
        </Space>
      ) : null}
    </div>
  );
}

function DetailItem(props) {
  const { item, currentFavs, toggleFav, isDark } = props;

  const [hide, setHide] = useState(false);
  const toggleShowRoad = useUnit(toggleShowRoadsEv);
  const showRoads = useUnit($showRoads);
  const language = useUnit($language);

  setTimeout(() => {
    setHide(true);
  }, 6000);

  let display;
  switch (item.type) {
    case 'table':
      display = (
        <>
          <Table
            dataSource={item.data}
            columns={item.columns}
            pagination={false}
            style={{ marginTop: 10 }}
          />
          {item.subTable && (
            <Table
              dataSource={item.subTable.data}
              columns={item.subTable.columns}
              pagination={false}
            />
          )}
        </>
      );
      break;
    case 'columns':
      display = <LiteColumnsChart data={item.data} metrics={item.metric} />;
      break;
    case 'pie':
      display = (
        <LitePieChart data={item.data} metrics={item.metric} bt={item.bt} />
      );
      break;
    case 'bar':
      display = (
        <LiteBarChart data={item.data} metrics={item.metric} bt={item.bt} />
      );
      break;
  }

  const streetsClick = () => {
    clearGradientEv();
    toggleShowRoad();
  };
  const popoverContent = (
    <div
      style={{
        maxWidth: 600,
      }}
    >
      <div className={style.popover_title}>{item.title}</div>
      {item.info?.match(/<.+?>/g) ? (
        <div
          className={style.popover_info}
          dangerouslySetInnerHTML={{ __html: item.info }}
        />
      ) : (
        <div className={style.popover_info}>{item.info}</div>
      )}
    </div>
  );

  return (
    <div className={style.details_item} onMouseEnter={() => setHide(true)}>
      {item.type !== 'table' && !hide && <CursorIcon />}
      {item.blockData ? (
        <div style={{ marginBottom: 20 }}>
          <div
            className={style.details_item_title}
            style={{ color: isDark ? 'white' : '#717171' }}
          >
            {item.blockData.title}
          </div>
          <div className={style.blocks_wrapper}>
            {item.blockData.blocks.map((elem, index) => {
              return (
                <div key={index} style={{ textAlign: 'center' }}>
                  <div>{elem.title}</div>
                  <div
                    style={{
                      fontSize: 24,
                      color:
                        typeof elem.value === 'number'
                          ? elem.value === 0
                            ? 'inherit'
                            : elem.value > 0
                              ? 'green'
                              : 'red'
                          : 'inherit',
                    }}
                  >
                    {typeof elem.value === 'number'
                      ? `${
                          elem.value === 0 ? '' : elem.value > 0 ? '+' : ''
                        }${elem.value.toFixed(1)}%`
                      : elem.value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      {item.title && (
        <>
          {item.metric === 'd5' ? (
            // <StreetsIcon
            //   style={{ cursor: 'pointer' }}
            //   className={showRoads ? style.pulse_animation : ''}
            //   onClick={streetsClick}
            // />
            <Button
              type="primary"
              onClick={streetsClick}
              style={{ width: 'calc(100% - 15px)' }}
            >
              {showRoads ? 'Показать на ячейках' : 'Показать трафик по улицам'}
            </Button>
          ) : (
            ''
          )}
          <div className={style.details_item_title_wrapper}>
            <div
              className={style.details_item_title}
              style={{ color: isDark ? 'white' : '#717171' }}
            >
              {item.metric === 'd5' && showRoads
                ? 'Среднесуточная проходимость'
                : item.title}
            </div>{' '}
          </div>
        </>
      )}
      {currentFavs.includes(item.metric) ? (
        <StarFilled
          onClick={() => toggleFav(item.metric)}
          className={style.details_star}
        />
      ) : (
        <StarOutlined
          onClick={() => toggleFav(item.metric)}
          className={style.details_star}
        />
      )}
      <Popover content={popoverContent} placement="left">
        <InfoCircleOutlined className={style.details_info} />
      </Popover>
      {/* <div className={style.public_category_popup}> */}
      {/*  <div className={style.public_category_popup_title}>{item.title}</div> */}
      {/*  {item.info} */}
      {/* </div> */}
      {showRoads && (
        <div
          style={{ background: isDark ? '#1e2328' : 'white' }}
          className={style.roads_disable}
        >
          {ru_en_page_dictionary.roads_disable[language]}
        </div>
      )}
      <div
        style={{
          filter: showRoads ? 'blur(2px)' : 'unset',
          pointerEvents: showRoads ? 'none' : 'unset',
        }}
      >
        {display}
      </div>
    </div>
  );
}

export default LiteCategoryDetails;
