import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useUnit } from 'effector-react';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import {
  age_dictionary,
  gender_dictionary,
  income_dictionary,
} from '../../../dictionaries/socdem_dictionaries.js';
import {
  $chartFilters,
  changeChartFiltersEv,
} from '../../../models/rbpLiteModel/index.js';
import { charts_names_dict } from './charts_names_dict.js';
import { addSeparators } from '../../../utils/format-utils.js';
import { $tourOpen, incStepEv } from '../../../models/educationModel/index.js';

function LiteColumnsChart({ data, metrics }) {
  const darkMode = useUnit($isDarkTheme);
  const chartFilters = useUnit($chartFilters);
  const changeChartFilters = useUnit(changeChartFiltersEv);
  const chartRef = useRef();
  const tourOpen = useUnit($tourOpen);
  const incStep = useUnit(incStepEv);

  const handleChartClick = (event, chartContext, config) => {
    const { name } = config.config.series[config.seriesIndex];
    const category = config.config.xaxis.categories[config.dataPointIndex];

    const payload = {
      category: name,
      column: category,
      metrics,
      // chart: title,
    };

    changeChartFilters(payload);
    // if (tourOpen) {
    //   incStep();
    // }
  };

  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
        },
      },
    },
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        click: handleChartClick,
        dataPointMouseEnter(event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      // categories: Object.values(age_dictionary),
      categories: Object.keys(data),
      labels: {
        formatter: (value) => {
          return metrics === 'd3' || metrics === 'd4'
            ? `${income_dictionary[value]}т. тг`
            : age_dictionary[value];
        },
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => addSeparators(value),
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    legend: {
      formatter: (value) => {
        return metrics === 'd3' || metrics === 'd4'
          ? `${charts_names_dict[value]}, чел`
          : `${charts_names_dict[value]}, лет`;
      },
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      y: {
        formatter: (value) => addSeparators(value),
        title: {
          formatter: (value) => {
            return metrics === 'd3' || metrics === 'd4'
              ? `${charts_names_dict[value]}, чел`
              : `${charts_names_dict[value]}, лет`;
          },
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'lighten',
          value: 0.3,
        },
      },
    },
  };

  let series = {};
  Object.keys(data).forEach((item) => {
    Object.keys(data[item]).forEach((field) => {
      if (!series[field]) {
        series[field] = [data[item][field]];
      } else {
        series[field] = [...series[field], data[item][field]];
      }
    });
  });

  series = Object.keys(series).map((key) => {
    return {
      // name: charts_names_dict[key],
      name: key,
      data: series[key],
    };
  });

  useEffect(() => {
    const { selectedDataPoints } = chartRef.current.chart.w.globals;
    if (
      (chartFilters.length > 0 && chartFilters[0].metrics !== metrics) ||
      (chartFilters.length === 0 && selectedDataPoints.length > 0)
    ) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));
      if (copy.length > 0) {
        copy.forEach((item, seriesIndex) => {
          if (item && item.length > 0) {
            item.forEach((dataIndex) => {
              chartRef.current.chart.ctx.toggleDataPointSelection(
                seriesIndex,
                dataIndex
              );
            });
          }
        });
      }
    }
    if (chartFilters.length > 0 && chartFilters[0].metrics === metrics) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));

      const dict = Object.hasOwn(chartFilters[0], 'age')
        ? age_dictionary
        : income_dictionary;
      const field = chartFilters[0].age ? 'age' : 'income';
      const chartLabels = chartRef.current.chart.w.globals.labels;
      if (copy.length === 0) {
        chartFilters.forEach((filter) => {
          const seriesIndex = +filter.gender - 1;
          const element = chartLabels.find((item) =>
            item.includes(dict[filter[field]])
          );
          const dataPointIndex = chartLabels.indexOf(element);

          chartRef.current.chart.ctx.toggleDataPointSelection(
            seriesIndex,
            dataPointIndex
          );
        });
      }
    }
  }, [chartFilters]);

  return (
    <Chart
      ref={chartRef}
      options={options}
      series={series}
      type="bar"
      width={390}
      padding="15"
      height="100%"
    />
  );
}

export default LiteColumnsChart;
