import React, { useState } from 'react';
import { Button, Collapse, Descriptions, Segmented, Table } from 'antd';
import { useUnit } from 'effector-react';
import style from './BuildingInfo.module.css';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import {
  $activeLitePreset,
  $buildingInfo,
} from '../../../models/rbpLiteModel/index.js';

function BuildingInfo() {
  const isDark = useUnit($isDarkTheme);
  const [tab, setTab] = useState('characteristic');
  const buildingInfo = useUnit($buildingInfo);
  const activeLitePreset = useUnit($activeLitePreset);

  const segmentedOptions = [
    {
      label: 'Характеристики здания',
      value: 'characteristic',
    },
    {
      label: 'Бизнес в здании',
      value: 'business',
    },
  ];

  const descItems = [
    {
      key: 'building_type',
      label: 'Тип здания',
      children: buildingInfo.building_type,
    },
    {
      key: 'building_floors',
      label: 'Этажность',
      children: buildingInfo.building_floors,
    },
    {
      key: 'building_material',
      label: 'Материал стен',
      children: buildingInfo.building_material,
    },
  ];

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Тип',
      dataIndex: 'category',
      key: 'category',
    },
  ];

  const openYandex = (url) => {
    window.open(url, '_blank');
  };

  const items = [
    {
      key: '1',
      label: 'Показать информацию о доме',
      children: (
        <div className={style.building_info_content}>
          <Segmented options={segmentedOptions} value={tab} onChange={setTab} />
          {tab === 'characteristic' ? (
            <>
              <Descriptions items={descItems} column={1} />
              <Button
                type="primary"
                style={{ marginTop: 20 }}
                onClick={() => openYandex(activeLitePreset.yandex_url)}
              >
                Посмотреть панорамы
              </Button>
            </>
          ) : (
            <Table
              style={{ marginTop: 20 }}
              columns={columns}
              dataSource={buildingInfo.building_orgs.map((item, index) => {
                return {
                  ...item,
                  key: `business_${index}`,
                };
              })}
              pagination={false}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div
      className={style.building_info}
      style={{ background: isDark ? '#1e2328' : 'white' }}
    >
      <Collapse items={items} expandIconPosition="end" ghost />
    </div>
  );
}

export default BuildingInfo;
