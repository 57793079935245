import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import mapboxgl from 'mapbox-gl';
import { $mapLoaded } from '../../models/mapModel/index.js';
import stops from '../../data/public_stops.json';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import { $showStops } from '../../models/zoom7Model/index.js';

const onClick = (e) => {
  const feature = e.features[0];
  const coordinates = feature.geometry.coordinates.slice();
  const html = `
            <div class="popup_content_wrapper">
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Название:</div> 
                <div class="popup_item_value">${
                  feature.properties.name || '-'
                }</div>
            </div>`;

  new mapboxgl.Popup().setLngLat(coordinates).setHTML(html).addTo(window.map);
};

function PublicStops() {
  const mapLoaded = useUnit($mapLoaded);
  const showStops = useUnit($showStops);

  const createLayer = (update) => {
    const stopsData = {
      type: 'FeatureCollection',
      features: stops.features,
    };

    if (update) {
      window.map.getSource('public_stops_source').setData(stopsData);
    } else {
      if (!window.map.getSource('public_stops_source')) {
        window.map.addSource('public_stops_source', {
          type: 'geojson',
          data: stopsData,
        });
      }

      if (!window.map.getLayer('public_stops_layer')) {
        window.map.addLayer({
          id: 'public_stops_layer',
          type: 'symbol',
          source: 'public_stops_source',
          layout: {
            // 'circle-color': 'red',
            // 'circle-radius': 5,
            'icon-image': 'stops',
            'icon-size': 1,
          },
        });
      }

      window.map.on('click', 'public_stops_layer', onClick);

      changeLayerVisibility('public_stops_layer', false);
    }
  };

  useEffect(() => {
    if (mapLoaded && stops) {
      if (window.map.getLayer('public_stops_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [mapLoaded, stops]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('public_stops_layer')) {
      if (showStops) {
        changeLayerVisibility('public_stops_layer', true);
      } else {
        changeLayerVisibility('public_stops_layer', false);
      }
    }
  }, [mapLoaded, showStops]);

  return null;
}

export default PublicStops;
