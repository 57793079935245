const certs_data = {
  'СТ-1':
    '<h1>Описание Сертификата СТ-1</h1>\n' +
    '    <p>Сертификат СТ-1 подтверждает происхождение товаров и необходим при экспорте в страны СНГ для предоставления таможенных льгот, например, освобождения от таможенных пошлин. Этот документ не требуется для товаров, перемещаемых между Россией, Казахстаном и Беларусью благодаря упрощенной системе оформления.</p>\n' +
    '    \n' +
    '    <h2>Процесс оформления</h2>\n' +
    '    <p>Документы, предоставленные заявителем, анализируются для подтверждения происхождения товара. Сертификаты могут быть оформлены как до, так и после отгрузки товара. Оформление проводится с использованием защищенных бланков, при этом экспортеру выдаются оригинал и копия.</p>\n' +
    '    \n' +
    '    <h2>Срок действия</h2>\n' +
    '    <p>Срок действия сертификата составляет 12 месяцев с даты его выдачи.</p>\n' +
    '    \n' +
    '    <h2>Критерии происхождения</h2>\n' +
    '    <p>Товары признаются происходящими из Казахстана, если они полностью получены или произведены в стране. Примеры включают:</p>\n' +
    '    <ul>\n' +
    '        <li>добычу полезных ископаемых,</li>\n' +
    '        <li>выращенную продукцию растительного происхождения,</li>\n' +
    '        <li>животных, родившихся и выращенных в Казахстане</li>\n' +
    '    </ul>\n' +
    '    \n' +
    '    <h2>Процедура выдачи</h2>\n' +
    '    <p>Сертификат выдается в течение одного рабочего дня после регистрации заявки, если предоставлены все требуемые документы. В случае отказа, выносится мотивированное решение.</p>',
  'СТ-2':
    '<h1>Описание Сертификата СТ-2</h1>\n' +
    '    <p>Сертификат СТ-2 оформляется для подтверждения происхождения реэкспортируемых товаров, в основном в Республику Сербию, включая товары, ввезенные в Казахстан из стран ЕАЭС. Сертификат может быть оформлен как до, так и после отгрузки товара.</p>\n' +
    '    \n' +
    '    <h2>Срок действия</h2>\n' +
    '    <p>Срок действия сертификата составляет 12 месяцев с даты его выдачи.</p>\n' +
    '    \n' +
    '    <h2>Критерии происхождения</h2>\n' +
    '    <p>Товары считаются происходящими из Казахстана, если они полностью произведены или добыты в стране. Это включает:</p>\n' +
    '    <ul>\n' +
    '        <li>добычу полезных ископаемых,</li>\n' +
    '        <li>растениеводство,</li>\n' +
    '        <li>животноводство,</li>\n' +
    '        <li>переработку</li>\n' +
    '    </ul>\n' +
    '    \n' +
    '    <h2>Процедура выдачи</h2>\n' +
    '    <p>Сертификат выдается в течение одного рабочего дня после регистрации заявки, если предоставлены все требуемые документы. В случае отказа, выносится мотивированное решение.</p>\n' +
    '    \n' +
    '    <h2>Полезность сертификатов</h2>\n' +
    '    <p>Сертификаты СТ-2 обеспечивают тарифные преференции, включая снижение или освобождение от таможенных пошлин, что предоставляет казахстанским экспортерам конкурентные преимущества на международном уровне.</p>',
  'СТ-3':
    '<h1>Описание Сертификата СТ-3</h1>\n' +
    '    <p>Сертификат происхождения формы СТ-3 предназначен для подтверждения казахстанского происхождения товаров при их экспорте. Этот документ важен для компаний, ведущих внешнеэкономическую деятельность, поскольку он помогает получать тарифные преференции или льготы при экспорте в различные страны.</p>\n' +
    '    \n' +
    '    <h2>Процесс оформления</h2>\n' +
    '    <h3>Подача заявки</h3>\n' +
    '    <p>Экспортер должен подать заявку на получение сертификата в электронной форме через уполномоченные органы или через систему "Атамекен". В заявке необходимо указать полную информацию о товаре и предоставить все требуемые документы.</p>\n' +
    '    \n' +
    '    <h3>Проверка документов</h3>\n' +
    '    <p>Предоставленные документы подвергаются тщательной проверке на соответствие заявленным критериям происхождения. Это может включать проверку производственных мощностей, аудит на местах и экспертизу материалов.</p>\n' +
    '    \n' +
    '    <h3>Выдача сертификата</h3>\n' +
    '    <p>После подтверждения соответствия товара требованиям происхождения, сертификат формы СТ-3 выдается на определенный срок. Если документы не соответствуют требованиям, может быть вынесено мотивированное решение об отказе.</p>\n' +
    '    \n' +
    '    <h2>Срок действия</h2>\n' +
    '    <p>Сертификат действителен в течение 12 месяцев с даты выдачи. Этот период позволяет использовать его для множества отгрузок экспортируемых товаров.</p>\n' +
    '    \n' +
    '    <h2>Критерии происхождения</h2>\n' +
    '    <p>Товары должны удовлетворять определенным критериям, чтобы считаться происходящими из Казахстана:</p>\n' +
    '    <ul>\n' +
    '        <li>Произведены полностью на территории Казахстана,</li>\n' +
    '        <li>Изготовлены из местного сырья или материалов,</li>\n' +
    '        <li>Значительная доля добавленной стоимости создана в Казахстане</li>\n' +
    '    </ul>\n' +
    '    \n' +
    '    <h2>Значение для экспортеров</h2>\n' +
    '    <p>Сертификат СТ-3 позволяет экспортерам участвовать в международной торговле на более выгодных условиях, снижая таможенные пошлины или полностью освобождая от них. Это способствует увеличению конкурентоспособности товаров на международных рынках.</p>',
  EAV:
    '<h1>Сертификат о происхождении товара формы «EAV»</h1>\n' +
    '    \n' +
    '    <h2>Общее описание</h2>\n' +
    '    <p>Сертификат EAV предназначен для подтверждения происхождения товаров при их экспорте, особенно в страны, с которыми у Казахстана заключены международные соглашения о торговле. Этот сертификат помогает получать таможенные льготы или тарифные преференции.</p>\n' +
    '    \n' +
    '    <h2>Процесс оформления</h2>\n' +
    '    <p>Заявление на получение сертификата подается в электронной форме через информационную систему "Атамекен". Необходимо предоставить документы, строго соответствующие установленным правилам и нормам.</p>\n' +
    '    \n' +
    '    <h2>Критерии происхождения</h2>\n' +
    '    <p>Товар должен полностью производиться в Казахстане или значительно перерабатываться на территории страны. Важна значительная доля созданной добавленной стоимости в производственном процессе в Казахстане.</p>\n' +
    '    \n' +
    '    <h2>Срок действия сертификата</h2>\n' +
    '    <p>Сертификат EAV действителен в течение 12 месяцев с момента выдачи, что позволяет использовать его для нескольких партий товаров.</p>\n' +
    '    \n' +
    '    <h2>Значение для экспортеров</h2>\n' +
    '    <p>Сертификат позволяет участвовать в международной торговле на более выгодных условиях, снижая таможенные пошлины или полностью освобождая от них, что укрепляет конкурентные позиции на международных рынках.</p>',
  A:
    '<h1>Сертификат о происхождении товара формы «А»</h1>\n' +
    '    \n' +
    '    <h2>Процесс оформления</h2>\n' +
    '    <ul>\n' +
    '        <li>Сертификат оформляется на каждую отдельную поставку товара</li>\n' +
    '        <li>Может быть выдан как до, так и после отгрузки товара</li>\n' +
    '        <li>Оформление регулируется международными нормативными документами и требует строгого соблюдения предписанных критериев</li>\n' +
    '    </ul>\n' +
    '    \n' +
    '    <h2>Срок действия</h2>\n' +
    '    <p>Срок действия сертификата составляет 10 месяцев с момента выдачи, что позволяет использовать его для регулярных поставок товаров в течение почти всего года.</p>\n' +
    '    \n' +
    '    <h2>Критерии происхождения</h2>\n' +
    '    <p>Для получения сертификата, товары должны удовлетворять следующим условиям:</p>\n' +
    '    <ul>\n' +
    '        <li>Полностью получены или произведены в Казахстане</li>\n' +
    '        <li>Включают широкий спектр товаров от минеральных ресурсов до сельскохозяйственной продукции и промышленных изделий</li>\n' +
    '    </ul>\n' +
    '    \n' +
    '    <h2>Значение для экспортеров</h2>\n' +
    '    <p>Этот сертификат важен для казахстанских экспортеров, так как облегчает доступ на рынки стран с развитой экономикой и способствует расширению международной торговли.</p>',
  REX:
    '<h1>Система REX</h1>\n' +
    '    \n' +
    '    <h2>Система REX (для экспортеров в Швейцарию и Норвегию)</h2>\n' +
    '    <p>В соответствии с Регламентом Европейского союза № 2015/2447 от 24 ноября 2015 года в рамках Генеральной системы преференций (ГСП) Европейским союзом, Швейцарией и Норвегией внедрена Система экспортеров Европейского Союза (Система REX).</p>\n' +
    '    <p>С 1 января 2014 года Казахстан в список пользователей преференций ГСП Европейского союза не относится и может получать преференции (снижение ставок ввозных таможенных пошлин) только при экспорте продукции в Норвегию и Швейцарию.</p>\n' +
    '    <p>С 1 мая 2021 года для получения преференций при экспорте продукции в Швейцарию и Норвегию сертификаты о происхождении товаров формы «А» не принимаются.</p>\n' +
    '    \n' +
    '    <h2>Регистрация в Системе REX</h2>\n' +
    '    <p>Для этих целей необходимо получить регистрационный номер в Системе REX (Registered exporters system), для самостоятельного заявления о происхождении товара в счете-фактуре или в любом другом коммерческом документе.</p>\n' +
    '    <p>Экспорт товаров стоимостью до 6 000 евро для получения преференций не требует наличия регистрации в Системе REX и может подтверждаться декларацией о происхождении товара.</p>\n' +
    '    <p>Для экспорта товаров в страны Европейского союза регистрация в данной системе не требуется.</p>\n' +
    '    \n' +
    '    <h2>Процедура регистрации в системе REX</h2>\n' +
    '    <p>Законом РК «О Национальной палате предпринимателей Республики Казахстан» НПП «Атамекен» наделена компетенцией по регистрации экспортеров в Системе REX.</p>\n' +
    '    <p>Порядок регистрации определен приказом Министра торговли и интеграции Республики Казахстан от 31 марта 2021 года № 244-НҚ.</p>\n' +
    '    <h3>Для получения регистрационного номера экспортера (REX-номера) необходимо:</h3>\n' +
    '    <ul>\n' +
    '        <li>Обратиться в Региональную палату предпринимателей в 17 областях, городах республиканского значения и столице</li>\n' +
    '        <li>Представить заявление на получение регистрационного номера по форме согласно приложению 1 к приказу МТИ РК от 31 марта 2021 года № 244-НҚ</li>\n' +
    '        <li>Предоставить копии документов согласно Перечню, утвержденному приказом МИР РК от 8 июля 2014 года № 257</li>\n' +
    '    </ul>\n' +
    '    <p><strong>Срок регистрации:</strong> не позднее 5 (пяти) рабочих дней, следующих за днем регистрации заявки с полным пакетом документов.</p>\n' +
    '    <p>Регистрация в системе REX осуществляется единожды, на неограниченный срок, и после получения REX-номера, который будет указываться экспортером на инвойсе, не потребуется получать сертификат на каждую партию товара.</p>\n' +
    '    \n' +
    '    <h2>Стоимость оказания услуги</h2>\n' +
    '    <p>Согласно решению Президиума Национальной палаты предпринимателей Республики Казахстан «Атамекен», стоимость услуги по регистрации экспортеров в информационных системах стран ввоза товара или внесение изменений в регистрационные данные экспортеров составляет 4 МРП.</p>\n' +
    '    <p>При этом, для субъектов предпринимательства, не имеющих задолженности по оплате обязательным членским взносам, предоставляется дисконт 50%, и стоимость составляет 2 МРП.</p>',
  ОРИГИНАЛ:
    '<h1>Непреференциальный сертификат о происхождении товара общей формы «Оригинал»</h1>\n' +
    '    \n' +
    '    <h2>Процесс оформления</h2>\n' +
    '    <p>Оформление сертификата о происхождении товара общей формы «Оригинал» регламентируется приказом Министра торговли и интеграции Республики Казахстан от 13 июля 2021 года № 454-НҚ «Об утверждении Правил по определению страны происхождения товара, статуса товара Евразийского экономического союза или иностранного товара, выдаче сертификата о происхождении товара и отмене его действия, установлении форм сертификата по определению страны происхождения товара».</p>\n' +
    '    \n' +
    '    <p>Сертификат о происхождении товара общей формы «Оригинал» оформляется для нетарифного регулирования, в том числе:</p>\n' +
    '    <ul>\n' +
    '        <li>На каждую отдельную поставку товара, которая осуществляется одним или несколькими транспортными средствами при экспорте товара с территории Республики Казахстан</li>\n' +
    '        <li>Может быть оформлен как до, так и после отгрузки товара</li>\n' +
    '        <li>Может быть оформлен на реэкспортируемый товар, т.е. если товар завозится на территорию Казахстана с территории любого государства и далее экспортируется</li>\n' +
    '    </ul>\n' +
    '    \n' +
    '    <h2>Срок действия сертификата</h2>\n' +
    '    <p>Срок действия сертификата о происхождении товара формы «Оригинал» составляет 12 месяцев с даты выдачи.</p>',
  'СТ-KZ':
    '<h1>Сертификат о происхождении товара формы «СТ-KZ»</h1>\n' +
    '    \n' +
    '    <p>Сертификат о происхождении товара формы «СТ-KZ» предназначен для внутреннего обращения для участия в закупках товаров при вывозе товаров со свободных складов и свободных экономических зон. Выдается для подтверждения страны происхождения товара и доли местного содержания (ДМС) на товары полностью казахстанского происхождения (ДМС 100 %) или казахстанского происхождения с учетом критерия достаточной переработки товара.</p>\n' +
    '    \n' +
    '    <h2>Инструкция получения</h2>\n' +
    '    <p>Сертификаты формы «СТ-KZ» оформляются в РПП в соответствии с <strong>Правилами по определению страны происхождения товара</strong>, утвержденными приказом Министра торговли и интеграции Республики Казахстан от 13 июля 2021 года № 454-НҚ.</p>\n' +
    '    \n' +
    '    <h2>Процедура выдачи сертификата о происхождении товара формы «СТ-KZ»</h2>\n' +
    '    \n' +
    '    <h3>1 этап. Проведение экспертизы происхождения товаров</h3>\n' +
    '    <p>Заявитель самостоятельно осуществляет выбор экспертной организации. Реестр экспертов-аудиторов по определению страны происхождения товара и перечень экспертных организаций размещаются на интернет-ресурсе Комитета технического регулирования Министерства торговли и интеграции Республики Казахстан.</p>\n' +
    '    \n' +
    '    <h3>2 этап. Оформление удостоверения и выдача сертификата формы «СТ-KZ»</h3>\n' +
    '    <p>Сертификат выдается в Региональной палате предпринимателей в 14 областях, в городах республиканского значения и столице.</p>\n' +
    '    <ul>\n' +
    '        <li><strong>Основание:</strong> электронная форма заявки, электронные копии акта экспертизы о происхождении товара и документов, представленные на экспертизу через сайт documentolog.kz</li>\n' +
    '        <li><strong>Срок выдачи сертификата:</strong> не позднее 3 (трех) рабочих дней с момента регистрации заявки с полным пакетом документов</li>\n' +
    '        <li>При подаче заявки на товарные позиции, выпускаемые впервые, либо при изменении перечня основного оборудования и технологических операций, осуществляется выездная проверка наличия производственной базы, технологического оборудования и идентификации товара. В таком случае срок выдачи сертификата формы «СТ-KZ» составляет 4 (четыре) рабочих дня</li>\n' +
    '    </ul>',
  Индустриальный:
    '<h1>Индустриальный сертификат</h1>\n' +
    '    \n' +
    '    <p>Индустриальный сертификат – документ, подтверждающий наличие заявителя в Реестре отечественных производителей товаров, работ и услуг. Выдача индустриального сертификата осуществляется в соответствии с <strong>Правилами формирования и ведения Реестра ОП</strong>, утвержденными Решением Президиума Национальной палаты предпринимателей Республики Казахстан «Атамекен» от 19 марта 2024 года (Протокол № 5).</p>\n' +
    '    \n' +
    '    <h2>Инструкция</h2>\n' +
    '    <p>Включение в Реестр ОП и получение индустриального сертификата состоит из трех основных этапов:</p>\n' +
    '    \n' +
    '    <h3>1 ЭТАП: Проведение анализа состояния производства</h3>\n' +
    '    <p>Анализ состояния производства товаров, работ и услуг проводится отраслевым экспертом отраслевой ассоциации (союза) с момента заключения договора в сроки согласно размерности предприятий:</p>\n' +
    '    <ul>\n' +
    '        <li>до 5 рабочих дней – малые предприятия (до 50 человек);</li>\n' +
    '        <li>до 7 рабочих дней – средние предприятия (до 250 человек);</li>\n' +
    '        <li>до 10 рабочих дней – крупные предприятия (свыше 250 человек)</li>\n' +
    '    </ul>\n' +
    '    <p><a href="https://docs.google.com" target="_blank">Перечень отраслевых ассоциаций и экспертов</a></p>\n' +
    '    \n' +
    '    <h3>2 ЭТАП: Подача заявления</h3>\n' +
    '    <p>Заявитель посредством сайта <a href="https://documentolog.kz" target="_blank">documentolog.kz</a> оформляет заявление о включении в Реестр ОП путем выбора Региональной Палаты Предпринимателей.</p>\n' +
    '    \n' +
    '    <h3>3 ЭТАП: Рассмотрение заявления</h3>\n' +
    '    <p>Рассмотрение заявления заявителя и заключения отраслевого эксперта об анализе состояния производства товаров, работ, услуг уполномоченным лицом РПП. Уполномоченное лицо РПП рассматривает заявку в течение 5 рабочих дней со дня ее поступления в электронную систему сертификации.</p>',
};

export const messages_sequence = {
  'Для экспорта в третьи страны':
    'СЕРТИФИКАТ О ПРОИСХОЖДЕНИИ ТОВАРА НА ЭКСПОРТ</br>' +
    '</br>' +
    'Сертификат о происхождении казахстанского товара – это документ, подтверждающий страну происхождения товара.</br>' +
    '</br>' +
    'Сертификаты о происхождения товара экспортных форм (СТ-1, СТ-2, СТ-3, EAV, REX, А, Оригинал) оформляются для получения тарифных преференций в стране назначения (снижение или освобождение от таможенных пошлин), нетарифного регулирования (запреты, ограничения на ввоз/вывоз товара).</br>' +
    '</br>' +
    'Выберите страну экспорта',
  'Для внутреннего обращения':
    'Для внутреннего обращения существуют следующие типы сертификации:',
  'Покажи все программы':
    'Это общий перечень сертификаций, используемых в Республике Казахстан.</br>' +
    'Вы можете ознакомиться с каждым из них и, при необходимости, подать заявку на получение сертификата.',

  'Страны СНГ ': certs_data['СТ-1'],
  Грузия: certs_data['СТ-1'],
  Вьетнам: certs_data.EAV,
  Иран: certs_data['СТ-3'],
  'Норвегия, Швейцария': certs_data.REX,
  Сербия: certs_data['СТ-2'],
  'Страны ЕС': certs_data.A,
  'США, Канада, Япония, Турция, Австралия, Новая Зеландия,</br>и Лихтенштейн и др. страны, пользующиеся GSP':
    certs_data.A,
  'Третьи страны, с которыми не заключены</br>международные Соглашения':
    certs_data.ОРИГИНАЛ,
  'Для участия в закупках товаров': certs_data['СТ-KZ'],
  'Для подтверждающий наличие</br>в Реестре отечественных производителей':
    certs_data.Индустриальный,
  'СТ-1': certs_data['СТ-1'],
  'СТ-2': certs_data['СТ-2'],
  'СТ-3': certs_data['СТ-3'],
  EAV: certs_data.EAV,
  A: certs_data.A,
  REX: certs_data.REX,
  '«Оригинал»': certs_data.ОРИГИНАЛ,
  'СТ-KZ': certs_data['СТ-KZ'],
  'Индустриальный сертификат': certs_data.Индустриальный,
};

export const options_sequence = {
  'Для экспорта в третьи страны': [
    'Страны СНГ ',
    'Грузия',
    'Вьетнам',
    'Иран',
    'Норвегия, Швейцария',
    'Сербия',
    'Страны ЕС',
    'США, Канада, Япония, Турция, Австралия, Новая Зеландия,</br>и Лихтенштейн и др. страны, пользующиеся GSP',
    'Третьи страны, с которыми не заключены</br>международные Соглашения',
  ],
  'Для внутреннего обращения': [
    'Для участия в закупках товаров',
    'Для подтверждающий наличие</br>в Реестре отечественных производителей',
  ],
  'Покажи все программы': [
    'СТ-1',
    'СТ-2',
    'СТ-3',
    'EAV',
    'A',
    'REX',
    '«Оригинал»',
    'СТ-KZ',
    'Индустриальный сертификат',
  ],
};
