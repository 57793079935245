import { sample } from 'effector';
import {
  $categoriesRef,
  $currentStep,
  $gradientRef,
  $isPresetRef,
  $liteCategoryRef,
  $liteChartRef,
  $mapRef,
  $profileModalRef,
  $profileRef,
  $publicBusinessTypeRef,
  $publicCreateRef,
  $publicDetailsRef,
  $tourOpen,
  $tourSteps,
} from './stores.js';
import {
  decStepEv,
  incStepByHexEv,
  incStepEv,
  resetStepEv,
  setCategoriesRefEv,
  setGradientRefEv,
  setIspResetRefEv,
  setLiteCategoryRefEv,
  setLiteChartRefEv,
  setMapRefEv,
  setProfileModalRefEv,
  setProfileRefEv,
  setPublicBusinessTypeRefEv,
  setPublicCreateRefEv,
  setPublicDetailsRefEv,
  toggleTourEv,
} from './events.js';
import { education_steps_desc } from '../../data/education_steps_desc.jsx';
import {
  $isPreset,
  getNewLocationEv,
  resetChartFiltersEv,
  toggleCategoryExpandedEv,
  toggleIsPresetEv,
} from '../rbpLiteModel/index.js';
import { wsGetLiteData } from '../../utils/webSocketConfig.js';
import { clearGradientEv } from '../activeFiltersModel/index.js';
import { changeDrawModeEv, changeInteractivityEv } from '../mapModel/index.js';

const scenario_order_1 = {
  isPreset: 1,
  publicBusiness: 2,
  publicDetails: 3,
  mapDetails: 4,
  publicCreate: 5,
  mapCreate: 6,
  liteCategory: 7,
  liteChart: 8,
  gradient: 9,
  profile: 10,
  profileModal: 11,
  // map: 9,
  // categories: 10,
};

const scenario_prev_actions = {
  publicBusiness: () => toggleIsPresetEv(true),
  liteCategory: () => {
    toggleIsPresetEv(false);
    changeInteractivityEv(false);
    changeDrawModeEv('draw_point');
    window.draw.changeMode('draw_point');
  },
  liteChart: () => toggleCategoryExpandedEv(true),
  gradient: () => resetChartFiltersEv(),
  profile: () => clearGradientEv(),
};

const scenario_popup_placement = {
  isPreset: 'bottom',
  publicBusiness: 'bottomRight',
  publicDetails: 'left',
  mapDetails: 'top',
  publicCreate: 'bottomRight',
  mapCreate: 'top',
  liteCategory: 'left',
  liteChart: 'bottomLeft',
  gradient: 'top',
  profile: 'bottomLeft',
};

$tourOpen.on(toggleTourEv, (state, payload) => {
  if (typeof payload === 'boolean') return payload;
  return !state;
});

// FIXME Handlers to set refs asap
$isPresetRef.on(setIspResetRefEv, (state, payload) => payload);
$categoriesRef.on(setCategoriesRefEv, (state, payload) => payload);
$gradientRef.on(setGradientRefEv, (state, payload) => payload);
$mapRef.on(setMapRefEv, (state, payload) => payload);
$publicBusinessTypeRef.on(
  setPublicBusinessTypeRefEv,
  (state, payload) => payload
);
$publicDetailsRef.on(setPublicDetailsRefEv, (state, payload) => payload);
$publicCreateRef.on(setPublicCreateRefEv, (state, payload) => payload);
$liteCategoryRef.on(setLiteCategoryRefEv, (state, payload) => payload);
$liteChartRef.on(setLiteChartRefEv, (state, payload) => payload);
$profileRef.on(setProfileRefEv, (state, payload) => payload);
$profileModalRef.on(setProfileModalRefEv, (state, payload) => payload);

$currentStep.on(decStepEv, (state, payload) => state - 1).reset(resetStepEv);

sample({
  source: [$currentStep, $tourSteps],
  clock: incStepEv,
  fn: ([current, steps], clock) => {
    if (current === steps.length) return 0;
    return current + 1;
  },
  target: $currentStep,
});

sample({
  source: [$tourSteps, $mapRef],
  clock: [
    $isPresetRef.updates,
    $publicBusinessTypeRef.updates,
    $publicDetailsRef.updates,
    $publicCreateRef.updates,
    $liteCategoryRef.updates,
    $liteChartRef.updates,
    $profileRef.updates,
    $profileModalRef.updates,
    // $categoriesRef.updates,
    $gradientRef.updates,
    // $mapRef.updates,
  ],
  fn: ([tourSteps, mapRef], clock) => {
    let result = [...tourSteps];

    const foundIndex = result.indexOf((item) => item.element === clock.element);
    if (foundIndex) {
      result = [
        ...result.filter((item) => item.element !== clock.element),
        {
          element: clock.element,
          title: education_steps_desc[clock.element].title,
          description: education_steps_desc[clock.element].desc,
          target: clock.ref,
          prevEvent: scenario_prev_actions[clock.element],
          placement: scenario_popup_placement[clock.element],
        },
      ];
    } else {
      result.push({
        element: clock.element,
        title: education_steps_desc[clock.element].title,
        description: education_steps_desc[clock.element].desc,
        target: clock.ref,
        prevEvent: scenario_prev_actions[clock.element],
        placement: scenario_popup_placement[clock.element],
      });
    }

    if (
      clock.element === 'publicDetails' &&
      !result.find((item) => item.element === 'mapDetails')
    ) {
      result.push({
        element: 'mapDetails',
        title: education_steps_desc.mapDetails.title,
        description: education_steps_desc.mapDetails.desc,
        target: mapRef.ref,
        placement: scenario_popup_placement.mapDetails,
      });
    }
    if (
      clock.element === 'publicCreate' &&
      !result.find((item) => item.element === 'mapCreate')
    ) {
      result.push({
        element: 'mapCreate',
        title: education_steps_desc.mapCreate.title,
        description: education_steps_desc.mapCreate.desc,
        target: mapRef.ref,
        placement: scenario_popup_placement.mapCreate,
      });
    }

    return result.sort(
      (a, b) => scenario_order_1[a.element] - scenario_order_1[b.element]
    );
  },
  target: $tourSteps,
});

sample({
  source: [$tourSteps, $tourOpen, $isPresetRef],
  clock: $isPreset.updates,
  filter: ([steps, tourOpen], isPreset) => !tourOpen,
  fn: ([steps, tourOpen, isPresetRef], isPreset) => {
    if (!isPreset) {
      return steps.filter((item) => item.element !== 'isPreset');
    }
    return [
      {
        element: isPresetRef.element,
        title: education_steps_desc.isPreset.title,
        description: education_steps_desc.isPreset.desc,
        target: isPresetRef.ref,
      },
      ...steps,
    ];
  },
  target: $tourSteps,
});

sample({
  source: [$tourOpen, $currentStep],
  clock: incStepByHexEv,
  filter: ([tourOpen, _]) => tourOpen,
  fn: ([_, currentStep]) => {
    return currentStep + 1;
  },
  target: $currentStep,
});

sample({
  source: $tourOpen,
  clock: getNewLocationEv,
  filter: (source) => source,
  fn: () => {
    wsGetLiteData({
      zoom_id: '8920e60acd7ffff',
      business: 1,
      sub_business: 1,
    });
  },
  target: [toggleIsPresetEv, incStepEv],
});

sample({
  clock: $tourOpen.updates,
  filter: (clock) => !clock,
  target: [clearGradientEv, resetChartFiltersEv, toggleCategoryExpandedEv],
});

sample({
  source: $tourSteps,
  clock: $currentStep,
  filter: (source, clock) => clock === source.length,
  fn: () => false,
  target: [toggleTourEv, resetStepEv],
});
