import React from 'react';
import PaymentReports from '../sections/PaymentReports.jsx';
import Payment from '../sections/Payment.jsx';

function PaymentPage() {
  return (
    <>
      <Payment />
      {/* <PaymentReports /> */}
    </>
  );
}

export default PaymentPage;
